import React, { Component } from 'react'
import { Error } from 'react-form-error'

import { contactsService } from '../../services/tibesa.api'

import Modal from 'react-modal';
import LoadingOverlay from 'react-loading-overlay';

import TableContacts from './components/table';
import TableContactsBook from './../../components/tables/TableContactsBook'
import Catalogs from  '../../models/catalogs';
import configs from '../../configs/config';
import axios from 'axios';
import Swal from 'sweetalert2'

export default class index extends Component {

    constructor() {
        super();
        this.state = {
            isuploading: false,
            type: 1,  //Default
            errors: [],
            dataOriginal:[],
            data:[],
            databook: [],
            typesAllowed : [ { id:0, name:'Seleccione'}, { id:1, name:'Linkedin'},{ id:4, name:'HubSpot'}, { id:2, name:'vCard'},{ id:3, name:'Archivo Csv'}]
        }

        this.getAllData();

    }

    getAllData = () =>{
        this.getData();
        this.getDataBook();
    }

    getData = () => {
        axios.get(`${configs.URL_API_BASE}/contacts/getContacts`, {})
            .then(res => {
                if (res.data.status == 200) {
                    let auxData = res.data.results;
                    auxData.forEach((item) => {
                        item.createdOn = item.createdOn.split('T')[0];
                        item.id = item.contactId;
                    })
                    this.setState({ data: auxData });
                    this.setState({ dataOriginal: auxData });

                }
            });
    }

    getDataBook = () => {
        axios.get(`${configs.URL_API_BASE}/contacts/getContactsBook`, {})
            .then(res => {
                if (res.data.status == 200) {
                    let auxData = res.data.results;
                    let total = 0; 
                    auxData.map( (a,b) =>  { total+= a.count} );
                    // auxData.reduce( (a,b) => { a.count =(a.count?a.count:0); return a.count + b.count} )}
                    console.log(auxData); 
                    var all = [{"alpha":"Todos","count": total}];
                    auxData= all.concat(auxData);
                    this.setState({ databook: auxData });
                }
            });
    }

    delete = (id) => {
        axios.post(`${configs.URL_API_BASE}/contacts/${id}/delete`)
            .then(res => {
                console.log(res);
                if (parseInt(res.data.status) == 200) {
                    this.getData();
                    Swal.fire('Eliminado!', 'Elemento eliminado correctamente.', 'success');
                } else {
                    Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
                }
            }).catch(err => {
                console.log(err);
                Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
            });
    }
    
    onSubmit = (e) => {
        e.preventDefault(); 
        if (this.state.fileToImport) {
            let formData = new FormData();
            formData.append('fileType', this.state.fileType);
            formData.append('fileToImport', this.state.fileToImport);
            console.log("Trying to Import");

            return contactsService.importContacts(formData) 
                                .then(response => {
                                    console.log(response.data);
                                    Swal.fire(
                                        `Se encontraron ${response.data.valids} registros validos, ${response.data.duplicates} registros duplicados, de un total de ${response.data.total}`
                                    )
                                    if (response.data.valids>0 ) this.getAllData();
                                      
                                    return response;
                                })
                                .catch(error => {
                                    Swal.fire(
                                        `Error en la petición: ${error}`
                                    )
                                })
                                .finally(() => {
                                    this.closeModal();
                                });
        } else {
            Swal.fire('Seleccione un Achivo', 'Es requerido el Archivo con los Contactos a Importar', 'warning');

        }

    }

    onChangeFile = (e) => {
        this.setState({
            fileToImport: e.target.files[0]
        })
    }

    onInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleFilter = (filter) => {
        console.log(filter);
        if ( filter == 'Todos') 
            this.setState({ data : this.state.dataOriginal})
        else {
            let dataFiltered = this.state.dataOriginal.filter( (item) => item.name.startsWith(filter) );
            this.setState({ data: dataFiltered })
        }
    }

    openModal = () => {
        this.setState({ modalIsOpen: true, fileToImport: null});
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    handleOpenEdit = record => {
        console.log(record.id);
        this.props.history.push(`/contacts/${record.id}`);
    }

    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Contactos</h2>
                    <a className="btn btn-primary d-flex justify-content-between align-self-center" href="#" onClick={this.openModal}>
                        <span>Importar Contacto&nbsp;</span>
                        <span><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
                    </a>
                    <a href="/contacts/add" className="btn btn-primary d-flex justify-content-between align-self-center">
                        <span>Nuevo Contacto&nbsp;</span>
                        <span><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="row my-4">
                    <div className="col-2">
                        {
                            <TableContactsBook handleFilter={this.handleFilter} data={this.state.databook}></TableContactsBook>
                        }
                    </div>
                    <div className="col-10">
                        {
                            <TableContacts handleOpenEdit={this.handleOpenEdit} delete={this.delete} data={this.state.data}></TableContacts>
                        }
                    </div>
                </div>
                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    className="Modal"
                    contentLabel="Crear Usuario">
                    <form onSubmit={this.onSubmit}>
                        <div className="modal-dialog modal-lg">
                            <LoadingOverlay  active={this.state.isuploading}
                                spinner
                                text='Importando el contenido...' >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Importar Contactos</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row content-modal-mobile">
                                            <div className="col-12">
                                               
                                                    <div className="col-6 my-3">
                                                        <label>Selecciones el tipo de archivo <span className="text-red">*</span></label>
                                                        <select className="form-control" id="fileType" value={this.state.fileType} name={'fileType'} onChange={this.onInputChange}>
                                                            {
                                                                this.state.typesAllowed.map((rol, pos) => (
                                                                    <option key={pos} value={rol.id}>
                                                                        {rol.name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-12">
                                                        <label>Subir Archivo : <span
                                                            className="text-red">*</span></label>
                                                        <input type="file" name="fileToImport" onChange={this.onChangeFile} id="fileToImport" />
                                                    </div>
                                                     
                                                      {(this.state.fileType==3) && (                                                 
<div>Estes es el formato que debe cumplir el Archivo CSV en su primera linea. <br/>
    name , phone , cellPhone , email , country ,  city , company , companyRol , birthday,interets ,  address
</div>
                                                      )
                                                       }
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-danger btn-lg" type="button" onClick={this.closeModal}>Cancelar</button>
                                        <button className="btn btn-success btn-lg" type="submit">Enviar</button>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </form>
                </Modal>
            </>
        )
    }
}
