import React, { Component } from "react"
import VideosForm from './components/video-form'
import { videosService } from '../../services/tibesa.api'
import LoadingOverlay from "react-loading-overlay";
import Swal from 'sweetalert2'
import Video from "../../models/video";
import configs from './../../configs/config';

export default class VideoDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            video: null,
        }

        this.submitChanges = this.submitChanges.bind(this);

    }

    componentDidMount() {
        let curId=this.props.match.params.id;
     //   let curLan =this.props.match.params.language;
        Promise.all([videosService.get(curId)])
        .then(([video]) => {
            //antes de asignasr.. me piden el detalle de un idioma?
          /*  if (this.props.match.params.language&&curId){
                //tray to get that language. 
                Promise.all([newsService.getNewsTranslation(curId,curLan)])
                .then(([video]) => {
                    let lang =   this.props.match.params.language.toLowerCase();
                    this.setState({ news : new Videos(news),language: lang});
                    //si no habia datos todo el News esta en null 
                    if (!video.videoId)  {
                        this.setState({
                            video : {   //no muy deacuerdo con sto.. 
                                videoId : this.props.match.params.id,
                                language : this.props.match.params.language.toLowerCase()
                            },
                            language:this.props.match.params.language.toLowerCase(),
                        });
                    }  
                });
            }
            else{   el servicio me arroja un arreglo. aun que sea unoo ok.. */
                this.setState({ video: new Video(video[0]),language:null,  });
                console.log(video[0]);

           /* } */
        })
    }

    showTab(language){
        if (language===this.state.language){
            return "tab-paneactive show active fade p-3"
        }
        return "display:none"
    }

    showNav(language){
     //   console.log(`${language} === ${this.state.language}`)
        if (language===this.state.language){
            return "nav-link show active "
        }
        return "nav-link"
    }

    selectTab(language){
        if (language===this.state.language){
            return "true"
        }
        return "false"
    }

    parseUrlVideoEmbed = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length == 11)
            ? match[2]
            : null;
    }

    submitChanges(formData) {
        Swal.fire({
            title: '¿Seguro que deseas editar el Video?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return videosService.update(this.props.match.params.id, formData)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
                if (response.value && response.value.data && response.value.data.status === 200) {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: '¡Página actualizada con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }
                    ).then(() => {
                        window.location.reload();
                    });
                } else if(!response.dismiss === 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }

    submitTransalation(formData,id) {     
        Swal.fire({
            title: '¿Seguro que deseas editar la Página?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return videosService.createOrUpdateTranslation(id,this.state.language, formData)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
                if (response.value && response.value.data && response.value.data.status === 200) {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: '¡Página actualizada con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }
                    ).then(() => {
                        window.location.reload();
                    });
                } else if(!response.dismiss === 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }

    render() {

        let form;
        let formEn;

        if (this.state.video != null) {
            if (this.state.language==='en'){
                formEn = <VideosForm video={this.state.video} onSubmit={this.submitChanges} onSubmitTranslate={this.submitTransalation} language={this.state.video.language}></VideosForm>
            }
            else{
                form = <VideosForm video={this.state.video} onSubmit={this.submitChanges}></VideosForm>
            }

        }
        else
            form = <LoadingOverlay></LoadingOverlay>

        return (
            <>
                <div className="air__utils__heading">
                    <a href="/videos">Listado de videos</a><span>{" "}/{" "}Editar Video #{this.props.match.params.id}</span>
                </div>
                
                <div className="card mt-3 tab-card">
                <div className="card-header tab-card-header">
                    <ul className="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a className={this.showNav(null)} id="one-tab" data-toggle="tab" href={"/videos/"+this.props.match.params.id} role="tab" aria-controls="One" aria-selected={this.selectTab(null)} >Español</a>
                        </li>
                      
                    </ul>
                </div>
                <div className="tab-content" id="myTabContent">
                    <div className={this.showTab(null)} id="one" role="tabpanel" aria-labelledby="one-tab">
                            {form}          
                    </div>
                 
                </div>
                </div>
            </>
        )
    }
}
