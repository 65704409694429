import React from 'react';
import { BrowserRouter as Router, Switch} from 'react-router-dom';
import AppRoute from './components/app.route'

import Dashboard from './views/dashboard';
import Login from './views/login';
import Oportunities from './views/oportunities';
import OportunityDetail from './views/oportunities/detail';

import Amenities from './views/amenities';
import Categories from './views/categories';
import Properties from  './views/properties';
import PropertyDetail from  './views/properties/detail';
import PropertyAdd from  './views/properties/add';
import Subcategories from './views/subcategories';
import Currencies from './views/currencies';
import PropertyTypes from './views/property-types';

import Contacts from './views/contacts';
import ContactAdd from './views/contacts/add';
import ContactDetail from './views/contacts/detail';

import Companies from './views/companies';
import CompanyDetail from './views/companies/detail';

import Campaigns from './views/campaigns';
import CampaignDetail from './views/campaigns/detail';
import CampaignReport from  './views/campaigns/report';

import EmailLists from './views/emailLists';
import EmailListDetail from './views/emailLists/detail';


import Pages from './views/pages';
import PageAdd from './views/pages/add';
import PageDetail from './views/pages/detail';

import LandingPages from './views/landing-pages';
import LandingPageAdd from './views/landing-pages/addWizard';
import LandingPageDetail from './views/landing-pages/detail';


import News from './views/news';
import NewsAdd from './views/news/add';
import NewsDetail from './views/news/detail';

import Users from './views/users';

import Videos from './views/videos';
import VideoAdd from './views/videos/add';
import VideoDetail from './views/videos/detail';


import EmailTemplates from './views/email-templates';
import EmailTemplateDetail from './views/email-templates/detail';
import EmailTemplateAdd from './views/email-templates/add';


import ContactForm from './views/contact-form';

function App() {
      return (
            <Router>
                <Switch>
                    <AppRoute exact path="/" component={Dashboard} />
                    <AppRoute exact path="/login" component={Login} layout={null} />

                    <AppRoute exact path="/oportunities" component={Oportunities} />
                    <AppRoute path="/oportunities/:oportunityId" component={OportunityDetail} />

                    <AppRoute path="/contact-form" component={ContactForm} />

                    <AppRoute path="/amenities" component={Amenities} />
                    <AppRoute path="/categories" component={Categories} />

                    <AppRoute exact path="/properties" component={Properties} />
                    <AppRoute path="/properties/add" component={PropertyAdd} />
                    <AppRoute path="/properties/:id" component={PropertyDetail} />

                    <AppRoute path="/subcategories" component={Subcategories} />
                    <AppRoute path="/currencies" component={Currencies} />
                    <AppRoute path="/property-types" component={PropertyTypes} />

                    <AppRoute exact path="/contacts" component={Contacts} />
                    <AppRoute path="/contacts/add" component={ContactAdd} />
                    <AppRoute path="/contacts/:id" component={ContactDetail} />

                    <AppRoute exact path="/companies" component={Companies} />
                    <AppRoute path="/companies/:id" component={CompanyDetail} />

                    <AppRoute exact path="/campaigns" component={Campaigns} />
                    <AppRoute exact path="/campaigns/report/:id" component={CampaignReport} />
                    <AppRoute  path="/campaigns/:id" component={CampaignDetail} />

                    <AppRoute exact path="/emailLists" component={EmailLists} />
                    <AppRoute path="/emailLists/:id" component={EmailListDetail} />


                    <AppRoute exact path="/pages" component={Pages} />
                    <AppRoute path="/pages/add" component={PageAdd} />
                    <AppRoute exact path="/pages/:id" component={PageDetail} />
                    <AppRoute path="/pages/:id/:language" component={PageDetail} />

                    <AppRoute exact path="/landingPages" component={LandingPages} />
                    <AppRoute path="/landingPages/addWizard" component={LandingPageAdd} />
                    <AppRoute exact path="/landingPages/:id/:content" component={LandingPageDetail} />
                    <AppRoute exact path="/landingPages/:id/:content/:language" component={LandingPageDetail} />




                    <AppRoute exact path="/news" component={News} />
                    <AppRoute path="/news/add" component={NewsAdd} />
                    <AppRoute exact path="/news/:id" component={NewsDetail} />
                    <AppRoute path="/news/:id/:language" component={NewsDetail} />

                    <AppRoute path="/users" component={Users} />

                    <AppRoute exact path="/videos" component={Videos} />
                    <AppRoute path="/videos/add" component={VideoAdd} />
                    <AppRoute exact path="/videos/:id" component={VideoDetail} />

                    <AppRoute exact path="/email-templates" component={EmailTemplates} />
                    <AppRoute path="/email-templates/add" component={EmailTemplateAdd} />
                    <AppRoute path="/email-templates/:id" component={EmailTemplateDetail} />

                </Switch>    
            </Router>
      );
}
export default App;
