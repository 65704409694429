import React, { Component } from "react"
import { newsService } from '../../services/tibesa.api'
import LoadingOverlay from "react-loading-overlay";
import Swal from 'sweetalert2'
import News  from '../../models/news';
import NewsTranslated  from '../../models/newsTranslated';

import NewsForm from './components/news-form'
import loSanitize from 'lodash';

export default class NewsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: null,
        }

        this.submitChanges = this.submitChanges.bind(this);

    }

    componentDidMount() {
        let curId=this.props.match.params.id;
        let curLan =this.props.match.params.language;
        Promise.all([newsService.get(curId)])
        .then(([news]) => {
             news.body = loSanitize.unescape(news.body);
            //antes de asignasr.. me piden el detalle de un idioma?
            if (this.props.match.params.language&&curId){   //language  y  Id Padre 
                //tray to get that language. 
                Promise.all([newsService.getNewsTranslation(curId,curLan)])
                .then(([newsT]) => {
                    if (newsT){
                        newsT.body = loSanitize.unescape(newsT.body);
                    }

                    this.setState({ news : new NewsTranslated(newsT),language:  curLan.toLowerCase()});
                    //si no habia datos todo el News esta en null 
                    if (!newsT.newsId)  {
                         //nueva TRaduccion. 
                         newsT.language = curLan.toLowerCase();
                         newsT.newsId = curId;
                         this.setState({
                              news: new NewsTranslated(newsT),
                              language:curLan.toLowerCase(),
                         });   
                    } 
                });
            }
            else{
                this.setState({ news: new NewsTranslated(news),language:'es'});
            }
        })
    }

    showTab(language){
        if (language===this.state.news?.language){
            return "tab-paneactive show active fade p-3"
        }
        return "display:none"
    }
    showNav(language){
        if (language===this.state.news?.language){
            return "nav-link show active "
        }
        return "nav-link"
    }

    selectTab(language){

        if (language===this.state.news?.language){
            return "true"
        }
        return "false"
    }

    submitChanges(formData,id) {
        Swal.fire({
            title: '¿Seguro que deseas editar la Página?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: () => {

                return newsService.update(id,formData)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
                if (response.value && response.value.data && response.value.data.status === 200) {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: '¡Noticia actualizada con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }
                    ).then(() => {
                        window.location.reload();
                    });
                } else if(!response.dismiss === 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }

    submitTransalation(formData,id,language) {     
        Swal.fire({
            title: '¿Seguro que deseas editar la Página?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: () => {

                return newsService.createOrUpdateTranslation(id,language, formData)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
                if (response.value && response.value.data && response.value.data.status === 200) {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: '¡Página actualizada con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }
                    ).then(() => {
                        window.location.reload();
                    });
                } else if(!response.dismiss === 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }

    render() {

        let form;
        let formEn;

        if (this.state.news != null) {
            if (this.state.news.language==='en'){
                formEn = <NewsForm news={this.state.news} onSubmitTranslate={this.submitTransalation} ></NewsForm>
            }
            else{
                form = <NewsForm news={this.state.news} onSubmit={this.submitChanges}></NewsForm>
            }

        }
        else
            form = <LoadingOverlay></LoadingOverlay>

        return (
            <>
                <div className="air__utils__heading">
                    <a href="/news">Listado de noticias</a><span>{" "}/{" "}Editar noticia #{this.props.match.params.id}</span>
                </div>
                
                <div className="card mt-3 tab-card">
                <div className="card-header tab-card-header">
                    <ul className="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a className={this.showNav('es')} id="one-tab" data-toggle="tab" href={"/news/"+this.props.match.params.id} role="tab" aria-controls="One" aria-selected={this.selectTab('es')} >Español</a>
                        </li>
                        <li className="nav-item">
                            <a className={this.showNav('en')}  id="two-tab" data-toggle="tab"href={"/news/"+this.props.match.params.id+"/en"}role="tab" aria-controls="Two" aria-selected={this.selectTab('en')}>English</a>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="myTabContent">
                    <div className={this.showTab('es')} id="one" role="tabpanel" aria-labelledby="one-tab">
                            {form}          
                    </div>
                    <div className={this.showTab('en')}  id="two" role="tabpanel" aria-labelledby="two-tab">
                            {formEn}          
                    </div>
                </div>
                </div>
            </>
        )
    }
}
