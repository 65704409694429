import React, { Component } from 'react'

import TableSubCategories from './components/table';

import axios from 'axios';
import Swal from 'sweetalert2';
import configs from './../../configs/config'

import Switch from "react-switch";
import Modal from 'react-modal';
import LoadingOverlay from 'react-loading-overlay';

export default class Subcategories extends Component {

    constructor() {
        super();
        this.state = {
            isuploading: false,
            checked: false,
            modalIsOpen: false,
            showsuccess: false,
            config: {
                headers: { 'content-type': 'application/json' }
            },
            subs: [],
            cates: [],
            idCategory: '',
            name: '',
            nameEn: '',
            isEdit: false,
            id: '',
        };

        this.getData();
        this.getCats();
    }

    getData = () => {
        axios.get(`${configs.URL_API_BASE}/subcategories/getSubcategories`, {})
            .then(res => {
                if (res.data.status == 200) {
                    let auxData = res.data.results;
                    auxData.forEach((item) => {
                        item.id = item.idSubCategory;
                        item.status_val = (item.status == '1') ? 'Activo' : 'Inactivo';
                    })
                    this.setState({ subs: auxData });
                }
            });
    }

    getCats = () => {
        axios.get(`${configs.URL_API_BASE}/categories/getCategories`, {})
            .then(res => {
                if (res.data.status == 200) {
                    let auxCats = res.data.results;
                    auxCats.forEach((item) => {
                        item.id = item.id_category;
                    })
                    this.setState({ cates: auxCats });
                }
            });
    }

    onSubmit = (e) => {
        e.preventDefault();

        let newItem = {
            name: this.state.name,
            nameEn: this.state.nameEn,
            idCategory: this.state.idCategory,
            status: (this.state.checked) ? '1' : '0'
        }

        let url = ``;

        this.setState({ isuploading: true });
        if (this.state.isEdit) {
            url = `${configs.URL_API_BASE}/subcategories/${this.state.id}/edit`;
            axios.put(url, newItem, this.state.config).then((response) => {
                if (parseInt(response.data.status) == 200) {
                    this.setState({ isuploading: false });
                    this.closeModal();
                    this.getData();
                    this.getCats();
                    Swal.fire('Ingreso Correcto', 'Subcategoria ingresada correctamente.', 'success');

                } else {
                    this.setState({ isuploading: false });
                    console.log("fail");
                }
            });
        } else {
            url = `${configs.URL_API_BASE}/subcategories/createSubcategory`;
            axios.post(url, newItem, this.state.config).then((response) => {
                if (parseInt(response.data.status) == 200) {
                    this.setState({ isuploading: false });
                    this.closeModal();
                    this.getData();
                    this.getCats();
                    Swal.fire('Ingreso Correcto', 'Subcategoria ingresada correctamente.', 'success');

                } else {
                    console.log("fail");
                    this.setState({ isuploading: false });
                }
            });

        }


        // console.log('Add new Item', newItem);
        e.target.reset();
    }

    onInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    activeCheck = (checked) => {
        this.setState({ checked });
    }

    openModal = () => {
        this.setState({
            modalIsOpen: true,
            name: '',
            nameEn: '',
            isEdit: false,
            checked: true,
            idCategory: (this.state.cates[0] ||{id:1}).id ,
        });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    delete = (id) => {
        axios.get(`${configs.URL_API_BASE}/subcategories/${id}/delete`)
            .then(res => {
                //console.log(res);
                if (parseInt(res.data.status) == 200) {
                    this.getData();
                    Swal.fire('Eliminado!', 'Elemento eliminado correctamente.', 'success');
                } else {
                    Swal.fire(`Error!','Contacte al desarrollador.${res.data.status}`, 'warning');
                }
            }).catch(err => {
                console.log(err);
                Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
            });
    }

    handleOpenEdit = record => {
        console.log(record);
        this.setState({
            isEdit: true,
            name: record.name,
            nameEn: record.NameT,
            idCategory: record.idCategory,
            modalIsOpen: true,
            id: record.idSubCategory,
        });

        record.status == 1 ? this.setState({ checked: true }) : this.setState({ checked: false });
    }

    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Subcategorías</h2>
                    <a className="btn btn-primary d-flex justify-content-between align-self-center" onClick={this.openModal}>
                        <span>Nueva Subcategoría &nbsp;</span>
                        <span><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        <TableSubCategories handleOpenEdit={this.handleOpenEdit} delete={this.delete} subs={this.state.subs}></TableSubCategories>
                    </div>
                </div>
                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    className="Modal"
                    contentLabel="Crear Amenidad">
                    <form onSubmit={this.onSubmit}>
                        <div className="modal-dialog">
                            <LoadingOverlay
                                active={this.state.isuploading}
                                spinner
                                text='Subiendo el contenido...'
                            >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{this.state.isEdit ? "Editar Subcategoría" : "Crear Subcategoría"}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row content-modal-mobile">
                                            <div className="col-12">
                                                <div className="form-row my-3">
                                                    <div className="col-12 d-flex align-items-center">
                                                        <label className="form-check-label mr-3" htmlFor="priorizar">Activar esta Subcategoría?</label>
                                                        <Switch onChange={this.activeCheck} checked={this.state.checked} />
                                                    </div>
                                                    <div className="col-6 my-3">
                                                        <label>Nombre<span
                                                            className="text-red">*</span></label>
                                                        <input name="name" autoFocus={true} value={this.state.name} type="text" className="form-control" onChange={this.onInputChange} required />
                                                    </div>
                                                    <div className="col-6 my-3">
                                                        <label>Nombre en ingles<span
                                                            className="text-red">*</span></label>
                                                        <input name="nameEn" value={this.state.nameEn} type="text" className="form-control" onChange={this.onInputChange} required />
                                                    </div>
                                                    <div className="col-6 my-3">
                                                        <label>Categoría Principal <span
                                                            className="text-red">*</span></label>
                                                        <select className="form-control" value={this.state.idCategory} name="idCategory" onChange={this.onInputChange}>
                                                            {
                                                                this.state.cates.map((category, pos) => (
                                                                    <option key={pos} value={category.id_category}>
                                                                        {category.nombre}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-danger btn-lg" type="button" onClick={this.closeModal}>CANCELAR</button>
                                        <button className="btn btn-success btn-lg" type="submit">GUARDAR</button>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </form>
                </Modal>
            </>
        )
    }
}
