import React, { Component } from "react"
import { companiesService } from '../../services/tibesa.api'
import LoadingOverlay from "react-loading-overlay";
import Swal from 'sweetalert2'
import Company from "../../models/company";
import CompanyForm from "./components/company-form";
import CompanySmtp from "./components/company-smtp";
import * as ReactBootstrap from 'react-bootstrap';


export default class CompanyDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: null
        }
        this.submitChanges = this.submitChanges.bind(this);
        this.submitSmtpChanges = this.submitSmtpChanges.bind(this);

    }

    componentDidMount() {
        Promise.all([
        companiesService.get(this.props.match.params.id)])
        .then(([company]) => {
            this.setState({ company: new Company(company) });
        })
    }


    submitChanges(formData) {
        Swal.fire({
            title: '¿Seguro que deseas editar los datos de la Empresa?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return companiesService.update(this.props.match.params.id, formData)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
                console.log(response);
                if (response.value && response.value.data && response.value.data.status == 200) {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: '¡Empresa actualizada con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }
                    ).then(() => {
                        window.location.reload();
                    });
                } else if(!response.dismiss == 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }

    submitSmtpChanges(formData) {
        Swal.fire({
            title: '¿Seguro que deseas editar los datos de la Empresa?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return companiesService.updateSMTP(this.props.match.params.id, formData)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
                console.log(response);
                if (response.value && response.value.data && response.value.data.status == 200) {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: '¡Datos Actualizados con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }
                    ).then(() => {
                        window.location.reload();
                    });
                } else if(!response.dismiss == 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }

    render() {

        let form;
        let smtpData;

        if (this.state.company != null) {
            form = <CompanyForm company={this.state.company} onSubmit={this.submitChanges}></CompanyForm>
            smtpData = <CompanySmtp company={this.state.company} onSMTPUpdate={this.submitSmtpChanges}></CompanySmtp>

        }
        else
            form = <LoadingOverlay></LoadingOverlay>

        return (
            <>
                <div className="air__utils__heading">
                    <a href="/companies">Listado de Empresas</a><span>{" "}/{" "}Editar empresa #{this.props.match.params.id}</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="clearfix">&nbsp;</div>
                        <ReactBootstrap.Tabs defaultActiveKey="defaultData" id="controlled-tab">                
                            <ReactBootstrap.Tab title="Generales" eventKey="defaultData" >
                                { form }
                            </ReactBootstrap.Tab>
                            <ReactBootstrap.Tab title="Conf. Correo SMTP" eventKey="smtpData" >
                                { smtpData }
                            </ReactBootstrap.Tab>
                        </ReactBootstrap.Tabs>
                    </div>
                </div>
            </>
        )
    }
}
