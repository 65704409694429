import React, { Component } from 'react';
import Swal from 'sweetalert2'
import { RULE_FUNCTIONS, Validator } from '../../../utilities/form-validation';
import Video  from '../../../models/video';
import configs from '../../../configs/config';
import { Editor } from '@tinymce/tinymce-react';
import classNames from 'classnames';
import Switch from "react-switch";

export default class VideosForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...(props.video ||  new Video()),  
            errors: [],
            checked :null,
        };
        this.state.formTitle = this.state.title || 'Crear un nuevo Video';
        this.state.editMode = this.state.id !== null;   //HAY QUE  USARLO::: 
        this.submitCallback = this.props.onSubmit;
     /*   this.translateCallback = this.props.onSubmitTranslate; */
        let validationRules =
            [
                {
                    fields: ['title'],
                    rule: v => RULE_FUNCTIONS.required(v)  && RULE_FUNCTIONS.maxLen(v, 255)
                },
            ]
        this.validator = new Validator(validationRules);
        }
    componentDidMount(){
        this.setState({
            checked : this.state.status==1?true:false,
            imagepreview : `${configs.URL_IMAGESNEW}${this.state.imageUrl}`   
        });
        console.log(`estes es el statsus ${this.state.status}`);
    }
    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) === -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    handleChangeInput = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
        this.validateInput(name, value);
        
    }

    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) === -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    hasError = (name) => {
        return this.state.errors.indexOf(name) !== -1;
    }
    parseUrlVideoEmbed = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length == 11)
            ? match[2]
            : null;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let errors = this.validator.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            Swal.fire('Error!', `cuentas con ${errors.length} error(es) en el formulario, por favor revisa los campos en rojo`, 'error');
            return false;
        }
/*
        const formData = new FormData();
        
        formData.append('titulo', this.state.title);
        formData.append('descripcion', this.state.description);
        formData.append('prioridad', this.state.priority?this.state.priority:0);
        formData.append('status', this.state.status);
        formData.append('urlVideo', `https://www.youtube.com/embed/${this.parseUrlVideoEmbed(this.state.videoUrl)}`); 
        
        if (typeof this.translateCallback === "function"){
            formData.append('id', this.state.id);          
            formData.append('newsId', this.state.newsId);  //la pagina padre de la traduccion. 
            formData.append('language', this.state.language);
          //&  console.log(this.state);
            this.translateCallback(formData,this.state.id);
        }
        else */
        let normalUrl = this.state.videoUrl;
        let newitem = {
            id: this.state.id,
            titulo : this.state.title,
            descripcion : this.state.description,
            prioridad : this.state.priority?this.state.priority:0,
            status : this.state.status,
            urlVideo :  `https://www.youtube.com/embed/${this.parseUrlVideoEmbed(normalUrl)}`
        }
        if (typeof this.submitCallback === "function") { 
            this.submitCallback(newitem);
        } 
    }


    onChangeFile = e => {
        this.setState({
            image: e.target.files[0],
            imagepreview: URL.createObjectURL(e.target.files[0])
        });
    };

    activateCheck = checked => {
        this.setState({ 
            checked ,
            status : checked ? "1" : "0",
        });
    };

    render() {
        return (
            <>
                <form onSubmit={this.handleSubmit} autoComplete="off">
                <div className="">
                        <div className="modal-content">

                            <div className="panel panel-default">
                            </div>
                            <div className="modal-body">
                                    <div className="row content-modal-mobile my-0 my-lg-4">
                                        <div className="col-12">
                                            <div className="form-row my-3">
                                                <div className="col-12 d-flex align-items-center bt-3">
                                                    <label className="form-check-label mr-3" htmlFor="priorizar">Video Activo?</label>
                                                    <Switch onChange={this.activateCheck} checked={this.state.checked?this.state.checked:false}/> 
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-12 col-lg-6 my-3">
                                                    <label>Nombre <span
                                                        className="text-red">*</span></label>
                                                    <input type="text" autoFocus={true} name="title" value={this.state.title} className="form-control" onChange={this.handleChangeInput} required />
                                                </div>
                                                <div className="col-12 col-lg-6 my-3">
                                                    <label>URL del Video <span
                                                        className="text-red">*</span></label>
                                                    <input type="text" name="videoUrl" value={this.state.videoUrl} className="form-control" onChange={this.handleChangeInput} placeholder={`https://www.yoututube.com`} required />
                                                </div>
                                                <div className="col-12 my-3">
                                                    <label>Descripción del Video <span   className="text-red">*</span></label>
                                                <Editor
                                                    apiKey={configs.TINYMCE_API_KEY} 
                                                    value={this.state.description}
                                                    init={{
                                                        height: 500,
                                                        paste_as_text: true,
                                                        images_upload_url: `${configs.URL_API_BASE}/uploadImages/image`,
                                                        automatic_uploads: true,
                                                        block_unsupported_drop: true,
                                                        menubar: false,
                                                        content_css : "https://getbootstrap.com/docs/4.5/dist/css/bootstrap.min.css",
                                                        menu: {
                                                            edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall' },
                                                            insert: { title: 'Insert', items: 'link image' },
                                                            view: { title: 'View', items: 'visualaid' },
                                                            format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat' }
                                                        },
                                                        plugins: "image,paste,code",
                                                        paste_data_images: true,
                                                        toolbar:
                                                            'undo redo | formatselect | table link bold italic backcolor | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | removeformat | image code',
                                                        }}
                                                    onEditorChange={(content) => {
                                                        const data = content; 
                                                        this.setState({ description: data });
                                                    
                                                        
                                                    }}
                                                />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                            <div className="modal-footer">
                                <button className="btn btn-success btn-lg" type="submit">Guardar</button>
                                <button className="btn float-right" type="button" onClick={() => window.location.href = '/videos'}>CANCELAR</button>

                            </div>
                        </div>
                    </div>
                </form>
            </>
        )

    }

}