import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import "font-awesome/css/font-awesome.min.css";
import configs from "../../../configs/config";

export default class TableContactSearch extends Component {
    constructor(props) {
        super(props);
        const { data, dataselected } = props;


        this.columns = [
           
            {
                key: "name",
                text: "Nombre",
                align: "left",
            },
            {
                key: "email",
                text: "Correo",
                align: "left",
            },
            { 
                key: "action",
                text: "Acciones",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                      
                        <Fragment>
                            <button
                                className="btn btn-info btn-sm"
                                onClick={() => this.addRecord(record)}>agregar
                                <i className="fa fa-add"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 25,
            length_menu: [50],
            show_pagination: true,
            show_length_menu:false,
            show_filter: false,
            show_info: false,
            bLengthChange: false,
            grouping: true
        };

        this.state = {
            records: data,
            row_clicks: dataselected
        };

        this.updateRecords = this.updateRecords.bind(this);
    }


    ref= ()  => {
        return this;
    }

    openImageView = record =>{
        this.props.openLigthBox(`${configs.URL_IMAGESNEW}${record.logoUrl}`);
    }

    onRowClicked(record,index){
        record.selected = true;
        this.props.onRowClicked(record,index)
    }

    handleChange(record,index){
       this.props.propertyRowChanged(record,index)
    }

    
    addRecord = (record) => {
        console.log(record);
        this.props.handleAddRecord(record);
    }

    componentWillReceiveProps = next_props => {
        this.setState({ 
            records: next_props.data,
            row_clicks : next_props.dataselected
        });
    };

    updateRecords(newValues){
        console.log(newValues);
        this.setState({
            data : newValues
        })
    }

    render() {
        return (
                <ReactDatatable
                    config={this.config}
                    records={this.state.records}
                    columns={this.columns}
                />            
        );
    }
}
