import React, { Component, Fragment } from 'react'
import axios from 'axios';
import logo from '../../logo.png';
import configs from './../../configs/config'

export default class login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: null,
            email: '',
            password: '',
            isSignedUp: false,
        };
    }
    componentDidMount = () => {
        console.log("didmount");
        const localStorage = window.localStorage;
        if (localStorage.getItem('apitoken')) {
            this.redirectToDashboard();
        }
    }

    redirectToDashboard = () => {
        window.location.href = '/';
    };


    handleChangeInput = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const datatopost = {
            email: this.state.email,
            password: this.state.password,
        };
        axios.post(`${configs.URL_API_BASE}/users/login`, datatopost)
            .then(res => {
                if (res.data.token != null) {

                    this.setState({
                        isSignedUp: 'ok'
                    });
                    const localStorage = window.localStorage;
                    localStorage.setItem('apitoken', res.data.token);
                    localStorage.setItem('userlogo', res.data.results[0].imgProfile);
                    localStorage.setItem('user_name', `${res.data.results[0].name} ${res.data.results[0].lastname}`);
                    localStorage.setItem('user_type', res.data.results[0].userType);
                    localStorage.setItem('user_id', res.data.results[0].user_id);

                    this.redirectToDashboard();
                } else {
                    this.setState({
                        status: 'fail'
                    });
                }
            })
    }
    render() {
        return (
            <Fragment>
                <div className="login">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-5 ">
                                <div className="pt-5 pb-5 mt-auto d-flex justify-content-center">
                                    <img src={logo} className="logo" alt="Tibesa Realty" />
                                </div>
                                <div className="air__auth__container pl-5 pr-5 pt-5 pb-5 bg-white text-center">
                                    {this.state.status == 'fail' ?
                                        <div className="alert alert-danger" role="alert">
                                            acceso incorrecto.
                                </div> :
                                        <div></div>
                                    }

                                    <div className="text-dark font-size-30 mb-4">Inicia Sesión</div>
                                    <form onSubmit={this.handleSubmit} className="mb-4">
                                        <div className="form-group mb-4">
                                            <input type="email" name="email" className="form-control" placeholder="Email Address" onChange={this.handleChangeInput} />
                                        </div>
                                        <div className="form-group mb-4">
                                            <input type="password" name="password" className="form-control" placeholder="Password" onChange={this.handleChangeInput} />
                                        </div>
                                        <button className="text-center btn bg-accent-color-2 w-100 font-weight-bold font-size-18">
                                            ENTRAR
                                    </button>
                                    </form>
                                    {/*
                                <a href="recuperar-contrasena.html" className="text-secondary font-weight-bold font-size-18">Contraseña Olvidada?</a>
                                */}
                                </div>
                                <div className="mt-auto pb-5 pt-5">
                                </div>
                                <div className="text-gray-4 text-center">© 2020 Bienes Raices Tibera Realty. All rights reserved.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
