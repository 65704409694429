import React, { Component } from 'react';
import Swal from 'sweetalert2'
import { RULE_FUNCTIONS, Validator } from '../../../utilities/form-validation';
import classNames from 'classnames';
import Company from '../../../models/company';
import Switch from "react-switch"


export default class CompanySmtp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...(props.company || new Company()),
            errors: []
        };

        this.state.editMode = this.state.id;
        this.submitCallback = this.props.onSMTPUpdate;

        let validationRules =
            [
                {
                    fields: ['smtpHost'],
                    rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 300)
                }
            ]
        this.validator = new Validator(validationRules);
    }

    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) == -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    handleChangeInput = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
        this.validateInput(name, value);
    }

    handleSwitches = (value, _, id) => {
        this.setState({ [id]: value });
    }

    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) == -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    hasError = (name) => {
        return this.state.errors.indexOf(name) !== -1;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let errors = this.validator.validateState(this.state);
        console.log(errors);
        if (errors.length > 0) {
            this.setState({ errors });
            Swal.fire('Error!', ` ${errors.length} error(es) en el formulario, por favor revisa los campos en rojo`, 'error');
            return false;
        }

        const formData = new FormData();
        formData.append('smtpHost', this.state.smtpHost);
        formData.append('port', this.state.port);
        formData.append('smtpAccount', this.state.smtpAccount);
        formData.append('smtpPassword', this.state.smtpPassword);
        formData.append('useSSL', this.state.useSSL);
        formData.append('dailyEmails', this.state.dailyEmails);

        if (typeof this.submitCallback == "function") {
            this.submitCallback(formData);
        }
    }

    render() {
        return (
            <>

                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <div className="">

                        <div className="modal-content">

                            <div className="panel panel-default">
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-row ">

                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>smtpHost : <span
                                                    className="text-red">*</span></label>
                                                <input name="smtpHost" value={this.state.smtpHost} type="text"
                                                    onChange={this.handleChangeInput}
                                                    className={classNames('form-control')} />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>port : <span
                                                    className="text-red">*</span></label>
                                                <input name="port" type="text" value={this.state.port} onChange={this.handleChangeInput}
                                                    className={classNames('form-control')} />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>smtpAccount : </label>
                                                <input name="smtpAccount" type="text" value={this.state.smtpAccount} onChange={this.handleChangeInput}
                                                    className={classNames('form-control')} />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>smtpPassword : </label>
                                                <input name="smtpPassword" type="password" value={this.state.smtpPassword} onChange={this.handleChangeInput}
                                                    className={classNames('form-control')} />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>useSSL : </label>
                                                 <Switch onChange={this.handleSwitches} checked={this.state.useSSL ?  this.state.useSSL == true : false} id="useSSL" />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>email por Dia : </label>
                                                <input name="dailyEmails" type="text" value={this.state.dailyEmails} onChange={this.handleChangeInput}
                                                    className={classNames('form-control')} />
                                            </div>
                                            <div className="d-flex justify-content-around col-12">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-success btn-lg" type="submit">Guardar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        )

    }

}