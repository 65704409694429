import React, { Component } from "react"
import { contactsService } from '../../services/tibesa.api'
import LoadingOverlay from "react-loading-overlay";
import Swal from 'sweetalert2'
import Contact from "../../models/contact";
import ContactForm from "./components/contact-form";

export default class ContactDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: null
        }
        this.submitChanges = this.submitChanges.bind(this);
    }

    componentDidMount() {
        Promise.all([
        contactsService.get(this.props.match.params.id)])
        .then(([contact]) => {
            this.setState({ contact: new Contact(contact) });
            console.log(contact);
        })
    }


    submitChanges(formData) {
        Swal.fire({
            title: '¿Seguro que deseas editar el Contacto?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return contactsService.update(this.props.match.params.id, formData)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
                console.log(response);
                if (response.value && response.value.data && response.value.data.status == 200) {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: '¡Contacto actualizado con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }
                    ).then(() => {
                        window.location.reload();
                    });
                } else if(!response.dismiss == 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }


    render() {

        let form;
        if (this.state.contact != null) {
            form = <ContactForm contact={this.state.contact} onSubmit={this.submitChanges}></ContactForm>
        }
        else
            form = <LoadingOverlay></LoadingOverlay>

        return (
            <>
                <div className="air__utils__heading">
                    <a href="/contacts">Listado de contactos de email</a><span>{" "}/{" "}Editar contacto #{this.props.match.params.id}</span>
                </div>
                {form}
            </>
        )
    }
}
