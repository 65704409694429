import React, { Component } from 'react'
import { RULE_FUNCTIONS, Validator } from '../../../utilities/form-validation';
import { categoriesService,placesService,propertiesService } from '../../../services/tibesa.api'
import config from '../../../configs/config';


export default class SectionPropertyListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category:'',  //filter
            city:'',    //filter
            title:'',   // texto
            bodyHtml:'',  /// Texto adicional. 
            categories:[],
            cities:[],
            states:[],
            properties:[],
        }
        this.submitCallBack = this.props.onSubmit;

        let validationRules =
        [
            {
                fields: ['category'],
                rule: v => RULE_FUNCTIONS.required(v) 
            },
            {
                fields: ['title'],
                rule: v => RULE_FUNCTIONS.required(v)  && RULE_FUNCTIONS.maxLen(v, 100)
            },
        ]
        this.validator = new Validator(validationRules);

    }

    componentDidMount(){
        Promise.all([categoriesService.getAll(),placesService.getStates()],
        )
        .then(([categories,states]) => {
            this.setState({ categories, states});
        })

    }
    
    handleChangeInput = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleStateChanged = (event) => {
        let {value } = event.target;

        if (value){
            Promise.all([placesService.getCitiesOfState(value)],
            )
            .then(([cities]) => {
                this.setState({cities});
            })
        } 
        else
        this.setState({cities:[]})
    }

    handleCityChanged = (event) => {
        let {value } = event.target;
        if (value){
            var query =`cityId=${value}&categoryId=${this.state.category}`
            Promise.all([propertiesService.getPropertiesByQuery(query)],
            )
            .then(([properties]) => {
                this.setState({properties});
            })
        } 
        else
        this.setState({properties:[]})
    }


    generateHtml(){
        var ID = "PropertyListing-" + (new Date()).toTimeString().replace(/-/g,'').replace(/:/g,'').replace(/ /g,'').split('GMT')[0]
        var query =`cityId=${this.state.city}&categoryId=${this.state.category}`
        var moduleHTML =`<section id=${ID} name="Section-PropertyListing" class="container">
                <div class="row no-gutters bg-light position-relative">
                    <div class="col-md-3 mb-md-0 p-md-4">
                    </div>
                    <div class="col-md-9 position-static p-4 pl-md-0" >    
                        <div class="row">		
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="shorting-wrap">
                                    <h5 class="shorting-title">2 Resultados</h5>
                                </div>
                            </div>
                        </div>
                        <div class="row">		
                            <div class="col-lg-12 col-md-12 col-sm-12" name="SearchContainer" name="SearchContainer">
                            </div>  
                        </div>                          
                    </div>
                </div>
                <script>
                    $(function() {
                        $.ajax({
                            url: '${config.URL_API_BASE}/properties/getPropertiesByQuery?${query}',
                            dataType: "json",
                            success: function(result){
                                container=$('#SearchContainer');
                                container.append("<div class='row'>
                                                <div class='col-md-4 col-sd-12'>
                                                    <div class='image'>
                                                        <img class='img-fluid img-responsive' src=''>
                                                    </div>
                                                </div>
                                                <div class='col-md-5 col-sd-12'>
                                                    <div class='ser-header'>
                                                    </div>
                                                    <div class='ser-description'>
                                                    </div>
                                                    <div class='ser-review'>
                                                    </div>
                                                </div>
                                                <div class='col-md-5 col-sd-12'>
                                                    <div class='ser-price'>
                                                    </div>
                                                </div>
                                            </div>
                                            ");
                            }
                        });
                    }
                </script>
        </section> `;
        return { id:`${ID}`, name:`Section-PropertyListing`, value:moduleHTML};
    }

    submitChanges = () => {
        //Muestra los errores... 
        let errors = this.validator.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            return false;
        }

        if (this.submitCallBack){
            this.submitCallBack(this.generateHtml());
            this.clearForm();    

        }
    }

    clearForm = () =>{
        this.setState({
            category:'',  //filter
            city:'',    //filter
            title:'',   // texto
            bodyHtml:''  /// Texto adicional. 
        })
    }

    render() {
        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <label htmlFor="secName">Categoria (requerido): </label>
                                <select className="form-control" name="category" component="select"  validate={this.required}>
                                    <option value='0'> Selecciona una categoria</option>
                                    {this.state.categories.length && this.state.categories.map((item, pos) => (
                                        <option key={pos} value={item.categoryId}> {item.nombre} </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label htmlFor="secName">Estado (requerido): </label>
                                <select className="form-control" name="state" component="select"  onChange={this.handleStateChanged}   validate={this.required}>
                                    <option value=''> Selecciona una estado</option>
                                    {this.state.states.length && this.state.states.map((item, pos) => (
                                        <option key={pos} value={item.id_estado}> {item.estado} </option>
                                    ))}
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label htmlFor="secName">Ciudad (requerido): </label>
                                <select className="form-control" name="city" component="select"  onChange={this.handleCityChanged} validate={this.required}>
                                    <option value='0'> Selecciona una Ciudad</option>
                                    {this.state.cities.length && this.state.cities.map((item, pos) => (
                                        <option key={pos} value={item.id_municipio}> {item.nombre_municipio} </option>
                                    ))}
                                </select>
                              {this.state.properties.length>0 &&  <p> {this.state.properties.length}</p>}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label htmlFor="secName">Titulo (requerido - max 100): </label>
                                <input type="text" name="title"  className="form-control" value={this.state.title} onChange={this.handleChangeInput} ></input>                               
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label htmlFor="secName">Texto Adicional: </label>
                                <textarea rows="5" name="bodyHtml"  className="form-control" value={this.state.bodyHtml} onChange={this.handleChangeInput} ></textarea>
                            </div>
                        </div>
                        {
                            this.state.errors && <p> <br/>
                            <div className="alert alert-danger" role="alert">
                                Hay datos requeridos o No Validos!
                            </div></p>
                        }
                    </div>
                    <div className="card-footer">
                        <input type="button" className="btn btn-primary" onClick={this.submitChanges} value="Agregar"></input>
                    </div>
                </div>
            </>    
        )
    }
}
