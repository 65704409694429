import React, { Component } from 'react'

import { propertiesService, pagesService } from './../../services/tibesa.api';
import Swal from 'sweetalert2'

import { Field, Form } from 'react-final-form';
import Wizard from './../../components/wizard';
import LoadingOverlay from 'react-loading-overlay';

export default class LandingPageAdd extends Component {

    constructor(props) {
      super(props);

      this.state = {
        templates:[],
        properties:[],
        currentPage:0,
        selectedOption:'',
        isuploading:false,
        pageUrl :'',
        name :'',
      };
      this.getData();
    }

    getData = () => {
        let properties = propertiesService.getPropertiesBasic();
        let templates = pagesService.getLandingTemplates();
        Promise.all([properties,templates ])
        .then(([properties_c,templates_c]) => {
            console.log(properties_c);
            this.setState( { properties: properties_c, templates: templates_c });
        });
    }

    onSelectedOption = (selected) =>{
        if (selected.length==1){
            this.setState({
                selectedOption : selected[0]
            })
        }else{
            this.setState({
                selectedOption : ''
            })
        }
    }

    onSubmit = async values => {
        await this.sleep(300)
        // primero intenta  convertir el template a la propiedad. 
        let bodyJson = values;
        console.log(JSON.stringify(values));
        let plantilla = this.state.templates.filter( x => x.name == bodyJson.plantilla);
        if (plantilla[0]){
            //console.log(JSON.stringify(plantilla[0]));
            // agrega primeramente al json los valoes de los css, js, content de la seleccionada. 
            if (plantilla[0].content_style) bodyJson.content_style = plantilla[0].content_style
            if (plantilla[0].content_css) bodyJson.content_css = plantilla[0].content_css
            if (plantilla[0].content_js) bodyJson.content_js = plantilla[0].content_js
            this.setState({isuploading :true})
            let frmData = new FormData;
            frmData.append("id",bodyJson.property);
            frmData.append("bodyHtml",plantilla[0].body)
            
            pagesService.processLandingPageBody(frmData).then( res => {
                let data = res.data;
                if (data.status=="200"){
                    let frm = new FormData;
                    // let bodyHtml64 = new Buffer.from(data.results,'utf-8').toString("base64")

                    //now try to send this results to data base. but first convert body to <Base64>
                    frm.append("pageName",bodyJson.name);
                    frm.append("title",bodyJson.name);
                    frm.append("description","");
                    frm.append("keywords","");
                    frm.append("metas","");
                    frm.append("pageUrl",bodyJson.pageUrl);
                    frm.append("bodyJson",JSON.stringify(bodyJson));
                    frm.append("bodyHtml",data.results);
                    frm.append("isActive",1);
                    // now the promise. 
                    pagesService.createLandingPage(frm).then( res => {
                        if (res.data.status="200"){
                            // successull >+iuytrewq    
                            // redirect to index
                            Swal.fire(
                                {
                                    title:  '¡Perfecto!',
                                    text: '¡LandingPage creada con éxito!',
                                    icon: 'success',
                                    showCancelButton: false,
                                    confirmButtonText: 'OK'
                                }
                            ).then(() => {
                                window.location="/landingpages";
                            });
                        }
                        else {
                            this.setState({isuploading :false})
                            console.log(res.data.results);
                        }
                    }).catch(err => {
                        this.setState({isuploading :false})  // api route fail. 
                        console.log("Api Route fail...");
                    });
                }else{
                    this.setState({isuploading :false})  // data sent to process fail
                    console.log("Data Sent was rejected");
                }
            }).catch(err => {
                this.setState({isuploading :false}) // something on local variables fail.
                console.log("Invalid Data to be sent");
            })
            //Promesa y lladada a.. 
        }else
            console.log("Something was wrong no template found");
    }

    sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    required = value => (value ? undefined : 'Requerido')

    Error = ({ name }) => (
            <Field
            name={name}
            subscribe={{ touched: true, error: true }}
            render={({ meta: { touched, error } }) =>
                touched && error ? <div className="alert alert-danger" role="alert">{error}</div> : null
            }
            />
    )
    
    render() {    
        const currentPage = 0;
        return(
            <LoadingOverlay
                active={this.state.isuploading}
                spinner
                text='Generando LangingPage...'>
                <div className="container-fluid" id="grad1">
                    <div className="row justify-content-center mt-0">
                        <div className="col-12 col-sm-9 col-md-7 col-lg-6 text-center p-0 mt-3 mb-2">
                            <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
                                <h2><strong>Configurando Pagina de Aterrizaje</strong></h2>
                                <p>Llene correctamente la información</p>
                                <div className="row">
                                        <div className="col-md-12 mx-0">
                                            <ul id="progressbar">
                                                <li className={currentPage >= 0 ? 'active' : ''} id="pageName"><strong>Nombre</strong></li>
                                                <li className={currentPage >= 1 ? 'active' : ''} id="pageLayout"><strong>Plantilla</strong></li>
                                                <li className={currentPage >= 2 ? 'active' : ''} id="pageSearch"><strong>Propiedad</strong></li>
                                            </ul>
                                            <div className="card-body">
                                            <Wizard initialValues={{ version: 1, plantilla:''}}  onSubmit={this.onSubmit} >
                                                <Wizard.Page id="1">
                                                    <div className="form-group text-left">
                                                        <label  htmlFor="name">Nombre de la Pagina</label>
                                                        <Field name="name" component="input" 
                                                            className="form-control" type="text"  
                                                            placeholder="First Name" validate={this.required} />
                                                        <this.Error name="name" />
                                                    </div>
                                                    <div className="form-group text-left">
                                                        <label htmlFor="pageUrl">Url Que utilizará (http://www.bienesraicestibesa.mx/<b>my-url-friendly</b>)</label>
                                                        <Field name="pageUrl" component="input" 
                                                            className="form-control" type="text"
                                                            placeholder="/my-url-friendly" validate={this.required} />
                                                        <this.Error name="pageUrl" />
                                                    </div>
                                                </Wizard.Page>
                                                <Wizard.Page id="2" validate={values => {
                                                    const errors = {}
                                                    if (!values.plantilla||(values.plantilla==''))
                                                        errors.plantilla = 'Required'
                                                    return errors  }} >
                                                    <div className="form-group">
                                                        <div className="row">
                                                        {this.state.templates.length && this.state.templates.map((item, pos) => (
                                                            <div className="card mb-3 col-4" key={'FieldItem'+item.id} >
                                                                    <div className="row no-gutters">
                                                                        <div className="col-md-4">
                                                                            <img width="100px" height="180px" src={item.thumbnailUrl} alt="..."/>
                                                                        </div>
                                                                        <div className="col-md-8">
                                                                        <div className="card-body">
                                                                            <Field 
                                                                                name="plantilla"
                                                                                component="input"
                                                                                type="radio"
                                                                                value={item.name}
                                                                            />
                                                                            <h5 className="card-title">{item.name}</h5>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        ))}
                                                            
                                                            <this.Error name="plantilla" />
                                                        </div>
                                                    </div>
                                                </Wizard.Page>
                                                <Wizard.Page id="3"
                                                    validate={values => {
                                                    const errors = {}
                                                    if (!values.property&&!(values.property>0))
                                                        errors.property = 'Required'
                                                    return errors }} >
                                                    <div className="form-group  text-left">
                                                    <label>Propiedad</label>
                                                    <Field className="form-control" name="property" component="select" value={this.state.propertyId} validate={this.required}>
                                                        <option value='0'> Selecciona una propiedad</option>
                                                        {this.state.properties.length && this.state.properties.map((item, pos) => (
                                                            <option key={pos} value={item.id}> {item.name} </option>
                                                        ))}
                                                    </Field>
                                                    <this.Error name="property" />
                                                    </div>
                                                </Wizard.Page>
                                            </Wizard>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
      );
    }
   }