import { xor } from 'lodash';
import React, { Component } from 'react';
import Chart from "react-apexcharts";
import { propertiesService, } from '../../services/tibesa.api'

export default class dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options_subs: {
                chart: {
                    id: "CatPropiedades"
                },
                xaxis: {
                    categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio']
                }
            },
            series_subs: [
                {
                    name: "Total",
                    data: [30, 40, 45, 50, 49, 60]
                }
            ],


            options_votes: {
                chart: {
                    id: "subscriptions"
                },
                xaxis: {
                    categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
                }
            },
            series_votes: [
                {
                    name: "series-1",
                    data: [30, 40, 45, 50, 49, 60, 70, 91, 50, 30, 22, 34]
                }
            ]
        };
    }

    componentDidMount() {
        propertiesService.getCounterByCategory()
        .then((result) => {
            const catego = result.map( (x) => x.categoryName);
            const alldata= result.map( (x) => x.counter);


            this.setState({ 
                options_subs: {
                    chart: {
                        id: "CatPropiedades"
                    },
                    xaxis: {
                        categories: catego
                    },
                    series_subs: [
                        {
                            name : "Total",
                            data : alldata
                        }
                    ],
                },
            });
        })
    }


    render() {
        return (
            <>
                <div className="air__utils__heading">
                    <h2>DASHBOARD</h2>
                </div>
                <br />
                <div className="row">
                    <div className="col-6 col-xs-12 col-md-6">
                        <div className="card">
                            <div className="card-header card-header-flex">
                                <div className="d-flex flex-column justify-content-center mr-auto">
                                    <h5 className="mb-0">Categoria de Propiedades</h5>
                                    <Chart
                                        options={this.state.options_subs}
                                        series={this.state.series_subs}
                                        type="line"
                                        width="100%"
                                    />
                                </div>
                            </div>
                            <div className="card-body"></div>
                        </div>
                    </div>
                    <div className="col-6 col-xs-12 col-md-6">
                        <div className="card">
                            <div className="card-header card-header-flex">
                                <div className="d-flex flex-column justify-content-center mr-auto">
                                    <h5 className="mb-0">Votaciones</h5>
                                    <Chart
                                        options={this.state.options_votes}
                                        series={this.state.series_votes}
                                        type="bar"
                                        width="100%"
                                    />
                                </div>
                            </div>
                            <div className="card-body"></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
