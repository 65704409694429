import axios from 'axios';
import configs from './../configs/config'

const validateToken = (view) => {

    const localStorage = window.localStorage;
  
    if (!localStorage.getItem('apitoken')) {
        window.location.href = '/';
    } else {
        axios.defaults.headers.common['Authorization'] = localStorage.getItem('apitoken');
        axios.get(`${configs.URL_API_BASE}/auth/${view}/`,  {})
            .then(res => {
                if (res.data.auth == null || res.data.auth === false)  {
                    localStorage.removeItem('apitoken');
                    window.location.href = res.data.path;
                } 
            }).catch((e)=>{
                console.log("error",e);
                localStorage.removeItem('apitoken');
                window.location.href = '/';
            });
    }

}

const handlelogout = ()=>{
    localStorage.removeItem('apitoken');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_type');
    window.location.href = '/';
}


export {validateToken,handlelogout};