import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import "font-awesome/css/font-awesome.min.css";
import configs from "../../../configs/config";
import Swal from "sweetalert2";

export default class TableProperties extends Component {
    constructor(props) {
        super(props);
        const { dataproperties } = props;

        this.columns = [
            {
                key: "name",
                text: "Nombre",
                className: "name",
                align: "left",
                sortable: true
            },
            {
                key: "icon",
                text: "Foto",
                className: "icon",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            <img onClick={() => this.openImageView(record)}
                                style={{ marginRight: "5px" }} src={ configs.URL_IMAGESNEW+ record.logoUrl.replace('original', '64x64') } alt="logo" width="42" height="42" />
                        </Fragment>
                    );
                }
            },
            {
                key: "status_val",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {
                excel: true
            },
            sort: {
                status_val: "desc"
            }
        };

        this.state = {
            records: dataproperties
        };
    }

    componentWillReceiveProps = next_props => {
        this.setState({ records: next_props.dataproperties });
    };

    showImage = record => {
        alert(`link imagen: ${configs.URL_IMAGESNEW}${record.logoUrl}`);
    };

    openImageView=record=>{
        this.props.openLigthBox(`${configs.URL_IMAGESNEW}${record.logoUrl}`);
    }

    editRecord = record => {
        this.props.handleOpenEdit(record);
    };

    deleteRecord = record => {
        console.log("Delete Record", record);
        Swal.fire({
            title: "¿Esta seguro de eliminar la propiedad seleccionada?",
            text: "Una vez eliminada no podrá ser recuperado.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1c666f",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar"
        }).then(result => {
            if (result.value) {
                this.props.delete(record.id_property);
            }
        });
    };

    render() {
        return (
            <div>
                <ReactDatatable
                    config={this.config}
                    records={this.state.records}
                    columns={this.columns}
                />
            </div>
        );
    }
}
