import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

export default class TableTimeLine extends Component {
   
    constructor(props) {
        super(props);

        this.columns = [
            {
                text: "Historia",
                dataField:"custom",
                className: "action",
                formatter: (cellContent, row) => (
                        //row.createdOn.toLocaleDateString()  {format(new Date(row.createdOn),"dd-MMM-yyyy hh:mm",{locale:es})} 
                    <div className="stuck-message">
                        <div ></div>
                        <div >{format(new Date(row.createdOn),"dd-MMM-yyyy hh:mm",{locale:es})}</div>
                        <div >{row.comment}</div>
                    </div>
                )
            }
        ];

        this.state = {
            records: this.props.data
        }
    }

    componentWillReceiveProps = (next_props) => {
        this.setState({records: next_props.data});

    }

    render() {
        return (
            <div>
                <BootstrapTable
                    data={this.state.records}
                    columns={this.columns}
                    bootstrap4 
                    keyField='timeLineId' 
                    pagination={ paginationFactory()}
                />
            </div>
        )
    }
}
