export const RULE_FUNCTIONS = {
    minValue(value, minValue){
        return value >= minValue;
    },

    maxValue(value, maxValue){
        return value <= maxValue;
    },

    required(value){
        return value !== undefined && value !== '' && value != null;
    },

    notRequired(value){
        return value === undefined || value === '' || value === null;
    },

    maxLen(value, maxSize){
        return !value || value.length <= maxSize;
    },

    minLen(value, maxSize){
        return !value || value.length >= maxSize;
    },
  
    fileSize(file, size){
        console.log(file.size, size,file.size <= size );
        return  file.size <= size;
    },

    emailList(value){
        return value.split(',').every(s => this.email(s.trim()));
    },
    
    email(value){
        return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    },

    telephone(value){
        return /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/.test(value);
    },

    RegEx(value, expression){
        return expression.test(value);
    }
}


export class Validator {

    constructor(rules = []){
        this.rules = rules;
    }

    validateState(state){
        return Object.keys(state)
        .map(k => ({ruleDesc: this.getRule(k), key: k}))
        .filter(k => k.ruleDesc && !k.ruleDesc.rule(state[k.key]))
        .map(k => k.key);
    }
    
    validateField(name, value){
        let ruleDesc =  this.getRule(name);
        return !ruleDesc || ruleDesc.rule(value);
    }

    getRule(field){
        return this.rules.filter(r => r.fields.indexOf(field) !== -1)[0];
    }
}

export default Validator;