import axios from 'axios';
import configs from './../configs/config'

class Auth {
    constructor(){
        this.authenticated = false;
        this.token = null;
    }


    login(){
        //TODO:
        this.authenticated = true;
    }

    logout(){
        //TODO:
        localStorage.removeItem('apitoken');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_type');
        this.authenticated = false;
        window.location.href = '/login';
    }

    isAuthenticated(){
        return this.authenticated;
    }

    isViewAllowed(view){
        const localStorage = window.localStorage;
        
        return new Promise((resolve)=>{
            if (!localStorage.getItem('apitoken')) resolve(false);
            axios.defaults.headers.common['Authorization'] = localStorage.getItem('apitoken');
            
            axios.get(`${configs.URL_API_BASE}/auth/${view}/`,  {})
                .then(res => {
                    if (res.data.auth == null || res.data.auth === false)
                        resolve(false);
                    else
                        resolve(true);
                }).catch((e)=>{
                        resolve(false);
                });
        });
    }

    validateView(view){
        this.isViewAllowed(view).then(result => {
            if(!result) this.logout();
        })
    }

}

export default new Auth();