import React, { Component } from 'react';
import Swal from 'sweetalert2'
import { RULE_FUNCTIONS, Validator } from '../../../utilities/form-validation';
import classNames from 'classnames';
import Contact from '../../../models/contact';
import Catalogs from '../../../models/catalogs';
import { forEach } from 'lodash';

const MONTHS = [0,1,2,3,4,5,6,7,8,9,10,11,12];
const DAYS   = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];



export default class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...(props.contact || new Contact()),
            errors: []
        };
        this.state.formTitle = this.state.name || 'Crear un nuevo Contacto';
        this.state.editMode = this.state.id != null;
        this.submitCallback = this.props.onSubmit;

        let validationRules =
            [
                {
                    fields: ['name'],
                    rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 200)
                },
                {
                    fields: ['phone'],
                    rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.telephone(v) && RULE_FUNCTIONS.maxLen(v, 20) && RULE_FUNCTIONS.minLen(v, 8)
                },
                {
                    fields: ['cellPhone'],
                    rule: v => RULE_FUNCTIONS.notRequired(v) || (RULE_FUNCTIONS.telephone(v) && RULE_FUNCTIONS.maxLen(v, 20))
                },
                {
                    fields: ['email'],
                    rule: v => RULE_FUNCTIONS.notRequired(v) || (RULE_FUNCTIONS.email(v) && RULE_FUNCTIONS.maxLen(v, 200))
                }
            ]
        this.validator = new Validator(validationRules);
    }

    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) == -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    handleChangeInput = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
        this.validateInput(name, value);
    }

    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) == -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    hasError = (name) => {
        return this.state.errors.indexOf(name) !== -1;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let errors = this.validator.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            Swal.fire('Error!', `cuentas con ${errors.length} error(es) en el formulario, por favor revisa los campos en rojo`, 'error');
            return false;
        }

        const formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('phone', this.state.phone);
        formData.append('cellPhone', this.state.cellPhone);
        formData.append('email', this.state.email);

        formData.append('country', this.state.country );
        formData.append('city', this.state.city );
        formData.append('company', this.state.company );

        if (this.state.birthDay!="00" && this.state.birthMonth !="00")
        formData.append('birthday', this.state.birthMonth + '/' + this.state.birthDay);

        formData.append('companyRol', this.state.companyRol );
        formData.append('address', this.state.address);
        formData.append('interests', this.state.interests );
        formData.append('source', this.state.source );

        formData.append('isFavorite', this.state.isFavorite);

        if (typeof this.submitCallback == "function") {
            this.submitCallback(formData);
        }
    }

    formatCero = (n) =>{
        return n > 9 ? "" + n: "0" + n;
    }


    render() {
        return (
            <>

                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <div className="">

                        <div className="modal-content">

                            <div className="panel panel-default">
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-row ">

                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Nombre : <span
                                                    className="text-red">*</span></label>
                                                <input name="name" value={this.state.name ? this.state.name : ''} type="text"
                                                    onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('name') })} />
                                                <div className="invalid-feedback">Nombre requerido - máximo 200 caracteres.</div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Teléfono : <span
                                                    className="text-red">*</span></label>
                                                <input name="phone" type="text" value={this.state.phone} onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('phone') })} />
                                                <div className="invalid-feedback">Ingresa un teléfono valido.</div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Celular : </label>
                                                <input name="cellPhone" type="text" value={this.state.cellPhone} onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('cellPhone') })} />
                                                <div className="invalid-feedback">Ingresa un celular valido.</div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>e-mail : </label>
                                                <input name="email" type="text" value={this.state.email} onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('email') })} />
                                                <div className="invalid-feedback">Ingresa un e-mail valido.</div>
                                            </div>
                                            <div className="col-12 ">
                                                <div className="form-check">
                                                    <input name="isFavorite" type="checkBox" className="form-check-input"
                                                        value={this.state.isFavorite ? 1 : 0} onChange={this.handleChangeInput} />
                                                    <label className="form-check-label">Marcar como Favorito</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Pais : </label>
                                                <select name="country" value={this.state.country} onChange={this.handleChangeInput} className="form-control">
                                                    {Catalogs.Countries.map((item, pos) => (
                                                        <option key={pos} value={item.code}> {item.name} </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Ciudad : <span
                                                    className="text-red">*</span></label>
                                                <input name="city" type="text" value={this.state.city ? this.state.city : ''} 
                                                    className={classNames('form-control')} onChange={this.handleChangeInput} />
                                            </div><div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Compañia : </label>
                                                <input name="company" value={this.state.company ? this.state.company : ''} type="text"
                                                   className={classNames('form-control')}  onChange={this.handleChangeInput} />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Puesto : </label>
                                                <input name="companyRol" type="text" value={this.state.companyRol ? this.state.companyRol : ''} 
                                                className={classNames('form-control')}  onChange={this.handleChangeInput} />
                                            </div><div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Direccion : </label>
                                                <input name="address" value={this.state.address ? this.state.address : ''} type="text"
                                                  className={classNames('form-control')}   onChange={this.handleChangeInput} />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Interes : </label>
                                                <input name="interests" type="text" value={this.state.interests ? this.state.interests : ''}  
                                                className={classNames('form-control')} onChange={this.handleChangeInput}/>
                                            </div>
                                            <div className="col-6 col-md-3 col-lg-3 my-3">
                                                <label>Mes : </label>
                                                <select name="birthMonth" value={this.state.birthMonth ? this.state.birthMonth : ''} type="text"
                                                   className={classNames('form-control')}  onChange={this.handleChangeInput} >
                                                {
                                                    MONTHS.map(pos => (
                                                        <option key={pos} value={this.formatCero(pos)}>
                                                            {(pos==0)?"Seleccione el Mes":pos}
                                                        </option>
                                                    ))
                                                }       
                                                </select>
                                            </div>
                                            <div className="col-6 col-md-3 col-lg-3 my-3">
                                                <label>Dia : </label>
                                                <select name="birthDay" value={this.state.birthDay ? this.state.birthDay : ''} type="text"
                                                   className={classNames('form-control')}  onChange={this.handleChangeInput} >
                                                {
                                                    DAYS.map(pos => (
                                                        <option key={pos} value={this.formatCero(pos)}>
                                                            {(pos==0)?"Seleccione el Dia":pos}
                                                        </option>
                                                    ))
                                                }
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Fuente : </label>
                                                <input name="source" type="text" value={this.state.source ? this.state.source : 'BackOffice'}
                                                className={classNames('form-control')}  onChange={this.handleChangeInput}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             
                           
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-success btn-lg" type="submit">Guardar</button>
                                <button className="btn float-right" type="button" onClick={() => window.location.href = '/contacts'}>CANCELAR</button>

                            </div>
                        </div>
                    </div>
                </form>
            </>
        )

    }

}