import configs from './../configs/config'

export default class Property {

    constructor(prop = {}, amenities = [], images=[]){
        this.id = prop.id_property || null;
        this.typeId = prop.type_id || 1;
        this.categoryId = prop.categoryId || '';
        this.subcategoryId = prop.idSubCategory  || '';
        this.modifiedOn = prop[' created_at']  || null;
        this.createdOn = prop.createdOn  || null;
        this.isActive = prop.status === 1;
        this.isHighlighted = prop.prioridad === 1;
        
        this.totalSize = prop.totalSize || 0;
        this.frontSize = prop.front || 0;
        this.backgroundSize = prop.background || 0;
        this.fieldForm = prop.fieldForm || 1;
        this.price = prop.price || 0;
        this.currencyId = prop.currencyCode || 1;
        this.investmentPossibility = prop.isInvertion === 1;

        this.size= prop.habSize || 0;
        this.bedrooms= prop.beedrooms || 0;
        this.bathrooms= prop.bathrooms || 0;
        this.levels= prop.levels || 0;

        this.tradeType= prop.tradeType || 2;

        this.latitude= prop.latitude || 0; 
        this.longitude = prop.longitude || 0;
        this.zip = prop.CP || 0;
        this.cityId = prop.cityCode || 0;
        this.stateId = prop.stateId || 0;
        this.address = prop.address || '';

        this.en_name= prop.nombreEN || '';
        this.en_mainDescription= prop.descriptionEN || '';
        this.en_logoAlt= prop.alte || '';
        this.en_keywords= prop.keyworksEN || '';
        this.en_metaDescription= prop.metaEn || '';
        this.en_pdfUrl= prop.pdfUrli || '';
        this.en_investmentTerms= prop.invertionEN || '';
        this.en_rentTerms= prop.rentEN || '';
        this.en_petsTerms= prop.petsEn || '';
        this.en_slug= prop.slugEN|| '';
        this.es_name= prop.name || '';
        this.es_mainDescription= prop.descripcion || '';
        //this.es_address= prop.Direccion || '';
        this.es_logoAlt= prop.alt || '';
        this.es_keywords= prop.keywords || '';
        this.es_metaDescription= prop.meta || '';
        this.es_pdfUrl= prop.pdfUrl || '';
        this.es_investmentTerms= prop.invertion_description || '';
        this.es_rentTerms= prop.rent_terms || '';
        this.es_petsTerms= prop.pets_terms || '';
        this.es_slug= prop.slug || '';

        this.amenities = amenities.map(a => ({ id:a.idAmenity, value:a.name }));
        this.logoUrl = prop.logoUrl || null;
        this.logoPreview = this.logoUrl ? `${configs.URL_IMAGESNEW}${ this.logoUrl.replace('original', '200x140')}`: null;
        this.videoUrl = prop.videoUrl|| '';

        /*
        img_type
        1 = Image
        2 = youtube
        3 = vimeo
        4 = cupix
        5 = my360
        */

        this.images = images.filter(i => i.img_type === 1);
        this.galleryThumbs = this.images.map(i => `${configs.URL_IMAGESNEW}${i.img_url.replace('original', '200x140')}`);
        this.gallery = this.images.map(i => `${configs.URL_IMAGESNEW}${i.img_url}`);

        this.virtualTours = images.filter(i => i.img_type > 3);

        //solo un tour por el momento
        this.virtualTourType = this.virtualTours[0]?.img_type || 4;
        this.virtualTourKey = this.virtualTours[0]?.img_url || null;
        //-----
    }

}