class emailList {
    constructor(entity = {}){
        this.id = entity.emailListId || null;
        this.name = entity.name || '';
        this.createdOn = entity.createdOn || '';
        this.deleted = entity.deleted || '';
        this.totalEmails = entity.totalEmails || 0;
    }
};

export default emailList;

