
class PageTranlated  {
    constructor(entity = {}){
        this.id = ((entity.language == null || entity.language=='es') )? entity.pageId : entity.translationId || null;
        this.pageId =  (entity.language == null || entity.language=='es') ? null : entity.pageId;
        this.pageName = entity.pageName || '';
        this.title = entity.title || '';
        this.description = entity.description || '';
        this.pageUrl = entity.pageUrl || '';
        this.keywords = entity.keywords || '';
        this.metas= entity.metas || '';
        this.language= entity.language || null;
        this.bodyJson = entity.bodyJson || '';
        this.bodyHtml = entity.bodyHtml || null ;
        this.bodyScript = entity.bodyScript || null ;

        this.isActive = entity.isActive || false;
        this.isLandingPage = entity.isLandingPage || '';
        this.createdOn = entity.createdOn || null;
    }
};

export default PageTranlated;