import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import "font-awesome/css/font-awesome.min.css";
import configs from "../../../configs/config";

export default class TableProperties extends Component {
    constructor(props) {
        super(props);
        const { dataproperties, dataselected } = props;


        this.columns = [
            {
                key: "checkbox",
                text: "",
                className: "name",
                align: "left",
                cell: (record,index) => {
                    return (
                        // onClick={() => this.onRowClicked(record, index)} name={`item-` + index} 
                        (this.state.row_clicks.find(x => x.id == record.id)) ?
                        <Fragment>
                            <span className="fa fa-circle"></span>
                        </Fragment> :
                        <Fragment>
                                <input type="button" value="agregar" onClick={() => this.onRowClicked(record, index)}  onChange={() => this.handleChange(record,index)} /> 
                        </Fragment>
                       
                    );
                }
            },
            {
                key: "icon",
                text: "Foto",
                className: "icon",
                align: "left",
                
                cell: record => {
                    return (
                        <Fragment>
                            <img onClick={() => this.openImageView(record)} src={configs.URL_IMAGESNEW+ record.logoUrl.replace('original', '64x64') } alt="logo" width="42" height="42" />
                        </Fragment>
                    );
                }
            },
            {
                key: "name",
                text: "Nombre",
                className: "name",
                align: "left",
            },
            {
                key: "state",
                text: "Estado",
                className: "name",
                align: "left",
            }

        ];

        this.config = {
            page_size: 50,
            length_menu: [50],
            show_pagination: false,
            show_length_menu:false,
            show_filter: false,
            show_info: false,
            bLengthChange: false,
            grouping: true
        };

        this.state = {
            records: dataproperties,
            row_clicks: []
        };
    }

    openImageView = record =>{
        this.props.openLigthBox(`${configs.URL_IMAGESNEW}${record.logoUrl}`);
    }

    onRowClicked(record,index){
        record.selected = true;
        this.props.onRowClicked(record,index)
    }

    handleChange(record,index){
       this.props.propertyRowChanged(record,index)
    }

    componentWillReceiveProps = next_props => {
        this.setState({ 
            records: next_props.dataproperties,
            row_clicks : next_props.dataselected
        });
    };

   

    render() {
        return (
            <div>
                <ReactDatatable
                    config={this.config}
                    records={this.state.records}
                    columns={this.columns}
                />
            </div>
        );
    }
}
