import React, { Component } from 'react'

import axios from 'axios';
import Swal from 'sweetalert2';
import configs from './../../configs/config';

import Switch from "react-switch";
import Modal from 'react-modal';
import TableCurrency from './components/table';
import LoadingOverlay from 'react-loading-overlay';


export default class Currency extends Component {

    constructor() {
        super();
        this.state = {
            codes: [
                'MXN',
                'USD',
                'EURO',
                'JPY'
            ],
            config: {
                headers: { 'content-type': 'application/json' }
            },
            isuploading: false,
            checked: false,
            modalIsOpen: false,
            show: false,
            currencies: [],
            name: '',
            mxnValue: '',
            cCode: '',
            id: '',
            isEdit: false,
        };

        this.getData();
    }

    componentWillReceiveProps = (next_props) => {
        console.log('next_props', next_props);;
    }

    activeCheck = (checked) => {
        this.setState({ checked });
    }


    delete = (id) => {
        axios.post(`${configs.URL_API_BASE}/currencies/${id}/delete`)
            .then(res => {
                console.log(res);
                if (parseInt(res.data.status) == 200) {
                    this.getData();
                    Swal.fire('Eliminado!', 'Elemento eliminado correctamente.', 'success');
                } else {
                    Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
                }
            }).catch(err => {
                console.log(err);
                Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
            });
    }

    onSubmit = (e) => {
        e.preventDefault();

        let newItem = {
            name: this.state.name,
            cCode: this.state.cCode,
            mxnValue: this.state.mxnValue,
            status: (this.state.checked) ? '1' : '0'
        }

        let url = ``;
        this.setState({ isuploading: true });
        if (this.state.isEdit) {
            url = `${configs.URL_API_BASE}/currencies/${this.state.id}/edit`;
            axios.put(url, newItem, this.state.config).then((response) => {
                this.setState({ isuploading: false });
                if (parseInt(response.data.status) == 200) {
                    Swal.fire('Edición Correcta', 'Moneda editada correctamente.', 'success');
                    this.closeModal();
                    this.getData();
                } else {
                    console.log("fail");
                }
            });
        } else {
            url = `${configs.URL_API_BASE}/currencies/create`;
            axios.post(url, newItem, this.state.config).then((response) => {
                this.setState({ isuploading: false });
                if (parseInt(response.data.status) == 200) {
                    Swal.fire('Ingreso Correcto', 'Moneda ingresada correctamente.', 'success');
                    this.closeModal();
                    this.getData();

                } else {
                    console.log("fail");
                }
            });
        }

        e.target.reset();
    }

    getData = () => {
        axios.get(`${configs.URL_API_BASE}/currencies/getCurrencies`, {})
            .then(res => {
                if (res.data.status == 200) {
                    let auxData = res.data.results;
                    auxData.forEach((item) => {
                        item.id = item.id_Currency;
                    })
                    this.setState({ currencies: auxData });
                }
            });
    }


    handleOpenEdit = record => {
        console.log(record);
        this.setState({
            modalIsOpen: true,
            isEdit: true,
            name: record.name,
            mxnValue: record.valueInPesos,
            cCode: record.currencyCode,
            id: record.id_Currency,
        });
        record.status == 1 ? this.setState({ checked: true }) : this.setState({ checked: false });
    }

    onInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    activateAmenity = (checked) => {
        this.setState({ checked });
    }

    openModal = () => {
        this.setState({
            modalIsOpen: true,
            isEdit: false,
            name: '',
            mxnValue: '',
            cCode: '',
        });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Monedas</h2>
                    <a className="btn btn-primary d-flex justify-content-between align-self-center" href="#" onClick={this.openModal}>
                        <span>Nueva Moneda &nbsp;</span>
                        <span><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        <TableCurrency handleOpenEdit={this.handleOpenEdit} delete={this.delete} currencies={this.state.currencies}></TableCurrency>
                    </div>
                </div>
                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    className="Modal"
                    contentLabel="Crear Moneda">
                    <form onSubmit={this.onSubmit}>
                        <div className="modal-dialog">
                            <LoadingOverlay
                                active={this.state.isuploading}
                                spinner
                                text='Subiendo el contenido...'
                            >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{this.state.isEdit ? "Editar Moneda" : "Crear Moneda"}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row content-modal-mobile my-0 my-lg-4">
                                            <div className="col-12">
                                                <div className="form-row my-3">
                                                    <div className="col-12 d-flex align-items-center">
                                                        <label className="form-check-label mr-3" htmlFor="priorizar">Activar esta Moneda?</label>
                                                        <Switch onChange={this.activateAmenity} checked={this.state.checked} />
                                                    </div>
                                                    <div className="col-12 my-3">
                                                        <label>Nombre<span
                                                            className="text-red">*</span></label>
                                                        <input name="name" autoFocus={true} type="text" value={this.state.name} className="form-control" onChange={this.onInputChange} required />
                                                    </div>
                                                    <div className="col-12 my-3">
                                                        <label>Valor en Pesos Mexicanos <span
                                                            className="text-red">*</span></label>
                                                        <input name="mxnValue" type="number" value={this.state.mxnValue} className="form-control" onChange={this.onInputChange} required />
                                                    </div>
                                                    <div className="col-6 my-3">
                                                        <label>Código de la Moneda <span
                                                            className="text-red">*</span></label>
                                                        <select className="form-control" value={this.state.cCode} name="cCode" onChange={this.onInputChange}>
                                                            {
                                                                this.state.codes.map((category, pos) => (
                                                                    <option key={pos} value={category}>
                                                                        {category}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-danger btn-lg" type="button" onClick={this.closeModal}>CANCELAR</button>
                                        <button className="btn btn-success btn-lg" type="submit">GUARDAR</button>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </form>
                </Modal>

            </>
        )
    }
}
