import React, { Component } from "react"
import TemplateForm from './components/template-form'
import { emailTemplatesService } from '../../services/tibesa.api'
import LoadingOverlay from "react-loading-overlay";
import Swal from 'sweetalert2'
import EmailTemplate from "../../models/email-template";
import loSanitize from 'lodash';

export default class EmailTemplateDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            template: null
        }

        this.submitChanges = this.submitChanges.bind(this);

    }

    componentDidMount() {
        emailTemplatesService.get(this.props.match.params.id)
        .then((template) => {
            template.body= loSanitize.unescape(template.body);
            this.setState({ template: new EmailTemplate(template) });
        })
    }


    submitChanges(formData) {
        Swal.fire({
            title: '¿Seguro que deseas editar la plantilla?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
          //  formData.body= _.escape(formData.body);
            preConfirm: () => {
                return emailTemplatesService.update(this.props.match.params.id, formData)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
                console.log(response);
                if (response.value && response.value.data && response.value.data.status == 200) {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: '¡Plantilla actualizada con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }
                    ).then(() => {
                        window.location.reload();
                    });
                } else if(!response.dismiss == 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }


    render() {

        let form;
        if (this.state.template != null) {
            form = <TemplateForm template={this.state.template} onSubmit={this.submitChanges}></TemplateForm>
        }
        else
            form = <LoadingOverlay></LoadingOverlay>

        return (
            <>
                <div className="air__utils__heading">
                    <a href="/email-templates">Listado de plantillas de email</a><span>{" "}/{" "}Editar plantilla #{this.props.match.params.id}</span>
                </div>
                {form}
            </>
        )
    }
}
