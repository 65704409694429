import React, { Component, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import Swal from 'sweetalert2';
import { noAuto } from '@fortawesome/fontawesome-svg-core';

//http://react-datatable.in/server-side-data.html


export default class TableContactsBook extends Component {
    constructor(props) {
        super(props);
        const {data}=props;
        this.columns = [
            {
                dataField: "custom",
                text: "Book",
                className:"center",
                formatter: (cellContent,record) => (
                    <button className="btn btn-secondary" title="Contact Book" 
                            onClick={() => this.props.handleFilter(record.alpha)} style={{ marginRight: '5px' }}>
                                                         <i className="fa fa-book"></i> 
                                {record.alpha}
                             : {record.count}
                    </button>
                ),
            }
        ];

        this.state = {
            records: data
        }
    }
    componentWillReceiveProps = (next_props) => {
        this.setState({records: next_props.data});
    }
    render() {
        return (
            <Fragment>
                <BootstrapTable                    
                    data={this.state.records}
                    columns={this.columns}
                    bootstrap4 
                    keyField='alpha' 
                />
                
            </Fragment>
        )
    }
}
