import React, { Component } from 'react'

import 'react-tabs/style/react-tabs.css';
import Modal from 'react-modal';

import axios from 'axios';
import Swal from 'sweetalert2';
import configs from './../../configs/config';

import TableVideos from './components/table';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LoadingOverlay from 'react-loading-overlay';

export default class News extends Component {

    constructor() {
        super();
        this.state = {
            isuploading: false,
            modalIsOpen: false,
            isEdit: false,
            checked: false,
            data: [],
            video: {
                title: '',
                videoUrl: '',
                description: '',
                id: '',
                status: 1,
                priority: 1,
            }
        };

        this.getData();
    }

    getData = () => {
        axios.get(`${configs.URL_API_BASE}/videos/getVideos`, {})
            .then(res => {
                console.log(res);
                if (res.data.status == 200) {
                    let auxData = res.data.results;
                    auxData.forEach((item) => {
                        item.id = item.idVideo;
                        item.status_val = (item.status == '1') ? 'Activo' : 'Inactivo';
                    })
                    this.setState({ data: auxData });
                }
            });
    }


    delete = (id) => {
        axios.get(`${configs.URL_API_BASE}/videos/${id}/delete`)
            .then(res => {
                console.log(res);
                if (parseInt(res.data.status) == 200) {
                    this.getData();
                    Swal.fire('Eliminado!', 'Elemento eliminado correctamente.', 'success');
                } else {
                    Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
                }
            }).catch(err => {
                console.log(err);
                Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
            });
    }

    activateCheck = (checked) => {
        this.setState({ checked });
    }

    parseUrlVideoEmbed = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length == 11)
            ? match[2]
            : null;
    }

    onSubmit = (e) => {
        e.preventDefault();

        var normalUrl = this.state.video.urlVideo;

        let newItem = {
            ...this.state.video
        }

        newItem.urlVideo = `https://www.youtube.com/embed/${this.parseUrlVideoEmbed(normalUrl)}`;

        console.log(newItem);

        let url = ``;

        this.setState({ isuploading: true });
        if (this.state.isEdit) {
            url = `${configs.URL_API_BASE}/videos/${this.state.video.id}/edit`;
            axios.put(url, newItem, { headers: { 'content-type': 'application/json' } }).then((response) => {
                this.setState({ isuploading: false });
                if (parseInt(response.data.status) === 200) {
                    Swal.fire('Edición Correcta', 'Video editado correctamente.', 'success');
                    this.closeModal();
                    this.getData();

                } else {
                    console.log("fail");
                }
            });
        } else {
            url = `${configs.URL_API_BASE}/videos/createVideo`;
            axios.post(url, newItem, { headers: { 'content-type': 'application/json' } }).then((response) => {
                this.setState({ isuploading: false });
                if (parseInt(response.data.status) === 200) {
                    Swal.fire('Ingreso Correcto', 'Video ingresada correctamente.', 'success');
                    this.closeModal();
                    this.getData();

                } else {
                    console.log("fail");
                }
            });
        }


        e.target.reset();
    }

    onInputChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;

        this.setState(prevState => {
            return {
                video: {
                    ...prevState.video, [name]: value
                }
            }
        })
    }
    
    /*
    openModal = () => {
        this.setState({ modalIsOpen: true, isEdit: false });
        this.updatestevideosstates('titulo', '');
        this.updatestevideosstates('urlVideo', '');
        this.updatestevideosstates('descripcion', '');
        this.updatestevideosstates('id', '');

    } */

    handleOpenEdit = record => {
        this.props.history.push(`/videos/${record.id}`);
      /*  console.log(record);
        this.setState({ modalIsOpen: true, isEdit: true });
        this.updatestevideosstates('titulo', record.titulo);
        this.updatestevideosstates('urlVideo', record.urlVideo);
        this.updatestevideosstates('descripcion', record.descripcion);
        this.updatestevideosstates('id', record.id);
*/
    }
/*
    updatestevideosstates = (name, value) => {
        this.setState(prevState => {
            return {
                new: {
                    ...prevState.new, [name]: value,
                }
            }
        });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }
*/
    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Videos</h2>
                    <a href="/videos/add" className="btn btn-primary d-flex justify-content-between align-self-center">
                        <span>Nuevo Video&nbsp;</span>
                        <span><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        <TableVideos handleOpenEdit={this.handleOpenEdit} delete={this.delete} data={this.state.data}></TableVideos>
                    </div>
                </div>
            </>
        )
    }
}
