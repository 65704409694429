import React, { Component } from 'react'
import { Editor } from '@tinymce/tinymce-react';

import Swal from 'sweetalert2';
import EmailTemplate from '../../../models/email-template';
import { RULE_FUNCTIONS, Validator } from '../../../utilities/form-validation';
import classNames from 'classnames';
import loSanitize from 'lodash';

import configs from './../../../configs/config';


/*
import PlaceHolder from 'ckeditor5-placeholder'
https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/installing-plugins.html
*/

const emailTypes = ['Seleccione Tipo','Seguimiento','Brochure','Diseño','Listado'];


export default class EmailTemplateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...(props.template || new EmailTemplate()),
            editMode: false,
            errors: []
        };

        this.state.formTitle = this.state.name || 'Crear nueva plantilla de correo';
        this.state.editMode = this.state.id != null;
        this.submitCallback = this.props.onSubmit;
        let validationRules =
            [
                {
                    fields: ['type'],
                    rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.minValue(v, 1)
                },
                {
                    fields: ['name'],
                    rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 100)
                },
                {
                    fields: ['subject'],
                    rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 300)
                },
                {
                    fields: ['body'],
                    rule: v => RULE_FUNCTIONS.required(v)
                },
                {
                    fields: ['cc'],
                    rule: v => RULE_FUNCTIONS.notRequired(v) || (RULE_FUNCTIONS.emailList(v) && RULE_FUNCTIONS.maxLen(v,500))
                }
            ]
        this.validator = new Validator(validationRules);
      

    }

    handleChangeInput = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
        this.validateInput(name, value);
    }

    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) == -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    hasError = (name) => {
        return this.state.errors.indexOf(name) !== -1;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let errors =  this.validator.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            Swal.fire('Error!', `cuentas con ${errors.length} error(es) en el formulario, por favor revisa los campos en rojo`, 'error');
            return false;
        }

        const formData = new FormData();
        formData.append('type', this.state.type);
        formData.append('name', this.state.name);
        formData.append('subject', this.state.subject);
        formData.append('body', loSanitize.escape(this.state.body));
        formData.append('cc', this.state.cc);
        formData.append('orientation', this.state.orientation);
        formData.append('language', this.state.language);

        if (typeof this.submitCallback == "function") {
            this.submitCallback(formData);
        }

    }

    render() {
        return (
            <>
                <form  onSubmit={this.handleSubmit} autoComplete="off">
                    <div className="row">
                        <div className="col-12">
                            <hr />
                            <h3>{this.state.formTitle}</h3>
                            <hr />
                        </div>
                    </div>
                    <div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-12">
                            <div className="form-row ">
                                <div className="col-12 col-md-6 col-lg-6 my-3">
                                    <label>Tipo<span className="text-red">*</span></label>
                                    <select name="type" value={this.state.type} onChange={this.handleChangeInput}
                                        className={classNames('form-control', { 'is-invalid': this.hasError('type') })} >
                                           {emailTypes.map((x,y) =>
                                                    <option key={y} value={y}>{x}</option> )}
                                    </select>
                                    <div className="invalid-feedback">Tipo de plantilla requerido.</div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 my-3">
                                    <label>Nombre<span className="text-red">*</span></label>
                                    <input name="name" type="text" autoFocus={true}
                                        value={this.state.name} onChange={this.handleChangeInput}
                                        className={classNames('form-control', { 'is-invalid': this.hasError('name') })} />
                                    <div className="invalid-feedback">Nombre de la plantilla requerido - máximo 100 caracteres.</div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 my-3">
                                    <label>Asunto de correo <span className="text-red">*</span></label>
                                    <input name="subject" type="text"
                                        value={this.state.subject} onChange={this.handleChangeInput}
                                        className={classNames('form-control', { 'is-invalid': this.hasError('subject') })} />
                                    <div className="invalid-feedback">Asunto requerido - máximo 300 caracteres.</div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 my-3">
                                    <label>CC</label>
                                    <input name="cc" type="text"
                                        value={this.state.cc} onChange={this.handleChangeInput}
                                        className={classNames('form-control', { 'is-invalid': this.hasError('cc') })} />
                                    <div className="invalid-feedback">Ingresa correos electronicos validos separados por coma (,)</div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 my-3">
                                    <label>Orientación</label>
                                    <select className='form-control' name="orientation" value={this.state.orientation} onChange={this.handleChangeInput} >
                                        <option value="1">Portrait</option>
                                        <option value="2">Landscape</option>
                                    </select>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 my-3">
                                    <label>Idioma</label>
                                    <select  className='form-control' name="language" value={this.state.language} onChange={this.handleChangeInput} >
                                        <option value="es-MX">Español</option>
                                    </select>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12 my-3">
                                    <div className={classNames('editor-fixed-size', { 'is-invalid': this.hasError('body') })}>
                                        <Editor
                                                apiKey={configs.TINYMCE_API_KEY} 
                                                initialValue={this.state.body}
                                                init={{
                                                height: 500,
                                                images_upload_url: `${configs.URL_API_BASE}/uploadImages/image`,
                                                automatic_uploads: true,
                                                block_unsupported_drop: true,
                                                menubar: false,
                                                content_css : "https://getbootstrap.com/docs/4.5/dist/css/bootstrap.min.css",
                                                menu: {
                                                    edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall' },
                                                    insert: { title: 'Insert', items: 'link image' },
                                                    view: { title: 'View', items: 'visualaid' },
                                                    format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat' }
                                                },
                                                plugins: "image,paste,code",
                                                paste_data_images: true,
                                                toolbar:
                                                    'undo redo | formatselect | table link bold italic backcolor | \
                                                    alignleft aligncenter alignright alignjustify | \
                                                    bullist numlist outdent indent | removeformat | image code',
                                                }}
                                                onEditorChange={(content) => {
                                                    const data = content; 
                                                    this.setState({ body: data });
                                                    this.validateInput('body', data);
                                                    
                                                }}
                                            />
                                    </div>
                                    <div className="invalid-feedback">Cuerpo de plantilla requerido.</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row pt-5">
                        <div className="col-12">
                            <hr />
                            <button type="button" className="btn float-right" onClick={() => window.location.href = '/email-templates'}>CANCELAR</button>
                            <button type="submit" className="btn btn-secondary float-right">GUARDAR</button>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}
