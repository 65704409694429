import React, { Component, Fragment } from 'react';
import { handlelogout } from './../../session/SessionController';

export default class Header extends Component {
    render() {
        return (
            <Fragment>
                {/* /////// HEADER */}
                <div className="ant-layout-header air__layout__header">
                    <div className="air__utils__header">
                        {/* /////// TOP BAR */}
                        <div className="air__topbar justify-content-end">
                            <div className="dropdown">
                                <a className="dropdown-item" href="#o" onClick={handlelogout}>
                                    <i className="fas fa-sign-out-alt"></i> Cerrar Sesión
                                </a>
                            </div>
                        </div>
                        {/* /////// END TOP BAR */}
                    </div>
                </div>
                {/* /////// END HEADER */}
            </Fragment>
        )
    }
}
