import React from 'react';
import { Route } from 'react-router-dom'
import AppLayout from '../views/_shared/app.layout'

const AppRoute = ({component: Component, layout: Layout = AppLayout, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            if (Layout != null)
                return <Layout> <Component {...props} /> </Layout>;
            else
                return <Component {...props} />;
        } } />
);

export default AppRoute;