import config from '../configs/config';
import axios from "axios"

const BASE_URL = config.URL_API_BASE;

const UnWrapResults = (promise, defaultValue = []) => {
    return new Promise((resolve, reject) => {
        promise.then(result => {
            resolve(result.data.results || defaultValue);
        })
        .catch(reason => reject(reason));
    });
};


class PropertiesService {

    constructor(){
        this.multipart_config = {
            headers: {
                "content-type": "multipart/form-data"
            }
        };
    }

    getAll(){
        return UnWrapResults(axios.get(`${BASE_URL}/properties/getProperties`));
    }

    getPropertiesByQuery(query){
        return UnWrapResults(axios.get(`${BASE_URL}/properties/getPropertiesByQuery?${query}`));
    }

    getPropertiesBasic(){
        return UnWrapResults(axios.get(`${BASE_URL}/properties/getPropertiesBasic`));
    }

    getCounterByCategory(){
        return UnWrapResults(axios.get(`${BASE_URL}/properties/getCounterByCategory`));
    }

    getPropertiesByCategory(){
        return UnWrapResults(axios.get(`${BASE_URL}/properties/getCounterByCategory`));
    }
    
    get(id){
        return UnWrapResults(axios.get(`${BASE_URL}/properties/${id}`), {});
    }

    getMapIds(id){
        return UnWrapResults(axios.get(`${BASE_URL}/properties/${id}/getPropertyMapper`), {});
    }

    getMediaOfProperty(id){
        return UnWrapResults(axios.get( `${BASE_URL}/properties/imagesP/${id}`));
    }

    generatePropertyBrochure(id,data){
        return axios.post(`${BASE_URL}/properties/${id}/generatePropertyBrochure`, data);
    }

    create(property){
        return axios.post(`${BASE_URL}/properties/create`, property, this.multipart_config);
    }

    update(id, property){
        return axios.put(`${BASE_URL}/properties/${id}/edit`, property, this.multipart_config);
    }

    delete(id){
        return axios.post(`${BASE_URL}/properties/${id}/delete`)
    }

    getImagesLibrary(){
        return UnWrapResults(axios.get(`${BASE_URL}/properties/getImagesLibrary`));
    }

}

class PropertyTypesService {

    getAll(){
        return UnWrapResults(axios.get(`${BASE_URL}/propertyTypes/getActivesProperty_Type`));
    }

}

class CategoriesService {
    
    getAll(){
        return UnWrapResults(axios.get(`${BASE_URL}/categories/getCategories`));
    }

    getSubcategoriesOf(id){
        return  UnWrapResults(axios.get(`${BASE_URL}/subcategories/${id}/getSubcategories`));
    }

}

class FeedMapperService {
    getMapperCodes(id){
        return UnWrapResults(axios.get(`${BASE_URL}/feeds/${id}/getMapperCodes`));
    }
}

class AmenitiesService {
    
    getAll(){
        return UnWrapResults(axios.get(`${BASE_URL}/amenities/getAmenities`));
    }

}

class CurrenciesService {
    
    getAll(){
        return UnWrapResults(axios.get(`${BASE_URL}/currencies/getCurrencies`));
    }

}

class PlacesService {

    getStates(){
        return UnWrapResults(axios.get(`${BASE_URL}/main/states`));
    }

    getCitiesOfState(id){
        return UnWrapResults(axios.get(`${BASE_URL}/main/cities/${id}`));
    }

}

class EmailTemplatesService {
    getAll(){
        return UnWrapResults(axios.get(`${BASE_URL}/emailTemplates/getAllEmailTemplates`));
    }

    get(id){
        return UnWrapResults(axios.get(`${BASE_URL}/emailTemplates/${id}`), {});
    }

    create(templateData){
        return axios.post(`${BASE_URL}/emailTemplates/createEmailTemplate`, templateData);
    }

    update(id, templateData){
        return axios.put(`${BASE_URL}/emailTemplates/${id}/edit`, templateData);
    }

    getAvailableTokens(){
        return UnWrapResults(axios.get(`${BASE_URL}/emailTemplates/getAvailableTokens`));
    }
}

class ContactsService {
    getAll(){
        return UnWrapResults(axios.get(`${BASE_URL}/contacts/getContacts`));
    }
    getToExport(){
        return UnWrapResults(axios.get(`${BASE_URL}/contacts/getToExportContacts`));
    }
    getEmails(){
        return UnWrapResults(axios.get(`${BASE_URL}/contacts/getContactsEmailNames`));
    }
    get(id){
        console.log("getting values for "+ id);
        return UnWrapResults(axios.get(`${BASE_URL}/contacts/${id}`), {});
    }

    create(templateData){
        return axios.post(`${BASE_URL}/contacts/createContact`, templateData);
    }

    importContacts(templateData){
        return axios.post(`${BASE_URL}/contacts/importContacts`, templateData);
    }

    update(id, templateData){
        return axios.put(`${BASE_URL}/contacts/${id}/edit`, templateData);
    }
}

class CompaniesService {
    getAll(){
        return UnWrapResults(axios.get(`${BASE_URL}/companies/getCompanies`));
    }

    get(id){
        console.log("getting values for "+ id);
        return UnWrapResults(axios.get(`${BASE_URL}/companies/${id}`), {});
    }

    create(templateData){
        return axios.post(`${BASE_URL}/companies/createCompany`, templateData);
    }

    update(id, templateData){
        return axios.put(`${BASE_URL}/companies/${id}/edit`, templateData);
    }

    updateSMTP(id, templateData){
        return axios.put(`${BASE_URL}/companies/${id}/updateSmtp`, templateData);
    }

}

class CampaignsService {

    constructor(){
        this.multipart_config = {
            headers: {
                "content-type": "multipart/form-data"
            }
        };
    }

    getAll(){
        return UnWrapResults(axios.get(`${BASE_URL}/campaigns/getCampaigns`));
    }
    getTemplates(){
        return UnWrapResults(axios.get(`${BASE_URL}/campaigns/getCampaignsTemplates`));
    }
    getTemplateId(id){
        return UnWrapResults(axios.get(`${BASE_URL}/campaigns/${id}/getCampaignTemplateById`));
    }

    get(id){
        console.log("getting values for "+ id);
        return UnWrapResults(axios.get(`${BASE_URL}/campaigns/${id}`), {});
    }

    getCampaignItems (id){
        return UnWrapResults(axios.get(`${BASE_URL}/campaigns/${id}/getCampaignItems`))
    }

    create(templateData){
        return axios.post(`${BASE_URL}/campaigns/createCampaign`, templateData, this.multipart_config);
    }

    update(id, templateData){
        return axios.put(`${BASE_URL}/campaigns/${id}/edit`, templateData, this.multipart_config);
    }

    addTemplate(templateData){
        return axios.post(`${BASE_URL}/campaigns/addTemplate`, templateData, this.multipart_config);
    }

    updateTemplate(id, templateData){
        return axios.put(`${BASE_URL}/campaigns/${id}/updateTemplate`, templateData, this.multipart_config);
    }

    sendCampaign(id){
        return UnWrapResults(axios.get(`${BASE_URL}/campaigns/${id}/sendCampaign`));
    }

    unsuscribe(data){
        return UnWrapResults(axios.get(`${BASE_URL}/campaigns/unsuscribe/${data}`));
    }

    delete(id){
        return axios.post(`${BASE_URL}/campaigns/${id}/delete`)
    }

}

class EmailListsService {
    constructor(){
        this.multipart_config = {
            headers: {
                "content-type": "multipart/form-data"
            }
        };
    }

    getAll(){
        return UnWrapResults(axios.get(`${BASE_URL}/emailLists/getEmailLists`));
    }

    get(id){
        console.log("getting values for "+ id);
        return UnWrapResults(axios.get(`${BASE_URL}/emailLists/${id}`), {});
    }

    create(templateData){
        return axios.post(`${BASE_URL}/emailLists/createEmailList`, templateData);
    }

    update(id, templateData){
        return axios.put(`${BASE_URL}/emailLists/${id}/edit`, templateData);
    }

    getEmails(id, templateData){
        return UnWrapResults(axios.get(`${BASE_URL}/emailLists/${id}/getEmailListItems`, templateData),{});
    }

    updateEmails(id, templateData){
        return axios.put(`${BASE_URL}/emailLists/${id}/updateEmails`, templateData,this.multipart_config);
    }
    
    deleteEmails(id, templateData){
        return axios.put(`${BASE_URL}/emailLists/${id}/deleteEmails`, templateData);
    }

    deleteEmailItem(id,templateData){
        return axios.post(`${BASE_URL}/emailLists/${id}/deleteEmailListItem`,templateData);
    }

    deleteEmailItemByEmail(id,templateData){
        return axios.post(`${BASE_URL}/emailLists/${id}/deleteEmailListItemByEmail`,templateData);
    }

    delete(id){
        return axios.post(`${BASE_URL}/emailLists/${id}/delete`)
    }

}

class PagesService {

    getAll(){
        return UnWrapResults(axios.get(`${BASE_URL}/pages/getPages`));
    }

    getLandingTemplates(){
        return UnWrapResults(axios.get(`${BASE_URL}/pages/getLandingTemplates`),[]);
    }

    processLandingPageBody(frmData){
        return axios.post(`${BASE_URL}/pages/processLandingPageBody`, frmData);
    }

    createLandingPage(frmData){
        return axios.post(`${BASE_URL}/pages/createLandingPage`, frmData);
    }

    get(id){
        return UnWrapResults(axios.get(`${BASE_URL}/pages/${id}`), {});
    }

    getPageTranslation(id,language){
        return UnWrapResults(axios.get(`${BASE_URL}/pages/${id}/${language}`), {});
    }

    create(templateData){
        return axios.post(`${BASE_URL}/pages/createPage`, templateData);
    }

    update(id, templateData){
        return axios.put(`${BASE_URL}/pages/${id}/edit`, templateData);
    }

    createOrUpdateTranslation(id, language,templateData){
        if (!id)
            return axios.post(`${BASE_URL}/pages/${id}/${language}/createPageTranslation`, templateData);
        else
            return axios.put(`${BASE_URL}/pages/${id}/editTranslation`, templateData);
    }

    createOrUpdateLandingPageTranslation(id,language,frmData){
        if (!id)
            return axios.post(`${BASE_URL}/pages/${id}/${language}/createLandingPageTranslation`, frmData);
        else
            return axios.put(`${BASE_URL}/pages/${id}/editTranslation`, frmData);
    }



    delete(id){
        return axios.post(`${BASE_URL}/pages/${id}/delete`)
    }
}

class NewsService {

    getAll(){
        return UnWrapResults(axios.get(`${BASE_URL}/news/getNews`));
    }

    get(id){
        return UnWrapResults(axios.get(`${BASE_URL}/news/${id}`), {});
    }

    getNewsTranslation(id,language){
        return UnWrapResults(axios.get(`${BASE_URL}/news/${id}/${language}`), {});
    }

    create(templateData){
        return axios.post(`${BASE_URL}/news/createNews`, templateData);
    }

    update(id, templateData){
        return axios.put(`${BASE_URL}/news/${id}/edit`, templateData);
    }

    createOrUpdateTranslation(id, language,templateData){
        if (!id)
            return axios.post(`${BASE_URL}/news/${id}/${language}/createNewsTranslation`, templateData);
        else
            return axios.put(`${BASE_URL}/news/${id}/editTranslation`, templateData);
    }

    delete(id){
        return axios.post(`${BASE_URL}/news/${id}/delete`)
    }
}

class VideosService {

    getAll(){
        return UnWrapResults(axios.get(`${BASE_URL}/videos/getVideos`));
    }

    get(id){
        return UnWrapResults(axios.get(`${BASE_URL}/videos/${id}`), {});
    }

    getNewsTranslation(id,language){
        return UnWrapResults(axios.get(`${BASE_URL}/videos/${id}/${language}`), {});
    }

    create(templateData){
        return axios.post(`${BASE_URL}/videos/createVideo`, templateData);
    }

    update(id, templateData){   // ASI LO HICIERON::: NI MODO MANDARE UN JSON: 
        return axios.put(`${BASE_URL}/videos/${id}/edit`, templateData, { headers: { 'content-type': 'application/json' } });
    }

    createOrUpdateTranslation(id, language,templateData){
        if (!id)
            return axios.post(`${BASE_URL}/videos/${id}/${language}/createVideoTranslation`, templateData);
        else
            return axios.put(`${BASE_URL}/videos/${id}/editTranslation`, templateData);
    }

    delete(id){
        return axios.post(`${BASE_URL}/videos/${id}/delete`)
    }
}

export const propertiesService = new PropertiesService();
export const propertyTypesService = new PropertyTypesService();
export const categoriesService = new CategoriesService();
export const amenitiesService = new AmenitiesService();
export const currenciesService = new CurrenciesService();
export const placesService = new PlacesService();
export const emailTemplatesService = new EmailTemplatesService();
export const newsService = new NewsService();
export const videosService = new VideosService();
export const contactsService = new ContactsService();
export const companiesService = new CompaniesService();
export const pagesService = new PagesService();
export const campaignsService = new CampaignsService();
export const emailListsService = new EmailListsService();
export const feedMapperService = new FeedMapperService();
