import React, { Component } from 'react';
import Swal from 'sweetalert2'
import { RULE_FUNCTIONS, Validator } from '../../../utilities/form-validation';
import classNames from 'classnames';
import PageTranlated from '../../../models/pageTranslated';

export default class LandingPageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...(props.page ||  new PageTranlated()),
            errors: [],
        };

        this.state.formTitle = this.state.pageName || 'Crear un nueva Página';
        this.state.editMode = this.state.id !== null;   //HAY QUE  USARLO::: 


        this.submitCallback = this.props.onSubmit;
        this.translateCallback = this.props.onSubmitTranslate;
        
        let validationRules =
            [
                {
                    fields: ['pageName'],
                    rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 100)
                },
                {
                    fields: ['title'],
                    rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 70)
                },
                {
                    fields:['description'],
                    rule: v => RULE_FUNCTIONS.maxLen(v, 160)  //recomendado entre 155-160
                },
                {
                    fields:['metas'],
                    rule: v => RULE_FUNCTIONS.maxLen(v, 3000)
                },
                {
                    fields:['keywords'],
                    rule: v => RULE_FUNCTIONS.maxLen(v, 255)//recomendado entre 100-255
                },
                {
                    fields:['pageUrl'],
                    rule: v => RULE_FUNCTIONS.maxLen(v, 300)
                }
            ]
        this.validator = new Validator(validationRules);
       
    }
    componentDidMount(){
        console.log(`mounted ${this.props.page}`);                                          
    }

    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) === -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    handleChangeInput = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
        this.validateInput(name, value);
    }

    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) === -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    hasError = (name) => {
        return this.state.errors.indexOf(name) !== -1;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let errors = this.validator.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            Swal.fire('Error!', `cuentas con ${errors.length} error(es) en el formulario, por favor revisa los campos en rojo`, 'error');
            return false;
        }

        const formData = new FormData();
        
        formData.append('pageName', this.state.pageName);
        formData.append('title', this.state.title);
        formData.append('description', this.state.description);

        formData.append('pageUrl', this.state.pageUrl);
        formData.append('keywords', this.state.keywords);
        formData.append('metas', this.state.metas);
        formData.append('bodyScript', this.state.bodyScript);

        if (typeof this.translateCallback === "function"){
              formData.append('pageId', this.state.pageId);  //la pagina padre de la traduccion. 
              formData.append('language', this.state.language);
              this.translateCallback(formData,this.state.id,this.state.language);
        }
        else
        if (typeof this.submitCallback === "function") {
            if (this.state.id)
                this.submitCallback(formData,this.state.id);  //si el Id entonces es edicion. 
            else
                this.submitCallback(formData);  //si el Id entonces nuevo. 
        }
   
    }

    render() {
        return (
            <>

                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <div className="">
                        <div className="modal-content">

                            <div className="panel panel-default">
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-row ">

                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Nombre de la Página : <span  className="text-red">*</span></label>
                                                <input name="pageName" value={this.state.pageName ? this.state.pageName : ''} type="text" 
                                                    onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('pageName') })} />
                                                <div className="invalid-feedback">Nombre requerido - máximo 100 caracteres.</div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Titulo : <span  className="text-red">*</span>
                                                <span className="text-info">  ({ this.state.title ?this.state.title.length:0} / 70) caracteres</span>

                                                </label>
                                                <input name="title" type="text" value={this.state.title} onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('title') })} />
                                                    <div className="invalid-feedback">Titulo requerido - recomendado 50 y máximo 60 caracteres.</div>
                                            </div>
                                            <div className="col-12 ">
                                                <label>pageUrl : </label>
                                                <input name="pageUrl" value={this.state.pageUrl}  type="text"
                                                    onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('pageUrl') })} />
                                                    <div className="invalid-feedback">PageURL  - máximo 300 caracteres.</div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Description : 
                                                    <span className="text-info">  ({ this.state.description?this.state.description.length:0} / 160) caracteres</span>
                                                </label>
                                                <textarea name="description"  rows="3" value={this.state.description} onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('description') })} />
                                                    <div className="invalid-feedback">Descripción - recomendado 155 y máximo 160 caracteres.</div>
                                            </div>
                                           
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label> Keywords para busqueda{" "} 
                                                    <span className="text-info"> ({ this.state.keywords?this.state.keywords.length:0} / 255) caracteres</span> 
                                                </label>                                                 
                                                <textarea name="keywords" value={this.state.keywords}   rows="3"
                                                    onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('keywords') })} />
                                                <div className="invalid-feedback">Keywords  - recomedendado 100 y máximo 255 caracteres</div>
                                            </div>
                                            <div className="col-12 ">
                                                    <label> Metas Descriptores {" "} 
                                                        <span className="text-info">  ({ this.state.metas?this.state.metas.length:0} / 3000) caracteres</span>
                                                    </label>                                                 
                                                    <textarea name="metas" rows="5" value={this.state.metas} onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('metas') })}></textarea>
                                                    <div className="invalid-feedback">Metadescripción  - máximo 3000 caracteres.</div>
                                            </div>
                                            <div className="col-12 ">
                                                    <label> Script en el &lt;HEAD&gt;
                                                    </label>   
                                                    <textarea name="bodyScript" rows="5" value={this.state.bodyScript} onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('bodyScript') })}></textarea>
                                            </div>
                                            <div className="d-flex justify-content-around col-12">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-success btn-lg" type="submit">Guardar</button>
                                <button className="btn float-right" type="button" onClick={() => window.location.href = '/landingpages'}>CANCELAR</button>

                            </div>
                        </div>
                    </div>
                </form>
            </>
        )

    }

}