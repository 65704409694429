import React, { Component, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import Swal from 'sweetalert2';


export default class TableOportunities extends Component {

    constructor(props) {
        super(props);
        const {dataOportunities}=props;
        this.fas = ['fa fa-circle-o','fa fa-clone','fa fa-history','fa fa-line-chart','fa fa-heartbeat','fa fa-minus-circle'];
        this.colors = ['white','red','blue','gray','yellow','dark-blue'];
        this.divBack = {
            backgroundColor : '#FFEEE' 
        }
        this.columns = [
            {
                key: "description",
                text: "Estado",
                className: "name",
                sortable: true,
                width: 40,
                cell: record => {
                    return (
                        <Fragment>
                                { 
                                     <i title={record.description} className={this.fas[record.status]}></i>
                                   }
                        </Fragment>
                    );
                }
            },
            {
                key: "contact",
                text: "Contacto",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "property",
                text: "Interesado En",
                className: "desc",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment> 
                                <p>
                                    {(record.property)?record.property:'Aun no ha desidicio <Toma Acción> y enviale uno'}
                                    <br/>
                                    {record.lastCommentOn?.split('T')[0]}
                                </p>
                        </Fragment>
                    );
                }
            },
        /*    {
                key: "userName",
                text: "Asignado a",
                className: "desc",
                align: "left",
                sortable: true,
            }, */
            {
                key: "comment",
                text: "Ultimo comentario",
                className: "desc",
                align: "left",
                sortable: true,
                width: 300,

            },
            
            {
                key: "action",
                text: "Acciones",
                className: "action",
                width: 210,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment> 
                              <div style={this.divBack}>
                                     <button title="Change Status" className="MuiButtonBase-root MuiIconButton-root"
                                        onClick={() =>  window.location.href='/oportunities/' + record.oportunityId} >
                                        <i className="fa fa-edit"></i>
                                    </button> 
                                    <button title="Eliminar Oportunidad" className="MuiButtonBase-root MuiIconButton-root"
                                        onClick={() => this.deleteRecord(record)}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                    <button title="Cambiar Status" className="MuiButtonBase-root MuiIconButton-root"
                                        onClick={() => this.props.handleShow('status',record)} >
                                        <i className="fa fa-cogs"></i>
                                    </button>
                                    <button title="Agregar Comentario" className="MuiButtonBase-root MuiIconButton-root"
                                        onClick={() => this.props.handleShow('comment',record)} >
                                        <i className="fa fa-comment"></i>
                                    </button>
                                </div>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {excel: true},
            grouping: true,
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            }
        }

        this.state = {
            records: dataOportunities,
        }
        this.options = {
            filterType: 'checkbox',
          };
    }

    componentWillReceiveProps = (next_props) => {
        this.setState({records: next_props.dataOportunities});
    }

    deleteRecord = (record) => {
        console.log("Delete Record", record);
        Swal.fire({
            title: '¿Esta seguro de eliminar la Oportunidad seleccionada?',
            text: "Una vez eliminada no podrá ser recuperado.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c666f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.props.delete(record.id);
            }
        });
    }

    render() {
        return (
            <Fragment>
                <ReactDatatable
                config={this.config} 
                records={this.state.records}
                columns={this.columns}
                options = {this.options}/>
            </Fragment>
        )
    }
}
