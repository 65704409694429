class Campaign {


    constructor(entity = {}){
        //const CampaignStatus=['Incompleto','Eliminado','Configurando','Listo para Enviar','Enviando...','Enviado'];

        this.id = entity.campaignId || null;
        this.campaignName = entity.campaignName || '';
        this.campaignStatus = entity.campaignStatus || 0 ;   // 0 incompleto, 1= deleted, 2 = setting up, 3= ready to Sent, 4 = sending, 5=sent. 
        //console.log(this.campaignStatus);
        //this.campaignStatusDescription = CampaignStatus[this.campaignStatus];
        this.closingDate = entity.closingDate || '';
        this.totalSent = entity.totalSent || 0;
        this.sendingDate = entity.sendingDate || '';
        this.campaignDesign = entity.campaignDesign || '';
        this.campaignHtml = entity.campaignHtml || '';

        this.campaignTemplateId = entity.campaignTemplateId || 0;
        this.deleted = entity.deleted  || 0;
        this.campaignEmailListId = entity.campaignEmailListId  || 0; 
        this.createdOn = entity.createdOn  || '';
        this.updatedOn = entity.updatedOn || '';
        this.campaignTag = entity.campaignTag  || '';
        this.subject = entity.subject  || '';
        this.replyTo = entity.replyTo  || '';
        this.sender = entity.sender  || '';
        this.senderEmail = entity.senderEmail || '';

        this.totalEmails = entity.totalEmails || 0;

    }
};

export default Campaign;
