import React, { Component } from "react"
import { pagesService } from '../../services/tibesa.api'
import LoadingOverlay from "react-loading-overlay";
import Swal from 'sweetalert2'
import PageTranslated from "../../models/pageTranslated";

import PageForm from "./components/page-form";

export default class PageDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: null
        }
        this.submitChanges = this.submitChanges.bind(this);
    }

    componentDidMount() {
        let curId=this.props.match.params.id;
        let curLan =this.props.match.params.language;
        Promise.all([pagesService.get(curId)])
        .then(([page]) => {
            //antes de asignasr.. çme piden el detalle de un idioma?
            if (this.props.match.params.language&&curId){
                //tray to get that language. 
                Promise.all([pagesService.getPageTranslation(curId,curLan)])
                .then(([pageT]) => {

                    this.setState({ page : new PageTranslated(pageT),language:  curLan.toLowerCase()});
                    //si no habia datos todo el page esta en null 
                 //   console.log(`from query paget ${pageT.translationId}`);
                    if (!pageT.translationId)  {
                      //  console.log(`Nueva Traduccion de ${curId} en idioma EN`);
                        pageT.language = curLan.toLowerCase();
                        pageT.pageId = curId;
                        this.setState({
                            page : new PageTranslated(pageT),
                            language:curLan.toLowerCase(),
                        });
                    }
                });
            }
            else{
                this.setState({ page: new PageTranslated(page),language:'es' });
            }
        })
    }

    showTab(language){
        if (language===this.state.language){
            return "tab-paneactive show active fade p-3"
        }
        return "display:none"
    }
    showNav(language){
        if (language===this.state.language){
            return "nav-link show active "
        }
        return "nav-link"
    }

    selectTab(language){
        if (language===this.state.language){
            return "true"
        }
        return "false"
    }

    submitChanges(formData,id) {
        Swal.fire({
            title: '¿Seguro que deseas editar la Página?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return pagesService.update(id, formData)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
                if (response.value && response.value.data && response.value.data.status === 200) {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: '¡Página actualizada con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }
                    ).then(() => {
                        window.location.reload();
                    });
                } else if(!response.dismiss === 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }

    submitTransalation(formData,id,language) {     
        Swal.fire({
            title: '¿Seguro que deseas editar la Página?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return pagesService.createOrUpdateTranslation(id,language, formData)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
                if (response.value && response.value.data && response.value.data.status === 200) {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: '¡Página actualizada con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }
                    ).then(() => {
                        window.location.reload();
                    });
                } else if(!response.dismiss === 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }

    render() {

        let form;
        let formEn;

        if (this.state.page != null) {
            if (this.state.page.language==='en'){
                  formEn = <PageForm page={this.state.page} onSubmitTranslate={this.submitTransalation} ></PageForm>
            }
            else{
                form = <PageForm page={this.state.page} onSubmit={this.submitChanges}></PageForm>
            }

        }
        else
            form = <LoadingOverlay></LoadingOverlay>

        return (
            <>
            <div className="alert alert-primary" role="alert"> El propósito de esta página es proveer un mecanismo para agregar contenido SEO a las paginas estaticas del portal, tanto en ingles como en Español.</div>

                <div className="air__utils__heading">
                    <a href="/pages">Listado de páginas</a><span>{" "}/{" "}Editar pagina #{this.props.match.params.id}</span>
                </div>
                
                <div className="card mt-3 tab-card">
                    <div className="card-header tab-card-header">
                        <ul className="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className={this.showNav('es')} id="one-tab" data-toggle="tab" href={"/pages/"+this.props.match.params.id} role="tab" aria-controls="One" aria-selected={this.selectTab('es')} >Español</a>
                            </li>
                            <li className="nav-item">
                                <a className={this.showNav('en')}  id="two-tab" data-toggle="tab"href={"/pages/"+this.props.match.params.id+"/en"}role="tab" aria-controls="Two" aria-selected={this.selectTab('en')}>English</a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" id="myTabContent">
                        <div className={this.showTab('es')} id="one" role="tabpanel" aria-labelledby="one-tab">
                                {form}          
                        </div>
                        <div className={this.showTab('en')}  id="two" role="tabpanel" aria-labelledby="two-tab">
                                {formEn}          
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
