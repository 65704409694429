import React, { Component, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import Swal from 'sweetalert2';
import Modal from 'react-modal';


export default class TableContactForm extends Component {

    constructor(props) {
        super(props);
        const {datacontacts}=props;

        this.columns = [
            {
                key: "nombre",
                text: "Nombre",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "email",
                text: "Correo",
                className: "desc",
                align: "left",
                sortable: true,
            },
            {
                key: "subject",
                text: "Descripcion",
                className: "status",
                align: "left",
                sortable: true,
            },
            {
                key: "action",
                text: "Acciones",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>

                             <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.showInfo(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-info"></i>
                            </button>

                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>

                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {excel: true}
        }

        this.state = {
            records: datacontacts,
            modalIsOpen: false,
            selected: {}
        }
    }

    openModal = () =>{
        this.setState({ modalIsOpen: true });
    }

    closeModal = () =>{
        this.setState({ modalIsOpen: false });
    }

    componentWillReceiveProps = (next_props) => {
        this.setState({records: next_props.datacontacts});
    }
    
    showInfo = (record) => {
        console.log('SELECTED', record);
        this.openModal();
        this.setState({selected: record});
    }

    deleteRecord = (record) => {
        console.log("Delete Record", record);
        Swal.fire({
            title: '¿Esta seguro de eliminar el contacto seleccionada?',
            text: "Una vez eliminada no podrá ser recuperado.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c666f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.props.delete(record.idContactoForm);
            }
        });
    }

    render() {
        return (
            <Fragment>
                <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}/>
                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    className="Modal"
                    contentLabel="Informacion Contacto">
                    <div className="modal-dialog modal-lg modal-contact-user">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Informacion de Contacto</h5>
                                <button autoFocus={true} type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row my-4 px-5">
                                    <div className="col-12">
                                        <div className="row row-values">
                                            <div className="col-2">
                                                <div className="atts">Nombre</div>
                                            </div>
                                            <div className="col-8">
                                                <div className="atts-values">{this.state.selected.nombre}</div>
                                            </div>                                      
                                        </div>
                                        <div className="row row-values">
                                             <div className="col-2">
                                                <div className="atts">Email</div>
                                            </div>
                                            <div className="col-8">
                                                <div className="atts-values">{this.state.selected.email}</div> 
                                            </div>                                            
                                        </div>
                                        <div className="row row-values">
                                             <div className="col-2">
                                                <div className="atts">Asunto</div>
                                            </div>
                                            <div className="col-8">
                                                <div className="atts-values">{this.state.selected.subject}</div>   
                                            </div>                                          
                                        </div>
                                        <div className="row row-values">
                                             <div className="col-2">
                                                <div className="atts">Mensaje</div>
                                            </div>
                                            <div className="col-8">
                                                <div className="atts-values">{this.state.selected.message}</div>
                                            </div>                                             
                                        </div>
                                        <div className="row row-values">
                                             <div className="col-2">
                                                <div className="atts">Teléfono</div>
                                            </div>
                                            <div className="col-8">
                                                <div className="atts-values">{this.state.selected.telephone}</div>
                                            </div>                                             
                                        </div>
                                        <div className="row row-values">
                                             <div className="col-2">
                                                <div className="atts">Pais</div>
                                            </div>
                                            <div className="col-8">
                                                <div className="atts-values">{this.state.selected.country}</div>   
                                            </div>                                          
                                        </div>
                                        <div className="row row-values">
                                             <div className="col-2">
                                            <   div className="atts">State</div>
                                            </div>
                                            <div className="col-8">
                                                <div className="atts-values">{this.state.selected.state}</div> 
                                            </div>                                            
                                        </div>
                                        <div className="row row-values">
                                             <div className="col-2">
                                                <div className="atts">Ciudad</div>
                                            </div>
                                            <div className="col-8">
                                                <div className="atts-values">{this.state.selected.city}</div>
                                            </div>                                             
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-danger btn-lg" type="button" onClick={this.closeModal}>CANCELAR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </Fragment>
        )
    }
}
