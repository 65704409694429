import React, { Component, useState  } from 'react';
import Page from '../../../models/pageTranslated';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { each } from 'jquery';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import SectionMap from "../modules/sec-map"
import SectionArticle from "../modules/sec-article"
import SectionVideo from "../modules/sec-video"
import SectionBanner from "../modules/sec-jumbotron"
import SectionListing from "../modules/sec-property-listing"
import SectionPropertyListing from '../modules/sec-property-listing';


export default class LandingPageOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...(props.page ||  new Page()),
            errors: [],
            finalSpaceCharacters : null,
            showAlert:false
        };
        this.state.editMode = this.state.id !== null;   //HAY QUE  USARLO::: 
        this.submitCallback = this.props.onSubmit;
 
    }

    componentDidMount(){
        var parser = new DOMParser();
        var parsedHtml    = parser.parseFromString(`${this.state.bodyHtml}`, "text/html");
        let pTags = parsedHtml.getElementsByTagName("section");
        let vocab = [];
        for (let p of pTags) {
            const id =  p.getAttribute("id");
            const name = p.getAttribute("name") ? p.getAttribute("name") : p.getAttribute("id") ;
            const value = p.outerHTML;
            vocab.push( { id, name, value } )
        }
        this.setState({
            finalSpaceCharacters : vocab
        });
                                     
    }


    handleSubmit = (event) => {
        event.preventDefault();
        const characters = this.state.finalSpaceCharacters;

        if ( characters && characters[0]){
            const formData = new FormData();
            formData.append('pageName', this.state.pageName);

            let finalHtml =''
            characters.map(item => {
                console.log(item);
                finalHtml += item.value
            })


            //let dataHtml = new Buffer.from( finalHtml,'utf-8').toString("base64");
            formData.append('bodyHtml', finalHtml);
            if (typeof this.submitCallback === "function") {
                this.submitCallback(formData,this.state.id);  //si el Id entonces es edicion. 
            }
        }
    }

    handleOnDragEnd = (result) => {
        console.log(result.source.index);
        if (!result.destination) return;

        const items = Array.from(this.state.finalSpaceCharacters);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        this.updateCharacters(items); 
      }

    updateCharacters = (items)=> {
        this.setState({
            finalSpaceCharacters : items
        })
    }

    removeModule = (index) => {
        var allitems = this.state.finalSpaceCharacters;
        if (index !== -1) {
            allitems.splice(index, 1);
            this.setState({
                finalSpaceCharacters:allitems
            })
        }
    }

    addModule = (item) =>{
        var allitems = this.state.finalSpaceCharacters;
        allitems.push(item);
        this.setState({
            finalSpaceCharacters:allitems,
            showAlert:true
        })
        setTimeout(() => {
            this.setState({
                showAlert:false
            })  
        }, 2000);


    }

    render() {

        const styles= {
                'cursor': 'move',
                'marginBottom': '0',
                'WebkitBoxShadow': '0 1px 5px 0 rgba(0, 0, 0, 0.14)',
                'boxShadow': '0 1px 5px 0 rgba(0, 0, 0, 0.14)',
                'marginBottom': '20px',
                'padding': '15px 0 15px 60px'
        };

        const stylecolor = {
            'color':'white'
        }
        const characters = this.state.finalSpaceCharacters;
        
        if ( characters && characters[0])
        return (
            <>  
                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <div className="">
                        <div className="">
                            <div className="">
                            </div>
                            <div className="">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-row ">
                                            <div className="col-md-4 col-lg-4 my-3">
                                                <div className="d-flex justify-content-center h3"> Arrastra y Ordena</div>
                                                { characters  &&
                                                <DragDropContext onDragEnd={this.handleOnDragEnd}>
                                                    <Droppable droppableId="characters">
                                                        {   (provided) => (
                                                            <div className="col-md-12 characters" {...provided.droppableProps} ref={provided.innerRef} >
                                                                { this.state.showAlert && <div class="alert alert-success " role="alert">Modulo Agregado</div> }
                                                                {
                                                                    characters.map(({id, name}, index) => { 
                                                                        return (
                                                                            <Draggable key={id} draggableId={id} index={index}>
                                                                            {(provided) => (
                                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                    <div style={styles} className="sortable-moves"> 
                                                                                        <h6>
                                                                                            <a className="btn btn-secondary" style={stylecolor} onClick={() => this.removeModule(index)}>X</a>
                                                                                            &nbsp; {index+1} - {name}
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            </Draggable>
                                                                        );
                                                                    })
                                                                }
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                    </DragDropContext>
                                                }
                                            </div>
                                            <div className="col-md-8 col-lg-8">
                                            <div className="h3  d-flex  justify-content-center"> Modulos que puedes agregar</div>
                                            <Tabs>
                                                    <TabList>
                                                        <Tab>Map</Tab>
                                                        <Tab>Articulo</Tab>
                                                        <Tab>Video</Tab>
                                                        <Tab>Banner</Tab>
                                                        <Tab>Listado</Tab>

                                                    </TabList>
                                                    <TabPanel>
                                                         <SectionMap onSubmit={this.addModule}></SectionMap>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <SectionArticle onSubmit={this.addModule}></SectionArticle>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <SectionVideo onSubmit={this.addModule}></SectionVideo>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <SectionBanner onSubmit={this.addModule}></SectionBanner>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <SectionListing onSubmit={this.addModule}></SectionListing>
                                                    </TabPanel>
                                                </Tabs>
                                            </div>
                                         </div>
                                    </div>
                                </div>
                            </div>
                          
                            <div className="modal-footer">
                                <button className="btn btn-success btn-lg" type="submit">Guardar</button>
                                <button className="btn float-right" type="button" onClick={() => window.location.href = '/landingpages'}>CANCELAR</button>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        )
        else
            return('Formato No Valido Ha ocurrido un Error!!!')
        
    }
}