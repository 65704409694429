import React, { Component,useRef } from 'react';
import EmailEditor from 'react-email-editor';
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';

export default class DesignEmailBuilder extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        isloaded : false,
        iFrameHeight : null,
    };

    this.submitCallback = this.props.submitDesign;
    this.submitUpdateTemplateCallback = this.props.submitUpdateTemplate;
    this.submitAddTemplateCallback = this.props.submitAddTemplate;

    this.loadDesign = this.loadDesign.bind(this);
  }

  ref= ()  => {
    return this;
  }

  saveDesign = () => {
    this.editor.exportHtml(data => {
      const { design, html } = data;
      this.submitCallback(design,html); 
    });
  }

  updateTemplate = () => {
    this.editor.exportHtml(data => {
      const { design, html } = data;
      this.submitUpdateTemplateCallback(design,html); 
    });
  }

  addTemplate = () => {
    this.editor.exportHtml(data => {
      const { design, html } = data;
      this.submitAddTemplateCallback(design,html); 
    });
  }

  cancelDesign = () => {
      this.submitCallback(null); 
   }

  componentDidMount =  () => {
    this.setState ({isloaded:true})
    const totalHeight = ( window.innerHeight - 230 );
          this.setState({
          iFrameHeight : (totalHeight>500 ? totalHeight : 500) + 'px'
      })  
  }

  onLoad = () => {        
   
  }

  //external call to change current load. 
 loadDesign = (design) => {
      if (this.editor) {
        this.editor.loadDesign(JSON.parse(design));
      }   
 }
 
  render() {
    return (
      <>
        <div className="air__utils__heading">
                <div className="row">
                  <div className="col-lg-12">
                      <a className= "btn btn-primary float-right"
                        href="#o" onClick={this.saveDesign}>
                      <span>Guardar&nbsp;</span>
                      <span><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
                      </a> <div className= " float-right">&nbsp;</div>
                      <a  className="btn btn-warning float-right"
                          href="#o" onClick={this.cancelDesign}>
                          <span>cancelar&nbsp;</span>
                        <span><i className="fa fa-plus-cross" aria-hidden="true"></i></span>
                      </a>
                    </div>
                  
              </div>
        </div>
              <EmailEditor 
                id='emailEditorReact'
                projectId={1071}
                ref={editor => { this.editor = editor;  }}
                onLoad={  this.onLoad  }
                options={
                  { locale: 'es-ES', 
                    features: {
                        preview: true, userUploads : false
                    },
                    customJS: ['//api.bienesraicestibesa.mx/custom.js'],
                    customCSS: [ 'body {background-color: yellow;}']
                  }
                }
                appearance= {{
                  theme : 'dark'
                }}
                minHeight = { this.state.iFrameHeight}
                displayMode= {'email'}
          />
          <div className="row">
                  
                  <div className="col-lg-12">
                      <a  className="btn btn-outline-secondary float-right"
                          href="#o" onClick={this.updateTemplate}>
                          <span>Actualizar Plantilla&nbsp;</span>
                        <span><i className="fa fa-plus-cross" aria-hidden="true"></i></span>
                      </a><div className= " float-right">&nbsp;</div>
                      <a  className="btn btn-outline-secondary float-right"
                          href="#o" onClick={this.addTemplate}>
                          <span>Agregar Como Plantila&nbsp;</span>
                        <span><i className="fa fa-plus-cross" aria-hidden="true"></i></span>
                      </a>
                    </div>
              </div>

      </>
    );
  }
}