import React, { Component, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import Swal from 'sweetalert2';
import Modal from 'react-modal';

export default class TableVideos extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "titulo",
                text: "Titulo",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "urlVideo",
                text: "Video",
                align: "center",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.showInfo(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-video-camera" aria-hidden="true"></i>
                            </button>
                        </Fragment>
                    );
                }
            },
            {
                key: "status_val",
                text: "Estatus",
                className: "status",
                align: "left",
                sortable: true,
            },
            {
                key: "action",
                text: "Acciones",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                                
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {excel: true},
            sort:{
                'status_val': 'desc'
            }
        }

        this.state = {
            records: this.props.data,
            modalIsOpen: false,
            selected: {},
            showVideoReady: true
        }
    }

    componentWillReceiveProps = (next_props) => {
        this.setState({records: next_props.data});
    }

    editRecord = (record) => {
        this.props.handleOpenEdit(record);
    }

    deleteRecord = (record) => {
        console.log("Delete Record", record);
        Swal.fire({
            title: '¿Esta seguro de eliminar el video seleccionado?',
            text: "Una vez eliminada no podrá ser recuperado.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c666f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.props.delete(record.idVideo);
            }
        });
    }
    
    openModal = () =>{
        this.setState({ modalIsOpen: true});
    }

    closeModal = () =>{
        this.setState({ modalIsOpen: false, showVideoReady: true  });
    }

    showInfo=(record)=>{
        this.openModal();
        this.setState({selected: record});
    }

    videoLoader = () =>{
        this.setState({showVideoReady: false});
    }

    render() {
        return (
            <div>
                <ReactDatatable
                    config={this.config}
                    records={this.state.records}
                    columns={this.columns}
                />
                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    className="Modal"
                    contentLabel="Informacion Contacto">
                    <div className="modal-dialog modal-lg modal-contact-user">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Visualizar Video</h5>
                                <button autoFocus={true} type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row my-4 px-5">
                                    <div className="col-12 video-yt d-flex flex-column justify-content-center text-center">

                                        {
                                            this.state.showVideoReady == true ?
                                            <h4>
                                                Cargando video...
                                            </h4> :
                                            null
                                        }

                                        <iframe width="100%" height="315" src={this.state.selected.urlVideo} 
                                            frameBorder="0" 
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                            allowFullScreen
                                            title='video'
                                            onLoad={this.videoLoader}>
                                        </iframe>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-danger btn-lg" type="button" onClick={this.closeModal}>ACEPTAR</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}
