import React, { Component } from 'react'
import TablePages from './components/table';

import configs from '../../configs/config';
import axios from 'axios';
import Swal from 'sweetalert2'
import { escapeSelector } from 'jquery';

export default class index extends Component {

    constructor() {
        super();
        this.state = {
            isuploading: false,
            type: 1,  //Default
            errors: [],
            data:[],
        }
        this.getData();
    }

    getData = () => {
        axios.get(`${configs.URL_API_BASE}/pages/getLandingPages`, {})
            .then(res => {
                if (res.data.status === 200) {
                    let auxData = res.data.results;
                    auxData.forEach((item) => {
                        item.id = item.pageId;
                    })
                    this.setState({ data: auxData });
                }
            });
    }
    delete = (id) => {
        axios.post(`${configs.URL_API_BASE}/pages/${id}/deleteLandingPage`)
            .then(res => {
                console.log(res);
                if (parseInt(res.data.status) === 200) {
                    this.getData();
                    Swal.fire('Eliminado!', 'Elemento eliminado correctamente.', 'success');
                } else {
                    Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
                }
            }).catch(err => {
                console.log(err);
                Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
            });
    }
    

    handleOpenEdit = (record,section) => {
        this.props.history.push(`/landingpages/${record.id}/${section}`);
    }


    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Páginas de Aterrizaje</h2>
                    <a href="/landingPages/addWizard" className="btn btn-primary d-flex justify-content-between align-self-center">
                        <span>Nueva Página de Aterrizaje&nbsp;</span>
                        <span><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        {
                            <TablePages handleOpenEdit={this.handleOpenEdit} delete={this.delete} data={this.state.data}></TablePages>
                        }
                    </div>
                </div>
            
            </>
        )
    }
}
