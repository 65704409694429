import React, { Component } from "react"
import { emailListsService } from '../../services/tibesa.api'
import LoadingOverlay from "react-loading-overlay";
import Swal from 'sweetalert2'
import EmailListForm from "./components/emaillist-form";
import EmailList from "../../models/emailList";


export default class EmailListDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailList: null,
            data:null
        }
        this.submitChanges = this.submitChanges.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = function (){
        Promise.all([
            emailListsService.get(this.props.match.params.id)
            ,emailListsService.getEmails(this.props.match.params.id)])
        .then(([emL,allEmails]) => {
            this.setState({ emailList: new EmailList(emL), data:allEmails });
            console.log(this.state.data);
        })
    }

    submitChanges(formData, id = 0 , skipreload = false) {
        //intent guardar los cambios. solo el diseño. 
        emailListsService.updateEmails(this.props.match.params.id, formData)
            .then(response => {
              //  console.log(JSON.stringify(response));
                if (response.data && response.data.status == 200) {
                    Swal.fire(
                        `Se encontraron ${response.data.valids} correos, se omitieron ${response.data.duplicates} correos, de un total de ${response.data.total}`
                    ).then(() => {
                        if (!skipreload)
                                window.location.reload();
                    // this.loadData();
                    });
                } else if(!response.dismiss == 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                    );
                }
            })
            .catch(error => {
                Swal.showValidationMessage(
                    `Error en la petición: ${error}`
                )
            });   
    }


    render() {
        let form;
        if (this.state.emailList != null) 
           form = <EmailListForm emailList={this.state.emailList} data={this.state.data} onSubmit={this.submitChanges}></EmailListForm>
        else
            form = <LoadingOverlay></LoadingOverlay>
        return (
            <>
                <div className="air__utils__heading">
                    <a href="/emailLists">Listas de Correo</a><span>{" "}/{" "}Editar lista de correo #{this.props.match.params.id}</span>
                </div>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <div className="air__utils__heading d-flex justify-content-between align-self-center float-left">
                        <h2>Lista de Correos</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {form}
                    </div>
                </div>
            </>
        )
    }
}