import React, { Component } from 'react';
import Swal from 'sweetalert2'
import { RULE_FUNCTIONS, Validator } from '../../../utilities/form-validation';
import classNames from 'classnames';
import Company from '../../../models/company';

export default class CompanyForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...(props.company || new Company()),
            errors: []
        };

        this.state.formTitle = this.state.name || 'Crear un nueva Empresa';
        this.state.editMode = this.state.id != null;
        this.submitCallback = this.props.onSubmit;

        let validationRules =
            [
                {
                    fields: ['name'],
                    rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 200)
                },
                {
                    fields: ['phoneNumber'],
                    rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.telephone(v) && RULE_FUNCTIONS.maxLen(v, 20) && RULE_FUNCTIONS.minLen(v, 8)
                },
                {
                    fields: ['contactEmail'],
                    rule: v => RULE_FUNCTIONS.notRequired(v) || (RULE_FUNCTIONS.email(v) && RULE_FUNCTIONS.maxLen(v, 200))
                }
            ]
        this.validator = new Validator(validationRules);
    }

    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) == -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    handleChangeInput = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
        this.validateInput(name, value);
    }

    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) == -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    hasError = (name) => {
        return this.state.errors.indexOf(name) !== -1;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let errors = this.validator.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            Swal.fire('Error!', `cuentas con ${errors.length} error(es) en el formulario, por favor revisa los campos en rojo`, 'error');
            return false;
        }

        const formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('address', this.state.address);
        formData.append('cp', this.state.cp);
        formData.append('contactEmail', this.state.contactEmail);
        formData.append('cityCode', this.state.cityCode);
        formData.append('countryCode', this.state.countryCode);
        formData.append('phoneNumbre', this.state.phoneNumbre);

        if (typeof this.submitCallback == "function") {
            this.submitCallback(formData);
        }
    }

    render() {
        return (
            <>

                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <div className="">

                        <div className="modal-content">

                            <div className="panel panel-default">
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-row ">

                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Nombre : <span
                                                    className="text-red">*</span></label>
                                                <input name="name" value={this.state.name ? this.state.name : ''} type="text"
                                                    onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('name') })} />
                                                <div className="invalid-feedback">Nombre requerido - máximo 300 caracteres.</div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Dirección : <span
                                                    className="text-red">*</span></label>
                                                <input name="address" type="text" value={this.state.address} onChange={this.handleChangeInput}
                                                    className={classNames('form-control')} />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Cp : </label>
                                                <input name="cp" type="text" value={this.state.cp} onChange={this.handleChangeInput}
                                                    className={classNames('form-control')} />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Correo de contacto : </label>
                                                <input name="contactEmail" type="text" value={this.state.contactEmail} onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('contactEmail') })} />
                                                    <div className="invalid-feedback">Correo de Contacto requerido - máximo 300 caracteres.</div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Codigo de Ciudad : </label>
                                                <input name="cityCode" type="text" value={this.state.cityCode} onChange={this.handleChangeInput}
                                                    className={classNames('form-control')} />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Codigo de Pais : </label>
                                                <input name="countryCode" type="text" value={this.state.countryCode} onChange={this.handleChangeInput}
                                                    className={classNames('form-control')} />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Telefono : </label>
                                                <input name="phoneNumaber" type="text" value={this.state.phoneNumber} onChange={this.handleChangeInput}
                                                    className={classNames('form-control')} />
                                            </div>

                                            <div className="d-flex justify-content-around col-12">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-success btn-lg" type="submit">Guardar</button>
                                <button className="btn float-right" type="button" onClick={() => window.location.href = '/companies'}>CANCELAR</button>

                            </div>
                        </div>
                    </div>
                </form>
            </>
        )

    }

}