import React, { Component } from 'react'
import { Error } from 'react-form-error'

import Modal from 'react-modal';
import LoadingOverlay from 'react-loading-overlay';

import TableCampaigns from './components/table';

import configs from '../../configs/config';
import axios from 'axios';
import Swal from 'sweetalert2'
import Campaign from '../../models/campaign';

export default class index extends Component {

    CampaignStatus=['Incompleto','Eliminado','Configurando','Listo para Enviar','Enviando...','Enviado'];
  //  0 incompleto, 1= deleted, 2 = setting up, 3= ready to Sent,4 = enviando 5 = sent. 
    constructor() {
        super();
        this.state = {
            isuploading: false,
            type: 1,  //Default
            errors: [],
            data:[],
            name:''
        }
        this.getData();
    }

    getData = () => {
        axios.get(`${configs.URL_API_BASE}/campaigns/getCampaigns`, {})
            .then(res => {
                if (res.data.status === 200) {
                    let auxData = res.data.results;
                    auxData.map((item) => {
                        item.id = item.campaignId;
                        item.campaignStatusDescription = this.CampaignStatus[item.campaignStatus];
                        return new Campaign(item)
                    })
                    this.setState({ data: auxData });
                //    console.log(res.data.results);
                }
            });
    }
    delete = (id) => {
        axios.post(`${configs.URL_API_BASE}/campaigns/${id}/delete`)
            .then(res => {
                //console.log(res);
                if (parseInt(res.data.status) === 200) {
                    this.getData();
                    Swal.fire('Eliminado!', 'Elemento eliminado correctamente.', 'success');
                } else {
                    Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
                }
            }).catch(err => {
                //console.log(err);
                Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
            });
    }
    
    onSubmit = (e) => {
        e.preventDefault();
        if (this.state.name=='') return false;
        const formData = new FormData();
        formData.append('name', this.state.name);

        let url = ``;
        this.setState({ isuploading: true });
        url = `${configs.URL_API_BASE}/campaigns/createCampaign`;
        axios.post(url, formData, this.state.config).then((response) => {
            this.setState({ isuploading: false });
            if (parseInt(response.data.status) == 200) {
                this.props.history.push(`/campaigns/${response.data.results}`);
                //redireccionalo editar la campaña
            } else {
                console.log("fail");
            }
            this.closeModal();
        });
        e.target.reset();
    }

    handleOpenEdit = record => {
        //console.log(record.id);
        this.props.history.push(`/campaigns/${record.id}`);
    }

    handleOpenReport = record => {
        //console.log(record);
        this.props.history.push(`/campaigns/report/${record.id}`);
    }

    onInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    
    openModal = () => {
        this.setState({
            modalIsOpen: true,
            isEdit: false,
            name: '',
        });
    }

    handleSend = () =>{
        Swal.fire({
            title: '¿Esta seguro de Eviar la Campaña?',
            text: "Una vez Enviado ya no podra deshacer Envio",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c666f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Enviar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.sendEmails();
            }
        });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    render() {
        return (
            <>

                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Campañas</h2>

                    <a  className="btn btn-primary d-flex justify-content-between align-self-center"  href="#" onClick={this.openModal}>
                        <span>Nueva Campaña&nbsp;</span>
                        <span><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        {
                            <TableCampaigns handleOpenEdit={this.handleOpenEdit}   handleOpenReport={this.handleOpenReport}  delete={this.delete} data={this.state.data}></TableCampaigns>
                        }
                    </div>
                </div>
                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    className="Modal"
                    contentLabel="Crear Nueva Campaña">
                    <form onSubmit={this.onSubmit}>
                        <div className="modal-dialog">
                            <LoadingOverlay
                                active={this.state.isuploading}
                                spinner
                                text='Subiendo el contenido...'
                            >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{this.state.isEdit ? "Editar Campaña" : "Crear Campaña"}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row content-modal-mobile my-0 my-lg-4">
                                            <div className="col-12">
                                                <div className="form-row my-3">
                                                    
                                                    <div className="col-12 my-3">
                                                        <label>Nombre de la Campaña<span
                                                            className="text-red">*</span></label>
                                                        <input name="name" autoFocus={true} type="text" value={this.state.name} className="form-control" onChange={this.onInputChange} required />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-danger btn-lg" type="button" onClick={this.closeModal}>CANCELAR</button>
                                        <button className="btn btn-success btn-lg" type="submit">CONTINUAR</button>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </form>
                </Modal>
            </>
        )
    }
}
