import React, { Component } from 'react'

import * as ReactBootstrap from 'react-bootstrap';

import TableTimeLine from '../../components/tables/TableTimeLine'
import TablePropertiesSearch from '../properties/components/tableSearch'
import loSanitize from 'lodash';


import Modal from 'react-modal';
import LoadingOverlay from 'react-loading-overlay';
import configs from './../../configs/config';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Lightbox } from "react-modal-image"

import {  placesService, categoriesService, currenciesService, emailTemplatesService } from '../../services/tibesa.api'

import { Editor } from '@tinymce/tinymce-react';


export default class OportunityDetail extends Component {
    constructor() {
        super();
        this.state = {
            dataUsers: [],
            dataHistory:[],
            dataContact: [],
            dataProperties: [],
            dataOportunity: {},
            oportunityId: 0,
//Catalogos
            c_currencies: [],
            c_categories: [],
            c_states: [],
            c_templates:[],
            c_subcategories:[],

            f_stateId : null,
            f_categoryId : null,
            f_subcategoryId : null,
            f_currencyId : null,
            f_templateId : null,


            s_Properties:[],   //holds properties to be send

            s_contactId:null,
            s_agentId:null,
            s_templateId:0,
            s_propertyId:null,
            s_listingTemplateId:0,
            s_mailSubject:null,
            s_mailBody:null,

            open: false,
        


        }

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.onRowClicked = this.onRowClicked.bind(this);
      //  this.getFilteredProperties = this.getFilteredProperties.bind(this);

    }

    componentDidMount() {
        this.getData();
        this.getUsers();
        this.getCatalogs();
    }

    getData = () => {
        const { match: { params } } = this.props;
        this.setState({
            oportunityId : params.oportunityId
        }) 
        axios.get(`${configs.URL_API_BASE}/oportunities/${params.oportunityId}`, {})
            .then(res => {
                if (res.data.status === 200) {
                    let auxData = res.data.results;
                    this.setState({ dataOportunity: auxData });
                    this.getContact();
                    this.getHistory();
                    this.getFilteredProperties();
                }
            });
    }

    getCatalogs() {
        let p_currencies = currenciesService.getAll(),
            p_categories = categoriesService.getAll(),
            p_templates = emailTemplatesService.getAll(),  //si hay muchas plantillas debeia acambiar la forma en que se cargan . 
            p_states = placesService.getStates();

        Promise.all([p_currencies, p_categories, p_states, p_templates])
            .then(([c_currencies, c_categories, c_states,c_templates]) => {
                this.setState({
                    c_currencies : c_currencies,
                    c_categories :  c_categories,
                    c_states : c_states,
                    c_templates : c_templates,
                });
            });

    }

    getUsers = () => {
        axios.get(`${configs.URL_API_BASE}/users/getUsers`, {})
            .then(res => {
                if (res.data.status === 200) {
                    let auxData = res.data.results;
                    auxData.forEach((ele) => {
                        ele.id = ele.user_id;
                    })
                    this.setState({ dataUsers: auxData });
                }
            });
    }

    getHistory = ()=>{
        axios.get(`${configs.URL_API_BASE}/timeLine/${this.state.oportunityId}/getByOportunityId`, {})
            .then(res => {
                if (res.data.status === 200) {
                    try {
                        let auxData = res.data.results;
                        auxData.forEach((item) => {
                            item.id = item.timeLineId;
                        })
                        this.setState({ dataHistory: auxData });
                    }
                    catch{
                        this.setState({ dataHistory: [] });
                    }
                }
            });
    }

    getFilteredProperties = ()=> {
        let query ="";
        let url=`${configs.URL_API_BASE}/properties/getpropertiesbyquery?`;
        if  (this.state.f_categoryId) query = "categoryId=" + this.state.f_categoryId ;  
        if  (this.state.f_subcategoryId) query =  (query!==""? query + "&": "")  +   "subcategoryId=" + this.state.f_subcategoryId;
        if  (this.state.f_stateId) query = (query!==""? query + "&": "") +  "stateId=" + this.state.f_stateId;
        if  (this.state.f_currencyId) query = (query!==""? query + "&": "")  + "currencyId=" + this.state.f_currencyId + "";

        //prepare Default Property. 
        if (query==""&&this.state.dataOportunity&&this.state.dataOportunity.id_property){
            url= `${configs.URL_API_BASE}/properties/`
            //forze to enqter 
            query += this.state.dataOportunity.id_property;
        }

        if (query!==""){
            axios.get(url + query , {})
                .then(res => {
                    if (res.data.status === 200) {
                        try {
                            let auxData = res.data.results;
                        if (!auxData.length&&auxData.info) auxData = [auxData.info];
                            auxData.forEach((item) => {
                                item.id = item.id_property;
                                item.selected = 0;    //siempre que busque se vaciara este elemento. )
                        })
                            this.setState({ dataProperties: auxData });
                        }
                        catch{
                            this.setState({ dataProperties: [] });
                        }
                    }
                });
        }
    }

    handleFilterChange = (event) => {
        let targetName = 'f_' + event.target.name;
        this.setState({
            [targetName]: (event.target.value>0?event.target.value:null)            
        });
        if (event.target.value && event.target.name == 'categoryId') {
            //remueve el filtro de subcategoria. 
            this.setState ({f_subcategoryId:null});
            categoriesService.getSubcategoriesOf(event.target.value)
                .then(results => {
                    this.setState({
                        c_subcategories: results,
                    }
                    );
                })
        }
    }

    handleTemplateChange = (event) => {
        //get data of the template. 

        let template = this.state.c_templates.find( x => x.templateId  == event.target.value);
        // go for it.. 
        if (template){
            let p_templates = emailTemplatesService.get(template.templateId);  //si hay muchas plantillas debeia acambiar la forma en que se cargan . 
            Promise.all([p_templates]).then(([d_template])=>{
console.log(d_template.body);
                this.setState({
                    s_templateId : d_template.templateId,
                    s_mailSubject : d_template.subject,
                    s_mailBody : loSanitize.unescape(d_template.body),
                });
            }).catch((e) => {
                this.setState({
                    s_template : null,
                    s_mailBody : null,

                });
            });
        }
        else
            this.setState({
                s_templateId : 0,
                s_mailSubject : '',
                s_mailBody : '',
            });

    }

    handleListingTemplateChange = (event) => {
        //get data of the template. 
        this.setState({
            s_listingTemplateId : event.target.value
        });
    }

    getContact = () =>{
        axios.get(`${configs.URL_API_BASE}/contacts/${this.state.dataOportunity.contactId}`, {})
        .then(res => {
            if (res.data.status === 200) {
                let auxData = res.data.results;

                this.setState({ 
                        dataContact: auxData,
                        s_contactId : auxData.contactId 
                    }
                );
            }
        });
    }

    updateStatus(event) {
        event.preventDefault();
        let url = `${configs.URL_API_BASE}/oportunities/${this.state.oportunityId}/edit`;
        let dataOk = true;
        const formData = new FormData();
        switch (this.state.show) {
            case 'user': 
                if (this.state.assignedToUserId < 0) dataOk = false; 
                formData.append('assignedToUserId', this.state.assignedToUserId); 
                this.setState({ isuploading: true });
                axios.put(url, formData, this.state.config).then((response) => {
                    this.setState({ isuploading: false });
                    this.handleClose();
                    if (parseInt(response.data.status) === 200) {
                        this.getData();
                        Swal.fire('Actualización Correcta', '', 'success');
                    } else {
                        Swal.fire('Error', 'Ocurrio un error inesperado.', 'warning');
                    }
                });
            break;
            case 'email':
                if ((this.state.s_listingTemplateId==0 && this.state.s_Properties.length>0) || this.state.s_templateId==0) {
                    Swal.fire('Listing', 'Requiere una plantilla', 'warning');
                    return;
                } 
                url = `${configs.URL_API_BASE}/oportunities/${this.state.oportunityId}/SendOportunityEmail`;
                //validate info.. 
                formData.append('contactId',this.state.s_contactId);
                formData.append('agentId',5);
                formData.append('mailTemplateId',this.state.s_templateId);
                formData.append('propertyId', this.state.s_Properties.map(x => x.id));
                formData.append('templateId',this.state.s_listingTemplateId);
                formData.append('mailSubject', this.state.s_mailSubject);
                formData.append('mailBody', loSanitize.escape(this.state.s_mailBody));
                this.setState({ isuploading: true });
                axios.post(url, formData, this.state.config).then((response) => {
                    this.setState({ isuploading: false });
                    this.handleClose();
                    if (parseInt(response.data.status) === 200) {
                        Swal.fire('Email Enviado!!!', '', 'success');
                        this.clearEmailModal();
                    } else {
                        Swal.fire('Error', 'Ocurrio un error inesperado.', 'warning');
                    }
                });
                break;
            default:
                this.handleClose();
                Swal.fire('Error', 'Ocurrio un error inesperado.', 'warning');
                
        }
    }

    clearEmailModal() {
        this.setState({
            s_mailSubject :null,
            s_mailBody:null,
            s_templateId:0,
            s_listingTemplateId:0
        })
    }

    handleClose() {
        this.setState({
            show: null,
        });
        this.clearEmailModal();
    }

    handleShow(modalName) {
         // Validemos.. 
        this.setState({
            show: modalName,
        });
        
    }
    /// Function to handle values. 

    onChangeInput = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onRowClicked = (record, index) =>{
        if (this.state.s_Properties.length>=10) {
            Swal.fire('Propiedades', 'Por el Momento solo puede elegir maximo 3 propiedades', 'warning');
            return;
        } 
        //que noxistasta.
        let allreadyIn = this.state.s_Properties.find(x => x.id == record.id);
        if (!allreadyIn){
            let initial = this.state.s_Properties;
            initial.push(record);
            this.setState({
                s_Properties :  initial
            })
        } 
    }

    onItemRemove = (event) =>{
        let allreadyIn = this.state.s_Properties.find(x => x.id == event.target.value);
        if (allreadyIn){
            let initial = this.state.s_Properties;
            initial.splice(allreadyIn,1);
            this.setState({
                s_Properties :  initial
            })
        }
    }

    closeLightbox = () => {
        // this.state.open = false;
        this.setState({ open: false })
    }

    openLigthBox = urlimage => {
        this.setState({ urlimageshow: urlimage })
        this.setState({ open: true })
    }
   
    render() {
        return (
            <>
             <div className="air__utils__heading">
                    <a href="/oportunities">Listado de oportunidades</a><span>{" "}/{" "}Editar oportunidad #{this.props.match.params.oportunityId}</span>
                </div>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <div className="air__utils__heading d-flex justify-content-between align-self-center float-left">
                        <h2>Oportunidad</h2>
                    </div>
                    <div className="d-flex justify-content-between align-self-center">
                       
                        <div className="p-2">
                            <button className="btn btn-info"  title="Send Email"onClick={() => this.handleShow('email')}>
                                <i className="fa fa-envelope-o"></i>  Enviar Email
                                        </button>
                        </div>
                        <div className="p-2">
                            <button className="btn btn-info"  title="Re-Assign User" onClick={() => this.handleShow('user')} >
                                <i className="fa fa-user-o"></i> Re/Asignar
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-sm-3 card card-info" >
                        <div className="text-center card-info">
                            <div className="card-body">
                                <a href="#o" title="Click para Editar">                        
                                    <img alt="" className="avatar img-circle img-thumbnail" width={'auto'} height={80} mode='fit' src='https://ssl.gstatic.com/accounts/ui/avatar_1x.png'/>
                                </a>  
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 card card-info">
                        <div className="card-info">
                            <h5 className="card-title"> {this.state.dataContact?this.state.dataContact.name:''}</h5>
                            <div className="text">  <i className="fa fa-envelope-o"></i> {this.state.dataContact?this.state.dataContact.email:''}</div>
                            <div className="text">  <i className="fa fa-phone"></i> {this.state.dataContact?this.state.dataContact.phone:''}</div>
                            <div className="text">  <i className="fa fa-mobile-phone"></i> {this.state.dataContact&&this.state.dataContact.cellPhone?this.state.dataContact.cellPhone:'NA'}</div>
                        </div>
                    </div>
                    <div className="col-sm-5 card card-info">
                        <div className="card-info">
                            <h5 className="card-title"> Detalles Rapidos</h5>
                            <div className="text"> Originado por el WEB</div>
                            <div className="text"> Asignado a : 
                                <span className="badge badge-primary">{this.state.dataOportunity&&this.state.dataOportunity.userName?this.state.dataOportunity.userName:'No Asignado'}  </span> 
                            </div>
                            <div className="text"> Me interesa : 
                                <span className="badge badge-primary"> {this.state.dataOportunity&&this.state.dataOportunity.property?this.state.dataOportunity.property:'Sistema->Aun no me decido'} </span> 
                            </div>
                            <div className="text"> Ultimo Mensaje: 
                                <span className="badge badge-secundary"> {this.state.dataOportunity&&this.state.dataOportunity.comment?this.state.dataOportunity.comment:'No exite mensaje'} </span> 
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="row">
                    <div className="col-sm-12 table-bordered">
                        <ReactBootstrap.Tabs defaultActiveKey="home" id="controlled-tab">
                            <ReactBootstrap.Tab eventKey="home" title="Buscar" className="tab-bordered">
                                <br/>
                                <div className="row ">
                                    <div className="col-6 col-md-6 col-lg-3 col-xl-3">
                                        <label> Categoría de {" "} <span className="d-inline"> propiedad </span> </label>
                                        <select name="categoryId" value={this.state.categoryId} onChange={this.handleFilterChange} className="form-control">
                                                <option value='0'> Seleccione Categoria </option>
                                            {this.state.c_categories.map((item, pos) => (<option key={pos} value={item.id_category}> {item.nombre} </option>))}
                                        </select>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-3 col-xl-3">
                                        <label> Subcategoría de{" "} <span className="d-inline"> propiedad</span>{" "}</label>
                                        <select name="subcategoryId" value={this.state.subcategoryId} onChange={this.handleFilterChange} className="form-control">
                                            <option value='0'> Todas las Subcategoria </option>
                                            {this.state.c_subcategories.map((item, pos) => (<option key={pos} value={item.idSubCategory}> {item.name} </option>))}
                                        </select>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-3 col-xl-2">
                                        <label>Estado</label>
                                        <select name="stateId" value={this.state.stateId} onChange={this.handleFilterChange}
                                            required className="form-control">
                                            <option  value=''> Selecciona Estado</option>
                                            {this.state.c_states.map((item, pos) => (<option key={pos} value={item.id_estado}>{item.estado}</option>))}
                                        </select>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-3 col-xl-2">
                                        <label> Moneda</label>
                                        <select name="currencyId" value={this.state.currencyId} onChange={this.handleFilterChange}
                                            className="form-control">
                                            <option  value='0'> Seleccione Moneda </option>
                                            {this.state.c_currencies.map((item, pos) => (<option key={pos} value={item.id_Currency}> {item.currencyCode} </option>))}
                                        </select>
                                    </div>
                                    <div className="col-6 col-md-12 col-lg-3 col-xl-2">
                                        <span><br/></span>
                                        <div className="btn btn-primary" onClick={()=>{this.getFilteredProperties()}}> Buscar </div>
                                    </div>
                                </div>                            
                                <br/>
                                <div id="propertiesContainer" className="row">
                                     {this.state.s_Properties.map((item,pos) => (
                                        <div className="col-4 " key={pos}>  
                                            <button className="form-control" onClick={this.onItemRemove} value={item.id}>
                                                <i className="fa fa-trash"></i>&nbsp;{item.name}
                                            </button>
                                        </div>
                                     ))}
                                </div>
                                <hr/>     
                                <div> 
                                    {
                                        <TablePropertiesSearch 
                                        onRowClicked={this.onRowClicked} 
                                        dataselected ={this.state.s_Properties}
                                        dataproperties={this.state.dataProperties?this.state.dataProperties:[]} 
                                        openLigthBox={ this.openLigthBox } ></TablePropertiesSearch> 
                                    }
                                    {this.state.open && (
                                        <Lightbox
                                            large={this.state.urlimageshow}
                                            alt=""
                                            onClose={this.closeLightbox}
                                        />
                                    )}
                                </div>
                                
                            </ReactBootstrap.Tab>
                            <ReactBootstrap.Tab eventKey="history" title="Linea de Tiempo">
                                {
                                    <TableTimeLine data={this.state.dataHistory} ></TableTimeLine>
                                }
                            </ReactBootstrap.Tab>
                        </ReactBootstrap.Tabs>
                    </div>
                </div>
                <Modal  appElement={document.getElementById("root")}
                    isOpen={this.state.show == 'user'}
                    onRequestClose={this.handleClose}
                    className="Modal">
                    <form onSubmit={this.updateStatus}>
                        <div className="modal-dialog">
                            <LoadingOverlay
                                active={this.state.isuploading}
                                spinner
                                text='Subiendo el contenido...'>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Asignar Usuario</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row content-modal-mobile">
                                            <div className="col-12">
                                                <div className="form-row ">
                                                    <div className="col-12 d-flex align-items-center bt-3">
                                                        <label className="form-check-label mr-3" htmlFor="priorizar">Seleccione el usuario a Asignar {this.state.dataOportunity ? this.state.dataOportunity.contact : ''}</label>
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="col-4" >Usuario ? </label>
                                                        <select name="assignedToUserId" autoFocus={true} className="col-8" onChange={this.onChangeInput}>
                                                            <option key="-1" value="-1">Seleccione usuario</option>
                                                            {
                                                                (this.state.dataUsers) ? this.state.dataUsers.map((item) => {
                                                                    let key = item.user_id;
                                                                    let value = item.name + ', ' + item.lastname;
                                                                    return <option key={key} value={key}>{value}</option> }) : ''
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-danger btn-lg" type="button" onClick={this.handleClose}>Cancelar</button>
                                        <button className="btn btn-success btn-lg" type="submit">Asignar</button>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </form>
                </Modal>
                <Modal  appElement={document.getElementById("root")}
                    isOpen={this.state.show=='email'}
                    onRequestClose={this.handleClose}
                    className="Modal">
                    <form onSubmit={this.updateStatus}>
                        <div className="modal-dialog">
                            <LoadingOverlay  active={this.state.isuploading} spinner text='Subiendo el contenido...'>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title">Enviar por email a email a {this.state.dataContact.name}</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row content-modal-mobile">
                                            <div className="col-12 col-md-12 col-lg-8 col-xl-8">
                                                <div className="form-row ">
                                                    <div className="col-4 col-md-6 col-lg-4 col-xl-4">
                                                        <label className="text-primary" >Plantilla:</label>
                                                    </div>
                                                    <div className="col-8 col-md-6 col-lg-8 col-xl-8">
                                                        <select name="templateId" value={this.state.templateId} onChange={this.handleTemplateChange} className="form-control">
                                                            <option  value='0'> Enviar Sin Plnatilla </option>
                                                            {this.state.c_templates.filter(el => el.type===1).map((item, pos) => (
                                                                    <option key={pos} value={item.templateId}> {item.name} </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div> <hr/>
                                                <div className="form-row ">
                                                    <div className="col-4">
                                                        <label className="text-primary" >A:</label>
                                                    </div>
                                                    <div className="col-8">{this.state.dataContact?this.state.dataContact.email:'Contacto Sin Email.. <corriga el contacto>'}
                                                    </div>
                                                </div>
                                                <div className="form-row ">
                                                    <div className="col-4">
                                                        <label className="text-primary" >Asunto:</label>
                                                    </div>
                                                    <div className="col-8">
                                                        <input className="form-control" onChange={this.onChangeInput} value={this.state.s_mailSubject?this.state.s_mailSubject:''} name="s_mailSubject"  type="text" />
                                                    </div>
                                                </div>
                                                <div className="form-row ">
                                                    <div className="col-12">
                                                        <label className="text-primary" >Mensaje:</label>
                                                    </div>
                                                    <div className="col-12">
                                                        <Editor
                                                            apiKey={configs.TINYMCE_API_KEY} 
                                                            value={this.state.s_mailBody}
                                                            init={{
                                                                height: 500,
                                                                paste_as_text: true,
                                                                images_upload_url: `${configs.URL_API_BASE}/uploadImages/image`,
                                                                automatic_uploads: true,
                                                                block_unsupported_drop: true,
                                                                menubar: false,
                                                                content_css : "https://getbootstrap.com/docs/4.5/dist/css/bootstrap.min.css",
                                                                menu: {
                                                                    edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall' },
                                                                    insert: { title: 'Insert', items: 'link image' },
                                                                    view: { title: 'View', items: 'visualaid' },
                                                                    format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat' }
                                                                },
                                                                plugins: "image,paste,code",
                                                                paste_data_images: true,
                                                                toolbar:
                                                                    'undo redo | formatselect | table link bold italic backcolor | \
                                                                    alignleft aligncenter alignright alignjustify | \
                                                                    bullist numlist outdent indent | removeformat | image code',
                                                                }}
                                                            onEditorChange={(content) => {
                                                                const data = content; 
                                                                this.setState({ s_mailBody: data });                                                                
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-4 col-xl-4 air__menuRight">
                                            <div className="form-row air__menuLeft__outer">
                                                    <div className="air__menuRight__mobileToggleButton air__menuLeft__mobileActionToggle"><span></span></div>
                                                    <div className="air__menuRight__toggleButton air__menuRight__actionToggle"><span></span><span></span></div>
                                                    <div className="col-12 air__menuRight__container air__customScroll">
                                                        <label className="text-primary" >Propiedades:</label>
                                                        <span>
                                                            {this.state.s_Properties.map((item, pos) => (<label key={pos}>{item.id_property},{item.name.substring(0,25)}... </label>))}
                                                        </span>

                                                        <select name="listingId" value={this.state.listingId} onChange={this.handleListingTemplateChange} className="form-control">
                                                            <option  value='0'> Seleccione Plantilla </option>
                                                            {this.state.c_templates.filter(el => el.type===4).map((item, pos) => (
                                                                    <option key={pos} value={item.templateId}> {item.name} </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-danger btn-lg" type="button" onClick={this.handleClose}>Cancelar</button>
                                        <button className="btn btn-success btn-lg" type="submit">Enviar Email</button>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </form>
                </Modal>
            </>
        )

    }

}