import React, { Component } from 'react'

import Switch from "react-switch";
import Modal from 'react-modal';
import axios, { post, put } from 'axios';
import Swal from 'sweetalert2';
import TableAmenities from './components/table';
import configs from './../../configs/config'
import LoadingOverlay from 'react-loading-overlay';

export default class Amenities extends Component {

    constructor() {
        super();
        this.state = {
            imagepreview: null,
            isuploading: false,
            checked: false,
            modalIsOpen: false,
            name_en: '',
            name_es: '',
            descriptionEn: '',
            descriptionEs: '',
            image: '',
            showsuccess: false,
            dataamenities: [],
            isEdit: false,
            idEdited: '',
        };
        this.getAmenitiesData();

    }

    getAmenitiesData = () => {
        axios.get(`${configs.URL_API_BASE}/amenities/getAmenities`, {})
            .then(res => {
                if (res.data.status === 200) {
                    let auxAmen = res.data.results;

                    auxAmen.forEach((item) => {
                        item.id = item.id_amenity;
                        item.status_val = (item.status === '1') ? 'Activo' : 'Inactivo';
                    });
                    this.setState({ dataamenities: auxAmen });

                    console.log(this.state.dataamenities);
                }
            });
    }

    delete = (id) => {
        axios.get(`${configs.URL_API_BASE}/amenities/${id}/delete`)
            .then(res => {
                console.log(res);
                if (parseInt(res.data.status) === 200) {
                    this.getAmenitiesData();
                    Swal.fire('Eliminado!', 'Elemento eliminado correctamente.', 'success');
                } else {
                    Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
                }
            }).catch(err => {
                console.log(err);
                Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
            });
    }

    activateAmenity = (checked) => {
        this.setState({ checked });
    }

    openModal = () => {
        this.setState({
            name_es: '',
            name_en: '',
            imagepreview: null,
            image: null,
            descriptionEs: '',
            descriptionEn: '',
        });
        this.setState({ isEdit: false });
        this.setState({ modalIsOpen: true });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    handleOpenEdit = (record) => {
        console.log(record);
        this.setState({
            name_es: record.name,
            name_en: record.name_en,
            descriptionEs: record.description,
            descriptionEn: record.description_en,
            idEdited: record.id_amenity,
            checked: record.status,
            imagepreview: `${configs.URL_IMAGESNEW}${record.iconImage}`,
            image: null,

        });

        this.setState({ isEdit: true });
        this.setState({ modalIsOpen: true });

    }

    handleAmenitiSubmit = event => {
        event.preventDefault();
        let statuscheck = '0'
        this.state.checked ? statuscheck = '1' : statuscheck = '0';

        const datatopost = {
            name_en: this.state.name_en,
            name_es: this.state.name_es,
            descriptionEn: this.state.descriptionEn,
            descriptionEs: this.state.descriptionEs,
            status: statuscheck,
            image: this.state.image,
            id: this.state.idEdited,
        };
        this.setState({ isuploading: true });
        this.postAmenitiMultipart(datatopost).then((response) => {
            if (parseInt(response.data.status) === 200) {
                this.setState({ isuploading: false });
                this.closeModal();
                this.getAmenitiesData();
                this.state.isEdit ? Swal.fire('Edición Correcta', 'Amenidad editada correctamente.', 'success') : Swal.fire('Ingreso Correcto', 'Amenidad ingresada correctamente.', 'success');
            } else {
                this.setState({ isuploading: false });
                this.closeModal();
                Swal.fire('Error', 'Error al subir la amenidad.', 'warning')
            }
        });
    };

    postAmenitiMultipart(data) {
        const file = data.image;
        let url = ``;
        this.state.isEdit ? url = `${configs.URL_API_BASE}/amenities/${data.id}/edit` : url = `${configs.URL_API_BASE}/amenities/createAmenity`;
        const formData = new FormData();
        formData.append('image', file);
        formData.append('name_en', data.name_en);
        formData.append('name_es', data.name_es);
        formData.append('descriptionEn', data.descriptionEn);
        formData.append('descriptionEs', data.descriptionEs);
        formData.append('status', data.status);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        if (this.state.isEdit) {
            return put(url, formData, config)
        } else { return post(url, formData, config) }

    }

    handleChangeInput = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onChangeFile = e => {

        this.setState({
            image: e.target.files[0],
            imagepreview: URL.createObjectURL(e.target.files[0])
        })
    }

    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Amenidades</h2>
                    <a href="#o" className="btn btn-primary d-flex justify-content-between align-self-center" onClick={this.openModal}>
                        <span>Nueva Amenidad &nbsp;</span>
                        <span>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                        </span>
                    </a>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        <TableAmenities handleOpenEdit={this.handleOpenEdit} delete={this.delete} dataamenities={this.state.dataamenities}></TableAmenities>
                    </div>
                </div>

                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    className="Modal"

                    contentLabel={"Crear Amenidad"}
                >
                    <form onSubmit={this.handleAmenitiSubmit}>
                        <div className="modal-dialog">
                            <LoadingOverlay
                                active={this.state.isuploading}
                                spinner
                                text='Subiendo el contenido...'
                            >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{this.state.isEdit ? "Editar Amenidad" : "Crear Amenidad"}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row content-modal-mobile my-0 ">
                                            <div className="col-12">
                                                <div className="form-row ">

                                                    <div className="col-12 d-flex align-items-center">
                                                        <label className="form-check-label mr-3" htmlFor="priorizar">Activar esta Amenidad?</label>
                                                        <Switch onChange={this.activateAmenity} checked={this.state.checked} />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 my-3">
                                                        <label>Nombre de la Amenidad <span
                                                            className="text-red">*</span></label>
                                                        <input name="name_es" autoFocus={true} type="text" className="form-control" value={this.state.name_es} onChange={this.handleChangeInput} required />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 my-3">
                                                        <label>Amenity Name <span
                                                            className="text-red">*</span></label>
                                                        <input name="name_en" type="text" className="form-control" value={this.state.name_en} onChange={this.handleChangeInput} required />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 my-3">
                                                        <label>Descripción de la Amenidad <span
                                                            className="text-red">*</span></label>
                                                        <input name="descriptionEs" type="text" className="form-control" value={this.state.descriptionEs} onChange={this.handleChangeInput} required />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 my-3">
                                                        <label>Amenity Description <span
                                                            className="text-red">*</span></label>
                                                        <input name="descriptionEn" type="text" className="form-control" value={this.state.descriptionEn} onChange={this.handleChangeInput} required />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 my-3">
                                                        <p>Imagen de la Amenidad (64x64)<span className="text-red">*</span>
                                                        </p>
                                                        <input name="image" type="file" onChange={this.onChangeFile} id="img" />
                                                        <img alt=""
                                                            width={'auto'} height={180} mode='fit'
                                                            src={this.state.imagepreview} />

                                                    </div>
                                                    <div className="d-flex justify-content-around col-12">
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-danger btn-lg" type="button" onClick={this.closeModal}>CANCELAR</button>
                                        <button className="btn btn-success btn-lg" type="submit">GUARDAR</button>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </form>
                </Modal>
            </>
        )
    }
}
