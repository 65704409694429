export default class Contact {

    constructor(entity = {}){
        this.id = entity.contactId || null;
        this.name = entity.name || '';
        this.phone = entity.phone || '';
        this.cellPhone = entity.cellPhone || '';
        this.email = entity.email || '';

        this.country = entity.country || '';
        this.city = entity.city || '';
        this.company = entity.company || '';
        this.companyRol = entity.companyRol || '';
        this.birthday = entity.birthday || '';

        this.birthDay = (entity.birthday ? entity.birthday.split('/')[1] : '' );
        this.birthMonth = (entity.birthday ? entity.birthday.split('/')[0] : '');
        
        this.source = entity.source || '';
        this.interests = entity.interests || '';
        this.address = entity.address || '';

        this.isFavorite= entity.isFavorite || '';
        this.imageUrl = entity.imageUrl || '';
    }
}