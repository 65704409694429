import React, { Component, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import Swal from 'sweetalert2';

export default class TableCampaigns extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "campaignName2",
                text: "Nombre",
                className: "name",
                cell: record => {
                    return (
                        <Fragment>
                             <img src="//via.placeholder.com/60x100">
                             </img>
                        </Fragment>
                    );
                }
            },
            {
                key: "campaignName",
                text: "Nombre",
                className: "name",
                cell: record => {
                    return (
                        <Fragment>
                             <p className="h5"> {record.campaignName}</p>
                              Created on : {record.createdOn.split('T')[0]}
                              {
                                record.sendingDate && 
                                <p> Enviado:  {record.sendingDate.split('T')[0]}</p>
                              }
                              {
                                record.sendingDate && 
                                <p> Alcance :  {record.totalSent}</p>
                              }
                        </Fragment>
                    );
                }
            },
            {
                key: "campaignStatusDescription",
                text: "Status",
                className: "status",
                cell: record => {
                    return (
                        <Fragment>
                            {   record.campaignStatus<5 &&
                             <div className="alert alert-primary" role="alert">{record.campaignStatusDescription}</div>
                            }
                           
                              {  record.campaignStatus>4 &&
                            <p>Destinatarios : {record.totalEmails}</p>
                              }
                    </Fragment>
                    );
                }
            },
            {
                key: "listas. ",
                text: "Listas",
                className: "status",
                cell: record => {
                    return (
                        <Fragment>
                            {  !record.campaignEmailListId &&
                               <div className="alert alert-danger" role="alert">Sin Lista Asignada</div>
                            }
                             {  record.listName &&
                                <div >{record.listName}</div>
                            }
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Acciones",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                             {
                                 record.sendingDate &&
                                 <button  className="btn btn-primary btn-sm"
                                 onClick={() => this.reportRecord(record)}
                                 style={{ marginRight: '5px' }}>
                                 <i className="fa fa-graph">Report</i>
                                </button>
                             }
                             {
                                 !record.sendingDate &&
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.editRecord(record)}
                                    style={{ marginRight: '5px' }}>
                                    <i className="fa fa-edit"></i>
                                    
                                </button>
                            }
                            {
                                !record.sendingDate &&
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => this.deleteRecord(record)}>
                                    <i className="fa fa-trash"></i>
                                </button>
                            }
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {excel: true}
        }

        this.state = {
            records: this.props.data
        }
    }

    componentWillReceiveProps = (next_props) => {
        this.setState({records: next_props.data});
    }

    editRecord = (record) => {
       // console.log("Edit Record", record);
        this.props.handleOpenEdit(record);
    }

    reportRecord = (record) => {
        //console.log("Edit Record", record);
        this.props.handleOpenReport(record);
    }


    deleteRecord = (record) => {
      // console.log("Delete Record", record);
        Swal.fire({
            title: '¿Esta seguro de eliminar la campaña seleccionada?',
            text: "Una vez eliminada no podrá ser recuperado.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c666f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.props.delete(record.campaignId);
            }
        });
    }
    
    render() {
        return (
            <div>
                <ReactDatatable
                    config={this.config}
                    records={this.state.records}
                    columns={this.columns}
                />
            </div>
        )
    }
}
