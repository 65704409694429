import React, { Component, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import Swal from 'sweetalert2';

//http://react-datatable.in/server-side-data.html


export default class TablePages extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "pageName",
                text: "Nombre",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "title",
                text: "Titulo",
                className: "desc",
                align: "left",
                sortable: true,
            },
            {
                key: "pageUrl",
                text: "Url Friendly",
                className: "status",
                align: "left",
                sortable: true,
            },
            {
                key: "action",
                text: "Acciones",
                className: "action",
                width: 120,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                          { <button title="Editar Página" className="MuiButtonBase-root MuiIconButton-root"
                                onClick={() =>  this.editRecord(record)} >
                                <i className="fa fa-edit"></i>
                             </button> }
                            <button
                                title="Eliminar Página"
                                className="MuiButtonBase-root MuiIconButton-root"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>

                        </Fragment>
                    );
                }
            }
        ];
       
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            button: {excel: true},
            language: {
                length_menu: "Mostrar _MENU_ registros por página",
                filter: "Buscar en registros ...",
                info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
                pagination: {
                    first: "Primero",
                    previous: "Previa",
                    next: "próximo",
                    last: "Última"
                }
            }
        }

        this.state = {
            records: this.props.data,
        }
    }

    componentWillReceiveProps = (next_props) => {
        this.setState({records: next_props.data});
    }

    editRecord = (record) => {
        //console.log("Edit Record", record);
        this.props.handleOpenEdit(record);
    }

    deleteRecord = (record) => {
        console.log("Delete Record", record);
        Swal.fire({
            title: '¿Esta seguro de eliminar la página seleccionada?',
            text: "Una vez eliminada no podrá ser recuperado.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c666f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                this.props.delete(record.pageId);
            }
        });
    }

    render() {
        return (
            <Fragment>
                <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}/>
            </Fragment>
        )
    }
}
