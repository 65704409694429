import React, { Component } from 'react';
import Property from '../../../models/property'
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import Switch from "react-switch"
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {RULE_FUNCTIONS, Validator} from '../../../utilities/form-validation';

import { propertiesService, propertyTypesService, placesService, categoriesService, currenciesService } from '../../../services/tibesa.api'
import "react-tabs/style/react-tabs.css"
import classNames from 'classnames';
import Swal from 'sweetalert2'

// se hizo este formulario como parche a que no permitia guardar de forma rapida aunque en la api valida los datos se pusieron datos dummy. 



const TradeTypes = ["Undefined","Renta", "Venta", "Remate Bancario"];

export default class PropertyNew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...(props.property || new Property()),
            c_subcategories: [],
            c_types: [],
            c_currencies: [],
            c_categories: [],
            errors: []
        };

        this.state.propertyTitle = this.state.es_name || 'Crear nueva propiedad';
        this.state.serverGallery = this.state.galleryThumbs;
        this.state.editMode = this.state.id != null;

        this.submitCallback = this.props.onSubmit;

// validate data for new values. 
        let validationRules =
        [
            {
                fields:['es_name', 'en_name'],
                rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 200)
            },
            {
                fields:['es_mainDescription', 'en_mainDescription'],
                rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 5000)
            },
            {
                fields:['price', 'currencyId', 'typeId', 'categoryId', 'subcategoryId'],
                rule: v => RULE_FUNCTIONS.minValue(v, 1)
            },

        
        ]
        this.validation = new Validator(validationRules);

    }

    componentDidMount() {
        this.getCatalogs();
    }

    getCatalogs() {
        let p_types = propertyTypesService.getAll(),
            p_currencies = currenciesService.getAll(),
            p_categories = categoriesService.getAll()

        let p_subcategories = p_categories.then(c_categories => {
            if (c_categories && c_categories.length > 0) {
                return categoriesService.getSubcategoriesOf(this.state.categoryId || c_categories[0].id_category);
            }
            else {
                return Promise.resolve([]);
            }
        });


        Promise.all([p_types, p_currencies, p_categories, p_subcategories])
            .then(([c_types, c_currencies, c_categories, c_subcategories]) => {

                this.setState({
                    c_types,
                    c_currencies,
                    c_categories,
                    c_subcategories: c_subcategories.filter(s => s.status == 1 || (this.state.editMode && s.idSubCategory == this.state.subcategoryId)),
                    categoryId: this.state.categoryId || c_categories[0].id_category,
                    subcategoryId: this.state.subcategoryId || c_subcategories[0].idSubCategory,
                });
            });

    }

    investmentPossibilityCheck = () => {
        this.setState({ investmentPossibility: !this.state.investmentPossibility });
    }

    validateInput = (name, value) => {
        let validationResult = this.validation.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) === -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    handleChange = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
        this.validateInput(name, value);
        if (value && name == 'stateId') {
            placesService.getCitiesOfState(value)
                .then(results => {
                    this.setState({
                        c_cities: results,
                        cityId: (results[0] || {}).id_municipio
                    });
                });
        }
        else if (value && name == 'categoryId') {
            categoriesService.getSubcategoriesOf(value)
                .then(results => {
                    this.setState({
                        c_subcategories: results,
                        subcategoryId: (results[0] || {}).idSubCategory
                    },
                        () => {
                            this.validateInput('subcategoryId', this.state.subcategoryId);
                        }
                    );
                })
        }
    }

    handleSwitches = (value, _, id) => {
        this.setState({ [id]: value });
    }

    handleCheckAmenity = (event) => {
        let amenities = this.state.c_amenities
        amenities.forEach(amenity => {
            if (amenity.value === event.target.value)
                amenity.isChecked = event.target.checked
        })
        this.setState({ c_amenities: amenities });
    }

    handleChangePdf = (files, name) => {
        if (files.length > 0) {
            if (this.validateInput('pdf', files[0])) {
                this.setState({
                    [name]: files[0],
                    [`${name}Url`]: files[0].name
                });
            }
            else {
                Swal.fire('Archivo muy grande!', 'el tamaño máximo es 5 MB', 'error');
            }
        }
    }

    handleChangeLogo = (files) => {
        if (files.length > 0) {
            console.log(files[0]);
            if (this.validateInput('logo', files[0])) {
                this.setState({
                    logo: files[0],
                    logoPreview: URL.createObjectURL(files[0])
                });
            }
            else {
                Swal.fire('Imagen muy grande!', 'el tamaño máximo es 1.5 MB', 'error');
            }

        }
    }

    handleImageDrop = (pictureFiles, pictureDataURLs) => {
        this.setState({
            localGallery: pictureFiles,
            galleryThumbs: pictureDataURLs
        });
    }

    handleSubmit = (event) => {
        console.log(event.target.name);
        event.preventDefault();

        let errors = this.validation.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            Swal.fire('Error!', `cuentas con ${errors.length} error(es) en el formulario, por favor revisa los campos en rojo en todas las pestañas`, 'error');
            return false;
        }

        let formData = new FormData();

        formData.append("name_en", this.state.en_name);
        formData.append("name_es", this.state.es_name);

        formData.append("property_type", this.state.typeId);
        formData.append("categoryId", this.state.categoryId);
        formData.append("idSubCategory", this.state.subcategoryId);
        formData.append("price", this.state.price);
        formData.append("currencyCode", this.state.currencyId);

//TODO Debido a que aqui marca eeror por el contenido lo transformaremos a Base64 y de ahi la API que lo vuelva html. 
        let dataHtml = new Buffer.from( this.state.en_mainDescription,'utf-8').toString("base64");
        formData.append("descriptionEN", dataHtml);
        let dataHtml2 = new Buffer.from( this.state.es_mainDescription,'utf-8').toString("base64");
        formData.append("descriptionEs", dataHtml2);
    
        formData.append("prioridad", this.state.isHighlighted);
        formData.append("status",  1);

//por error de diseño ocupo mandar todos los datos. 


formData.append("totalsize", 0);
formData.append("front", 0);
formData.append("background", 0);
formData.append("fieldForm", 0);
formData.append("isInvertionC", 0);
formData.append("habsize", 0);
formData.append("bedrooms", 0);
formData.append("bathrooms", 0);
formData.append("levels", 0);
formData.append("tradeType", 0);
formData.append("latitud", 0);
formData.append("longitud", 0);
formData.append("stateId", 0);
formData.append("city", 0);
formData.append("cp",'0');
formData.append("address", " ");
formData.append("alte", " ");
formData.append("keywordsEN"," ");
formData.append("metaEn", " ");
formData.append("slugEN", " ");
formData.append("pdfUrli", " ");
//var mapIds = "";  //debera concatenar todos los combos. 
//if (this.state.vivaMapCode>0) mapIds += this.state.vivaMapCode; 
//if (mapIds) formData.append("mapIds",mapIds);
//if (this.state.en_pdf) formData.append("pdfi", this.state.en_pdf);
formData.append("rentTermsEN", "");
formData.append("petsTermsEN", "");
formData.append("alt", " ");
formData.append("keywords", " ");
formData.append("meta", " ");
formData.append("slug", " ");
formData.append("pdfUrl", "");
//if (this.state.es_pdf) formData.append("pdf", this.state.es_pdf);
formData.append("rentTerms", "");
formData.append("petsTerms", "");
//if (this.state.logo) { formData.append('pic_0', this.state.logo) };

//if (this.state.localGallery.length > 0) { this.state.localGallery.forEach((image, i) => formData.append(`pic_${i + 1}`, image)); };

//let imagesToDelete = this.state.serverGallery
//    .filter(i => this.state.galleryThumbs.indexOf(i) === -1)
//    .map(i => i.split('/').pop())
//    .join('@');

//if (imagesToDelete) {
//    formData.append(`imagestodelete`, imagesToDelete);
//}

//let amenities = this.state.c_amenities.filter(a => a.isChecked).map(a => a.id).join(',');
formData.append("amenities", "");

//if (this.state.investmentPossibility) {
//    formData.append("isInvertionTEn", this.state.en_investmentTerms)
//    formData.append("isInvertionT", this.state.es_investmentTerms);
//}

formData.append("videoUrl", "");

//if(this.state.virtualTourKey){
//    formData.append("virtualTourKey", this.state.virtualTourKey);
//    formData.append("virtualTourType", this.state.virtualTourType);
//}

        if (typeof this.submitCallback === "function") {
            this.submitCallback(formData);
        }
    }

    hasError = (name) => {
        return this.state.errors.indexOf(name) !== -1;
    }

    render() {

        let investmentDescriptionRow;

        if (this.state.investmentPossibility) {
            investmentDescriptionRow = <div className="col-12 col-lg-6 col-xl-6">
                <div className="form-group">
                    <Tabs>
                        <TabList>
                            <Tab>Términos de la inversión (Español)</Tab>
                            <Tab>Términos de la inversión (Inglés)</Tab>
                        </TabList>
                        <TabPanel>
                            <textarea name="es_investmentTerms" value={this.state.es_investmentTerms} onChange={this.handleChange}
                                className="form-control" placeholder="Términos de la inversión (Español)" rows="3"></textarea>
                        </TabPanel>
                        <TabPanel>
                            <textarea name="en_investmentTerms" value={this.state.en_investmentTerms} onChange={this.handleChange}
                                className="form-control" placeholder="Términos de la inversión (Inglés)" rows="3"></textarea>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>

        }
        else {
            investmentDescriptionRow = '';
        }

        return (
            <form className="property-form" onSubmit={this.handleSubmit} autoComplete="off">
        
                <div className="row">
                    <div className="col-12">
                      
                                <div className="row">
                                    <div className="col-12">
                                        <hr />
                                        <h3>{this.state.propertyTitle}</h3>
                                        <hr />
                                        <h4>Información básica</h4>
                                    </div>
                                </div>
                                <div className="row pt-3">
                                    <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                                        <div className="form-group row align-items-center">
                                            <label className="col-2"> Nombre:{" "}<span className="text-red">*</span></label>
                                            <div className="col-10">
                                                <Tabs>
                                                    <TabList>
                                                        <Tab><small>Español</small></Tab>
                                                        <Tab><small>Inglés</small></Tab>
                                                    </TabList>
                                                    <TabPanel>
                                                        <input name="es_name" type="text" placeholder="Nombre de la propiedad (ES)"
                                                            value={this.state.es_name} onChange={this.handleChange}
                                                            className={classNames('form-control', { 'is-invalid': this.hasError('es_name') })} />
                                                        <div className="invalid-feedback">Nombre de la propiedad (ES) requerido - máximo 200 caracteres.</div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <input name="en_name" type="text" placeholder="Nombre de la propiedad (EN)"
                                                            value={this.state.en_name} onChange={this.handleChange}
                                                            className={classNames('form-control', { 'is-invalid': this.hasError('en_name') })} />
                                                        <div className="invalid-feedback">Nombre de la propiedad (EN) requerido - máximo 200 caracteres.</div>
                                                    </TabPanel>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-2 col-lg-2 col-xl-3">
                                        <div className="form-group">
                                            <label> Precio:{" "}<span className="text-red">*</span></label>
                                            <input name="price" type="number" placeholder="####"
                                                value={this.state.price} onChange={this.handleChange}
                                                className={classNames('form-control', { 'is-invalid': this.hasError('price') })} />
                                            <div className="invalid-feedback">Precio debe ser mayor a 0.</div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-2 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label> Moneda{" "} <span className="text-red">*</span></label>
                                            <select name="currencyId" value={this.state.currencyId} onChange={this.handleChange}
                                                className={classNames('form-control', { 'is-invalid': this.hasError('currencyId') })}>
                                                <option value="" disabled> Moneda </option>
                                                {this.state.c_currencies.map((item, pos) => (<option key={pos} value={item.id_Currency}> {item.currencyCode} </option>))}
                                            </select>
                                            <div className="invalid-feedback">Selecciona moneda.</div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label> Tipo de  Propiedad{" "} <span className="text-red">*</span></label>
                                            <select name="typeId" value={this.state.typeId} onChange={this.handleChange}
                                                className="form-control">
                                                {this.state.c_types.map((item, pos) => (<option key={pos} value={item.id_propertyType}> {item.name} </option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label> Categoría de {" "} <span className="d-inline"> prop. </span> <span className="text-red"> * </span> </label>
                                            <select name="categoryId" value={this.state.categoryId} onChange={this.handleChange}
                                                className="form-control">
                                                {this.state.c_categories.map((item, pos) => (<option key={pos} value={item.id_category}> {item.nombre} </option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label> Subcategoría de{" "} <span className="d-inline"> prop.</span>{" "}<span className="text-red">*</span></label>
                                            <select name="subcategoryId" value={this.state.subcategoryId} onChange={this.handleChange}
                                                className={classNames('form-control', { 'is-invalid': this.hasError('subcategoryId') })}>
                                                {this.state.c_subcategories.map((item, pos) => (<option key={pos} value={item.idSubCategory}> {item.name} </option>))}
                                            </select>
                                            <div className="invalid-feedback">Selecciona subcategoría.</div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="custom-control d-flex align-items-center pl-0">
                                            <label className="form-check-label mr-3" htmlFor="priorizar">Propiedad destacada:</label>
                                            <Switch onChange={this.handleSwitches} checked={this.state.isHighlighted} id="isHighlighted" />
                                        </div>
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        {
                                            this.state.editMode ?
                                                <div className="custom-control d-flex align-items-center pl-0">
                                                    <label className="form-check-label mr-3" htmlFor="priorizar">Propiedad activa:</label>
                                                    <Switch onChange={this.handleSwitches} checked={this.state.isActive} id="isActive" />
                                                </div>
                                                : ''

                                        }
                                    </div>
                                    <div className="col-6 col-lg-4 col-xl-4">
                                        <div className="form-group">
                                            <label> Propiedad En ?</label>
                                            <select name="tradeType" value={this.state.tradeType} onChange={this.handleChange}
                                                className="form-control">
                                                {TradeTypes.map((item, pos) => (<option key={pos} value={pos}> {item} </option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 pt-4">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Descripción (Español)<span className="text-red">*</span></Tab>
                                                <Tab>Descripción (Inglés)<span className="text-red">*</span></Tab>
                                            </TabList>
                                            <TabPanel>
                                                <div className={classNames({ 'is-invalid': this.hasError('es_mainDescription') })}>
                                                    <CKEditor editor={ClassicEditor} data={this.state.es_mainDescription} onChange={(_, editor) => {
                                                        const data = editor.getData();
                                                        this.setState({ es_mainDescription: data });
                                                        this.validateInput('es_mainDescription', data);
                                                    }} />
                                                </div>
                                                <div className="invalid-feedback">Descripción (Español) es requerida - máximo 5000 caracteres.</div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className={classNames({ 'is-invalid': this.hasError('en_mainDescription') })}>
                                                    <CKEditor editor={ClassicEditor} data={this.state.en_mainDescription} onChange={(_, editor) => {
                                                        const data = editor.getData();
                                                        this.setState({ en_mainDescription: data });
                                                        this.validateInput('en_mainDescription', data);
                                                    }} />
                                                </div>
                                                <div className="invalid-feedback">Descripción (Inglés) es requerida - máximo 5000 caracteres.</div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                           
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-12">
                        <hr />
                        <button type="button" className="btn float-right" onClick={() => window.location.href = '/properties'}>CANCELAR</button>
                        <button type="submit" className="btn btn-secondary float-right">GUARDAR</button>
                    </div>
                </div>
            </form>
        )
    }
}