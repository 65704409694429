/* const dev_config = { 
    URL_API_BASE:'https://tibesa-api.azurewebsites.net',
    URL_IMAGES:'https://tibesa-api.azurewebsites.net/images/',
    URL_IMAGESNEW:'https://tibesa-api.azurewebsites.net/images/'
}; */

const dev_config = { 
    URL_API_BASE:'https://api.bienesraicestibesa.mx',  //    'http://localhost:3000',  //:      'http://localhost:3000',  //:   
    URL_IMAGES:'https://api.bienesraicestibesa.mx/images/',
    URL_IMAGESNEW:'https://api.bienesraicestibesa.mx/images/',
    TINYMCE_API_KEY:'0w98xg7mahbbcz3m4j5enkth046tlxzgrbk7ujg79tkp0wt3'

};

export default dev_config;