import React, { Component } from 'react';

import logo from '../../whitelogo.png';
import configs from "./../../configs/config"
import $ from  'jquery';

export default class LeftNav extends Component {

    constructor() {
        super();
        this.state = {
            user_name: '',
            user_type: '',
            userlogo: '',
            userRoles: [
                { id: 1, text: 'Administrador' },
                { id: 2, text: 'Editor' },
                { id: 3, text: 'Capturista' },
                { id: 4, text: 'Temporal' }
            ]
        }
    }

    componentDidMount = () => {
        this.getUserData();
    }

    getUserData = () => {
        const urlprofileimage=`${configs.URL_IMAGESNEW}`;
        this.setState(
            {
                user_name: window.localStorage.getItem('user_name'),
                user_type: this.getUserTypeString(window.localStorage.getItem('user_type')),
                userlogo: urlprofileimage+window.localStorage.getItem('userlogo'),
                openMarketing:false,
                openAdmin:false,
                openCatalogs:false,

            }
        );
    }


    getUserTypeString = (level) => {
        let textRol;
        // eslint-disable-next-line array-callback-return
        this.state.userRoles.filter((rol) => {
            if (rol.id === parseInt(level)) {
                textRol = rol.text;
            }
        });
        return textRol;
    }

    pressDesk = () =>{
        $('body').toggleClass('air__menu--toggled');
    }
    openMenu = (event) => {
        let { name } = event.target;
        this.setState({ [name]: !this.state[name] });
    }
    pressMobile = ()=>{
        $('body').toggleClass('air__menu--mobileToggled');
    }

    render() {
        return (
            <>
                {/* //////////// MENU LEFT */}
                <div className="air__menuLeft">
                    <div className="air__menuLeft__outer">
                        <div className="air__menuLeft__mobileToggleButton air__menuLeft__mobileActionToggle" onClick={this.pressMobile}>
                            <span></span>
                        </div>
                        <div className="air__menuLeft__toggleButton air__menuLeft__actionToggle" onClick={this.pressDesk}>
                            <span></span>
                            <span></span>
                        </div>
                        <a href="/" className="air__menuLeft__logo">
                            <div className="air__menuLeft__logo__name">
                                <img src={logo} className="logo" alt="Tibesa Realty" />
                            </div>
                        </a>
                        <a href="/" className="air__menuLeft__user">
                            <div className="air__menuLeft__user__avatar">
                                <img src={this.state.userlogo} width={'45'} height={'auto'}  alt={this.state.user_name} />
                            </div>
                            <div className="air__menuLeft__user__name">
                                {this.state.user_name}
                            </div>
                            <div className="air__menuLeft__user__role">
                                {this.state.user_type}
                            </div>
                        </a>
                        <div className="air__menuLeft__container air__customScroll">
                            <ul className="air__menuLeft__list">
                                <li className="air__menuLeft__item">
                                    <a href="/" className="air__menuLeft__link">
                                        <i className="fa fa-dashboard mr-2" aria-hidden="true"></i>
                                        <span>Panel de Control</span>
                                    </a>
                                </li>
                                <li className="air__menuLeft__item">
                                    <a href="/oportunities" className="air__menuLeft__link">
                                        <i className="fa fa-bookmark-o mr-2" aria-hidden="true"></i>
                                        <span>Oportunidades</span>
                                    </a>
                                </li>
                                <li className="air__menuLeft__item">
                                    <a href="/contacts" className="air__menuLeft__link">
                                        <i className="fa fa-address-card-o mr-2" aria-hidden="true"></i>
                                        <span>Contactos</span>
                                    </a>
                                </li>

                                <li className="air__menuLeft__item">
                                    <a href="/properties" className="air__menuLeft__link">
                                        <i className="fa fa-building-o mr-2" aria-hidden="true"></i>
                                        <span>Propiedades</span>
                                    </a>
                                </li>
                                <li className="air__menuLeft__item">
                                    <a href="/videos" className="air__menuLeft__link">
                                        <i className="fa fa-video-camera mr-2" aria-hidden="true"></i>
                                        <span>Videos</span>
                                    </a>
                                </li>
                                <li className="air__menuLeft__item">
                                    <a href="/contact-form" className="air__menuLeft__link">
                                        <i className="fa fa-comment-o mr-2" aria-hidden="true"></i>
                                        <span>Mensajes del Portal</span>
                                    </a>
                                </li>
                                <li className="air__menuLeft__item">
                                    <a href="/news" className="air__menuLeft__link">
                                        <i className="fa fa-newspaper-o mr-2" aria-hidden="true"></i>
                                        <span>Noticias</span>
                                    </a>
                                </li>

                                <li className="air__menuLeft__item air__menuLeft__submenu">
                                    <a href="#o" className="air__menuLeft__link" onClick={this.openMenu} name="openMarketing">
                                        <i className="fa fa-copyright mr-2" aria-hidden="true"></i>
                                        <span>Marketing</span>
                                    </a>
                                    { this.state.openMarketing &&  <ul className="air__menuLeft__" >
                                            <li className=" air__menuLeft__item">
                                                <a href="/landingpages" className="air__menuLeft__link">
                                                    <i className="fa fa-paste mr-2" aria-hidden="true"></i>
                                                    <span>Pagina de Aterrizaje</span>
                                                </a>
                                            </li>
                                            <li className=" air__menuLeft__item">
                                                <a href="/campaigns" className="air__menuLeft__link">
                                                    <i className="fa fa-copyright mr-2" aria-hidden="true"></i>
                                                    <span>Campañas</span>
                                                </a>
                                            </li>
                                            <li className="air__menuLeft__item">
                                                <a href="/emailLists" className="air__menuLeft__link">
                                                    <i className="fa fa-files-o mr-2" aria-hidden="true"></i>
                                                    <span>Listas</span>
                                                </a>
                                            </li>
                                        </ul> 
                                    }
                                </li>

                                <li className="air__menuLeft__item air__menuLeft__submenu">
                                    <a href="#o" className="air__menuLeft__link"  onClick={this.openMenu} name="openAdmin">
                                        <i className="fa fa-copyright mr-2" aria-hidden="true"></i>
                                        <span>Administración</span>
                                    </a>
                                    {
                                    this.state.openAdmin && 
                                        <ul className="air__menuLeft__">

                                            <li className="air__menuLeft__item">
                                                <a href="/companies" className="air__menuLeft__link">
                                                    <i className="fa fa-copyright mr-2" aria-hidden="true"></i>
                                                    <span>Empresas</span>
                                                </a>
                                            </li>
                                            <li className="air__menuLeft__item">
                                                <a href="/users" className="air__menuLeft__link">
                                                    <i className="fa fa-user-o mr-2" aria-hidden="true"></i>
                                                    <span>Usuarios</span>
                                                </a>
                                            </li>
                                            <li className="air__menuLeft__item">
                                                <a href="/pages" className="air__menuLeft__link">
                                                    <i className="fa fa-files-o mr-2" aria-hidden="true"></i>
                                                    <span>Páginas</span>
                                                </a>
                                            </li>

                                            <li className="air__menuLeft__item">
                                                <a href="/email-templates" className="air__menuLeft__link">
                                                    <i className="fa fa-envelope-o mr-2" aria-hidden="true"></i>
                                                    <span>Plantillas de Correo</span>
                                                </a>
                                            </li>
                                        </ul>
                                    }
                                </li>

                                <li className="air__menuLeft__item air__menuLeft__submenu">
                                    <a href="#o" className="air__menuLeft__link"  onClick={this.openMenu} name="openCatalogs">
                                        <i className="fa fa-copyright mr-2" aria-hidden="true"></i>
                                        <span>Catálogos</span>
                                    </a>
                                    { this.state.openCatalogs && 
                                        <ul className="air__menuLeft__"> 
                                            <li className="air__menuLeft__item">
                                                <a href="/amenities" className="air__menuLeft__link">
                                                    <i className="fa fa-bars mr-2" aria-hidden="true"></i>
                                                    <span>Amenidades</span>
                                                </a>
                                            </li>
                                            <li className="air__menuLeft__item">
                                                <a href="/categories" className="air__menuLeft__link">
                                                    <i className="fa fa-cubes mr-2" aria-hidden="true"></i>
                                                    <span>Categorias</span>
                                                </a>
                                            </li>
                                            <li className="air__menuLeft__item">
                                                <a href="/subcategories" className="air__menuLeft__link">
                                                    <i className="fa fa-cube mr-2" aria-hidden="true"></i>
                                                    <span>Subcategorias</span>
                                                </a>
                                            </li>
                                            <li className="air__menuLeft__item">
                                                <a href="/currencies" className="air__menuLeft__link">
                                                    <i className="fa fa-money mr-2" aria-hidden="true"></i>
                                                    <span>Monedas</span>
                                                </a>
                                            </li>
                                            <li className="air__menuLeft__item">
                                                <a href="/property-types" className="air__menuLeft__link">
                                                    <i className="fa fa-building mr-2" aria-hidden="true"></i>
                                                    <span>Tipo de Propiedades</span>
                                                </a>
                                            </li>
                                        </ul>
                                    }
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="air__menuLeft__backdrop air__menuLeft__mobileActionToggle"></div>
                {/* //////////// END MENU LEFT */}
            </>
        )
    }
}
