import React, { Component, Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import { emailListsService } from '../../../services/tibesa.api';

export default class TableCampaignItem extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "email",
                text: "email",
                className: "name",

            },
            {
                key: "name",
                text: "nombre",
                className: "name",

            },
            { 
                key: "delivered",
                text: "Entregado",
                cell: record => {
                    return (
                        <Fragment>
                            {
                                record.sentOn &&
                                <p> {record.sentOn.split('T')[0]}</p>
                            }
                        </Fragment>
                    );
                }
            },
            { 
                key: "rejected",
                text: "Rechazado",
                cell: record => {
                    return (
                        <Fragment>
                            {
                                record.rejectedOn &&
                                <p>error envio / rechazo</p>
                            }
                        </Fragment>
                    );
                }
            },
            { 
                key: "action",
                text: "Acciones",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            { (record.rejectedOn && record.deleted==null) && 
                                <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                            }   
                             {  record.deleted && 
                                <p>Removido</p>
                            }   
                        </Fragment>
                    );
                }
            }
           
        ];
        this.config = {
            page_size: 50,
            length_menu: [50],
            show_pagination: true,
            show_length_menu:false,
            show_filter: false,
            show_info: true,
            bLengthChange: false,
            grouping: true
        }

        this.state = {
            records: this.props.data
        }
    }

    componentWillReceiveProps = (next_props) => {
        this.setState({records: next_props.data});
    }

    editRecord = (record) => {
        //console.log("Edit Record", record);
        this.props.handleOpenEdit(record);
    }

    deleteRecord = (record) => {
        //console.log(record);
       
        const arrayCopy = this.state.records;
        this.props.handleDelete(record);
        record.deleted = true;
        this.setState({records: arrayCopy});
    }

    render() {
        return (
            <div>
                <ReactDatatable
                    config={this.config}
                    records={this.state.records}
                    columns={this.columns}
                />
            </div>
        )
    }
}
