import React, { Component } from 'react'

import axios from 'axios';
import Swal from 'sweetalert2';
import configs from './../../configs/config'
import TableCategories from './components/table';
import { emailTemplatesService } from './../../services/tibesa.api';

/*
import PlaceHolder from 'ckeditor5-placeholder'
https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/installing-plugins.html

*/

export default class EmailTemplates extends Component {

    constructor() {
        super();
        this.state = {
            isuploading: false,
            type: 1,  //Default
            errors: [],
            data:[],
        };
    }

    componentDidMount() {
        emailTemplatesService.getAll()
        .then(res => {
                this.setState({ data: res });
        });
    }

    delete = (id) => {  //no disponible por el momento. 
        axios.post(`${configs.URL_API_BASE}/emailTemplates/${id}/delete`)
            .then(res => {
                console.log(res);
                if (parseInt(res.data.status) == 200) {
                    this.getData();
                    Swal.fire('Eliminado!', 'Elemento eliminado correctamente.', 'success');
                } else {
                    Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
                }
            }).catch(err => {
                console.log(err);
                Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
            });
    }

    handleOpenEdit = record => {
        this.props.history.push(`/email-templates/${record.templateId}`);
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let url = `${configs.URL_API_BASE}/emailTemplates/createEmailTemplate`;
        this.state.isEdit ? url = `${configs.URL_API_BASE}/emailTemplates/${this.state.id}/edit` : url = `${configs.URL_API_BASE}/emailTemplates/createEmailTemplate`;
        const formData = new FormData();

        formData.append('type', this.state.type);
        formData.append('name', this.state.name);
        formData.append('subject', this.state.subject);
        formData.append('body', this.state.body);
        formData.append('cc', this.state.cc);


        this.setState({ isuploading: true });
        if (this.state.isEdit) {
            axios.put(url, formData, this.state.config).then((response) => {
                if (parseInt(response.data.status) == 200) {
                    this.setState({ isuploading: false });
                    this.closeModal();
                    this.getData();
                    Swal.fire('Edición Correcta', 'Plantilla editada correctamente.', 'success');
                } else {
                    this.setState({ isuploading: false });
                    this.closeModal();
                    Swal.fire('Error', 'Ocurrio un error inesperado.', 'waring');
                }
            });
        } else {
            axios.post(url, formData, this.state.config).then((response) => {
                if (parseInt(response.data.status) == 200) {
                    this.setState({ isuploading: false });
                    this.closeModal();
                    this.getData();
                    Swal.fire('Ingreso Correcto', 'Plantilla ingresada correctamente.', 'success');
                } else {
                    this.setState({ isuploading: false });
                    this.closeModal();
                    Swal.fire('Error', 'Ocurrio un error inesperado.', 'waring');
                }
            });
        }


    }

    handleChangeInput = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Plantillas de Correo</h2>
                    <a href="/email-templates/add" className="btn btn-primary d-flex justify-content-between align-self-center">
                        <span>Nueva Plantilla&nbsp;</span>
                        <span><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        <TableCategories handleOpenEdit={this.handleOpenEdit} delete={this.delete} data={this.state.data}></TableCategories>
                    </div>
                </div>
                
            </>
        )
    }
}
