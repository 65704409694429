import React, { Component } from 'react'
import TableCompanies from './components/table';
import configs from '../../configs/config';
import axios from 'axios';
import Swal from 'sweetalert2'

export default class index extends Component {

    constructor() {
        super();
        this.state = {
            type: 1,  //Default
            errors: [],
            data:[],
        }
        this.getData();
    }

    getData = () => {
        axios.get(`${configs.URL_API_BASE}/companies/getCompanies`, {})
            .then(res => {
                if (res.data.status == 200) {
                    let auxData = res.data.results;
                    auxData.forEach((item) => {
                        item.id = item.companyId;
                    })
                    this.setState({ data: auxData });
                }
            });
    }

    handleOpenEdit = record => {
        console.log(record.id);
        this.props.history.push(`/companies/${record.id}`);
    }


    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Empresas</h2>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        {
                            <TableCompanies handleOpenEdit={this.handleOpenEdit} delete={this.delete} data={this.state.data}></TableCompanies>
                        }
                    </div>
                </div>
          
            </>
        )
    }
}
