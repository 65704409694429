class Video {
    constructor(entity = {}){
        this.id = entity.idVideo || null;
        this.title = entity.titulo || '';
        this.description = entity.descripcion || '';
        this.videoUrl = entity.urlVideo || '';
        this.priority = entity.prioridad || '';
        this.status= entity.status || '';
    }
};

export default Video;