export default class Company {

    constructor(entity = {}){
        this.id = entity.companyId || null;
        this.name = entity.name || '';
        this.address = entity.address || '';
        this.cp = entity.cp || '';
        this.contactEmail = entity.contactEmail || '';
        this.cityCode= entity.cityCode || '';
        this.countryCode= entity.countryCode || '';

        this.phoneNumber = entity.phoneNumber || '';
        this.status = entity.status || '';

        this.logoUrl = entity.logoUrl  || '';
        this.createdOn = entity.createdOn  || '';
        this.smtpHost = entity.smtpHost   || '';
        this.port = entity.port  || '';
        this.smtpAccount = entity.smtpAccount  || '';
        this.smtpPassword = entity.smtpPassword  || '';
        this.useSSL = entity.useSSL  || false;
        this.dailyEmails = entity.dailyEmails  || '';


    }
}