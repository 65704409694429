import React, { Component } from 'react';
import Swal from 'sweetalert2'
import { RULE_FUNCTIONS, Validator } from '../../../utilities/form-validation';
import classNames from 'classnames';
import Campaign from './../../../models/campaign'
import {campaignsService, emailListsService} from '../../../services/tibesa.api'
import {EmailLists} from './../../../models/emailList'
import { template } from 'lodash';
import { event } from 'jquery';

export default class CampaignForm extends Component {

    constructor(props) {
        super(props);
        this.state={
            ...(props.campaign || new Campaign()),
            errors: [],
            emailLists: [],
            templates: [],
        };
        this.state.editMode = this.state.id != null;  //HAY QUE  USARLO::: 
        this.submitCallback = this.props.onSubmit;
        this.editDesignCallback = this.props.onEditDesign;
        this.onTemplateChangeCallback = this.props.onTemplateChanged;
        this.sendCallback = this.props.onSendEmails;

        let validationRules =
            [
                {
                    fields: ['campaignName'],
                    rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 100)
                },
                {
                    fields: ['subject'],
                    rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 300)
                },
                {
                    fields:['sender'],
                    rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 300)
                },
                {
                    fields:['senderEmail'],
                    rule: v => RULE_FUNCTIONS.email(v) && RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 300)
                },
                {
                    fields:['replyTo'],
                    rule: v => RULE_FUNCTIONS.email(v) ||  (RULE_FUNCTIONS.minLen(v, 0) && RULE_FUNCTIONS.maxLen(v, 0))
                },  
               
            ]
        this.validator = new Validator(validationRules);
       
        this.removeTemplate = this.removeTemplate.bind(this);

    }

    decodeToString = (data) => {
        let buff = new Buffer.from(data, 'base64');
        return buff.toString('utf-8');
    }

    componentDidMount(){
        Promise.all([
            emailListsService.getAll(),campaignsService.getTemplates()])
            .then(([emailLists,templates]) => {
               // console.log(templates);
               templates.forEach(element => {
                   element.campaignDesign = this.decodeToString(element.campaignDesign);
               })
                this.setState({ 
                    emailLists:emailLists,templates});
                }
            )    
    }

    ref= ()  => {
        return this;
    }
    
    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) === -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) === -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    hasError = (name) => {
        return this.state.errors.indexOf(name) !== -1;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let errors = this.validator.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            Swal.fire('Error!', `cuentas con ${errors.length} error(es) en el formulario, por favor revisa los campos en rojo`, 'error');
            return false;
        }
        if (this.emailLists==0)
        {
            this.setState({ errors });
            Swal.fire('Error!', `Se requiere al menos una lista de correos para enviar la campaña`, 'error');
            return false;
        }
        const formData = new FormData();
        
        formData.append('campaignName', this.state.campaignName);
        //formData.append('closingDate', this.state.closingDate);
        //formData.append('sendingDate', this.state.sendingDate);
       //campaignDesign y campaignTemplateId tampoco.      
        formData.append('campaignEmailListId', this.state.campaignEmailListId);
            formData.append('campaignTemplateId', this.state.campaignTemplateId);
        //formData.append('campaignTag', this.state.campaignTag);

        formData.append('subject', this.state.subject);
        formData.append('replyTo', this.state.replyTo);

        formData.append('sender', this.state.sender);
        formData.append('senderEmail', this.state.senderEmail);

        if (this.state.id)
            this.submitCallback(formData,this.state.id);  //si el Id entonces es edicion. 
        else
            this.submitCallback(formData);  //si el Id entonces nuevo.   Teoricamente aqui no debe entrar.. 

    }

    handleChangeInput = (event) => {
        let { name, value } = event.target;

        if (name == 'campaignTemplateId'){
            var template = this.state.templates.filter(item => item.templateId == value)
            //emit Signal to Detail.. 
            //console.log("Cambiando la plantilla. "+ JSON.stringify( template[0]));

            this.onTemplateChangeCallback(value == 0 ? null :  template[0]);
          // cuando es 0 ??
        }
        this.setState({ [name]: value });
        this.validateInput(name, value);
    }

    handleEditDesign = (event) => {
        event.preventDefault();
        //templateSelected.
        this.editDesignCallback();   //el Json de la Paantilla. 
    }


    handleSend = (event) => {
        event.preventDefault();
        //templateSelected.
        this.sendCallback();   //el Json de la Paantilla. 
    }

    handleSendTest = (event) => {
        event.preventDefault();
        //ask for an email.. 
        //templateSelected.
        let email="alonso_hdez@hotmail.com";
        this.sendCallback(email);   //el Json de la Paantilla. 
    }

    handleRemoveTemplate = (event) => {
        if (event) event.preventDefault();
        this.setState( {
            campaignTemplateId : 0,
            campaignDesign :"",
        })
        //notify to load 
        this.onTemplateChangeCallback(null);
    }

    removeTemplate = () =>{
        this.handleRemoveTemplate(null);
    }

    //Primera  Idtemplate==null.. Mostrar el combo y no el editar.. 
    //segunda  ves Mostrar el link de cual template aplico, y un boton de cambiar (este activa el como co un boton de cancelar que regresa a lo que tenia), 
    //aparecera tambien el link de editar. 
    //segunda.. editando si edito.. no le permitira solo cancelar cambios.. es decir regresar al estado anterior  (IdTemplate==[Null o Anterior])


    render() {
        return (
            <>

                <form onSubmit={this.handleSubmit} autoComplete="off">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-row ">

                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Nombre de la Campaña : <span  className="text-red">*</span></label>
                                                <input name="campaignName" value={this.state.campaignName ? this.state.campaignName : ''} type="text" 
                                                    onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('campaignName') })} />
                                                <div className="invalid-feedback">Nombre requerido - máximo 255 caracteres.</div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                            <label>Asunto : <span  className="text-red">*</span></label>
                                                <input name="subject" value={this.state.subject ? this.state.subject : ''} type="text" 
                                                    onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('subject') })} />
                                                <div className="invalid-feedback">Asunto requerido - máximo 300 caracteres.</div>
                                            </div>
                                           
                                        </div>
                                        <div className="form-row ">

                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Nombre de quien envia: <span  className="text-red">*</span></label>
                                                <input name="sender" value={this.state.sender ? this.state.sender : ''} type="text" 
                                                    onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('sender') })} />
                                                <div className="invalid-feedback">Nombre requerido - máximo 300 caracteres.</div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                            <label>Email de quien Envia : <span  className="text-red">*</span></label>
                                                <input name="senderEmail" value={this.state.senderEmail ? this.state.senderEmail : ''} type="text" 
                                                    onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('senderEmail') })} />
                                                <div className="invalid-feedback">verifique el correo electronico.</div>
                                            </div>
                                           
                                        </div>
                                        <div className="form-row ">

                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Responder a (email): </label>
                                                <input name="replyTo" value={this.state.replyTo ? this.state.replyTo : ''} type="text" 
                                                    onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('replyTo') })} />
                                                <div className="invalid-feedback">replyTo no Valido</div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Seleccione Lista de Envio: </label>
                                                <select name="campaignEmailListId" value={this.state.campaignEmailListId}  onChange={this.handleChangeInput}
                                                className='form-control'>
                                                <option value='0'> Seleccione Uno </option>
                                                    {this.state.emailLists.map((item, pos) => ( 
                                                        <option key={pos} value={item.emailListId}> {item.name} - ( {item.totalEmails ? item.totalEmails : 0 } ) </option>))                                                    
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-row ">
                                            <div className="col-12 col-md-6 col-lg-6 my-3">
                                                <label>Plantilla de Correo : </label>
                                                {
                                                    this.state.campaignTemplateId==0 &&
                                                <select name="campaignTemplateId" value={this.state.campaignTemplateId}  onChange={this.handleChangeInput}
                                                    className='form-control'>
                                                    <option value='0'> Seleccione Uno </option>
                                                        {this.state.templates.map((item, pos) => ( 
                                                            <option key={pos} value={item.templateId}> {item.name}  </option>))                                                    
                                                        }
                                                </select>
                                                }  
                                                { this.state.campaignTemplateId>0 && this.state.templates.length>0 &&
                                                  <p>
                                                       { this.state.templates.filter(item => item.templateId == this.state.campaignTemplateId)[0].name }
                                                  </p> 
                                                }
                                                { this.state.campaignTemplateId>0  &&
                                                    <a href="#o" onClick={this.handleRemoveTemplate}>
                                                        [x remover]
                                                     </a>
                                                }
                                                 { this.state.campaignTemplateId>0 &&
                                                     <a href="#o" onClick={this.handleEditDesign}>
                                                       &nbsp; [Editar plantilla]
                                                     </a>   
                                                     } 
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer row">

                                <div className="col-4">
                                    {
                                        this.state.campaignStatus==3 && 
                                        <button className="btn btn-warning btn-lg float-left" onClick={this.handleSendTest}>( Enviar Prueba )</button>
                                        
                                    }</div>
<div className="col-4 ">
                                    {
                                        this.state.campaignStatus==3 && 
                                        <button className="btn btn-success btn-lg float-left" onClick={this.handleSend}>( Enviar )</button>
                                    }</div>
<div className="col-4 ">
                                    {  /* que nosea haya enviado la campaña */
                                        this.state.campaignStatus<4 && 
                                        <button className="btn btn-success btn-lg float-right" type="submit">Guardar</button>
                                    }
                                    <button className="btn float-right" type="button" onClick={() => window.location.href = '/campaigns'}>CANCELAR</button>
                                </div>
                            </div>
                        </div>
                </form>
            </>
        )

    }

}