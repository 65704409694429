import React, { Component } from 'react'
import { RULE_FUNCTIONS, Validator } from '../../../utilities/form-validation';

export default class SectionMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            latitude:'',
            longitude:'',
            address:'',
            title:'',
        }
        this.submitCallBack = this.props.onSubmit;

        let validationRules =
        [
            {
                fields: ['address','latitude','longitude'],
                rule: v => RULE_FUNCTIONS.required(v) 
            },
            {
                fields: ['title'],
                rule: v => RULE_FUNCTIONS.required(v)  && RULE_FUNCTIONS.maxLen(v, 150)
            },
        ]
        this.validator = new Validator(validationRules);

    }
    
    handleChangeInput = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
    }

    generateHtml(){
        var ID = "Article-" + (new Date()).toTimeString().replace(/-/g,'').replace(/:/g,'').replace(/ /g,'').split('GMT')[0]
        var locationStr = "https://maps.google.com/maps?width=100%&height=40&q=" + this.state.address  +  "@" + this.state.latitude + "," + this.state.longitude + "&t=&z=14&ie=UTF8&iwloc=B&output=embed"
        var moduleHTML =`<section id=${ID} name="Section-Map" class="container">
                    <div class="card">
                        <div class="card-body d-flex flex-column align-items-start">
                            <h4 class="card-titel text-primary">${this.state.title}</h4>
                            <p class="card-text">${this.state.address}</p>
                            <iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" 
                            src='${locationStr}'>
                            </iframe>
                        </div>
                    </div>
        </section> `;
        return { id:`${ID}`, name:`Section-Map(nuevo)`, value:moduleHTML};
    }

    submitChanges = () => {
        //Muestra los errores... 
        let errors = this.validator.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            return false;
        }

        if (this.submitCallBack){
            //haremos la llamada con una promesa para saber si todo ok y despues borrar los datos. 
            this.submitCallBack(this.generateHtml());
            this.clearForm();    
        }
    }

    clearForm = () =>{
        this.setState({
            latitude:'',
            longitude:'',
            address:'',
            title:'',
        })
    }
    
    render() {
        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <label htmlFor="secName">Titulo (requerido)</label>
                        <input type="text" name="title"  className="form-control" value={this.state.title} onChange={this.handleChangeInput} ></input>

                        <label htmlFor="secName">Dirección (requerido)</label>
                        <input type="text" name="address"  className="form-control" value={this.state.address} onChange={this.handleChangeInput} ></input>
                        
                        <label htmlFor="secName">Latitude (requerido)</label>
                        <input type="text" name="latitude"  className="form-control" value={this.state.latitude} onChange={this.handleChangeInput} ></input>
                        
                        <label htmlFor="secName">Longitud (requerido)</label>
                        <input type="text" name="longitude"  className="form-control" value={this.state.longitude} onChange={this.handleChangeInput} ></input>
                
                        {
                            this.state.errors && <p> <br/>
                            <div className="alert alert-danger" role="alert">
                                Hay datos requeridos o No Validos!
                            </div></p>
                        }
                    </div>
                    <div className="card-footer">
                        <input type="button" className="btn btn-primary" onClick={this.submitChanges} value="Agregar"></input>
                    </div>
                </div>
            </>    
        )
    }
}
