import React, { Component } from 'react'
import { RULE_FUNCTIONS, Validator } from '../../../utilities/form-validation';

export default class SectionArticle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUrl:'',
            targetUrl:'',
            title:'',
            bodyHtml:''
        }
        this.submitCallBack = this.props.onSubmit;

        let validationRules =
        [
            {
                fields: ['targetUrl','imageUrl'],
                rule: v => RULE_FUNCTIONS.required(v) 
            },
            {
                fields: ['title'],
                rule: v => RULE_FUNCTIONS.required(v)  && RULE_FUNCTIONS.maxLen(v, 100)
            },
        ]
        this.validator = new Validator(validationRules);

    }
    
    handleChangeInput = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
    }

    generateHtml(){
        var ID = "Article-" + (new Date()).toTimeString().replace(/-/g,'').replace(/:/g,'').replace(/ /g,'').split('GMT')[0]
        var moduleHTML =`<section id=${ID} name="Section-Article" class="container">
                <div class="row no-gutters bg-light position-relative">
                    <div class="col-md-6 mb-md-0 p-md-4">
                        <img src=${this.state.imageUrl} class="w-100" alt=${this.state.title}>
                    </div>
                    <div class="col-md-6 position-static p-4 pl-md-0">
                        <h3 class="mt-0">${this.state.title}</h3>
                        <p>${this.state.bodyHtml}</p>
                        <a href="${this.state.targetUrl}" target="_blank" class="stretched-link">Leer más...</a>
                    </div>
                </div>
        </section> `;
        return { id:`${ID}`, name:`Section-Article(nuevo)`, value:moduleHTML};
    }

    submitChanges = () => {
        //Muestra los errores... 
        let errors = this.validator.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            return false;
        }

        if (this.submitCallBack){
            this.submitCallBack(this.generateHtml());
            this.clearForm();    

        }
    }

    clearForm = () =>{
        this.setState({
            imageUrl:'',
            targetUrl:'',
            title:'',
            bodyHtml:''
        })
    }

    render() {
        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <label htmlFor="secName">Titulo (requerido - max 100): </label>
                        <input type="text" name="title"  className="form-control" value={this.state.title} onChange={this.handleChangeInput} ></input>
                        
                        <label htmlFor="secName">Url de la Imagen (requerido): </label>
                        <input type="text" name="imageUrl"  className="form-control" value={this.state.imageUrl} onChange={this.handleChangeInput} ></input>
                        
                        <label htmlFor="secName">Enlace a pagina (requerido): </label>
                        <input type="text" name="targetUrl"  className="form-control" value={this.state.targetUrl} onChange={this.handleChangeInput} ></input>
                        
                        <label htmlFor="secName">Texto del Articulo (requerido): </label>
                        <textarea rows="5" name="bodyHtml"  className="form-control" value={this.state.bodyHtml} onChange={this.handleChangeInput} ></textarea>

                        {
                            this.state.errors && <p> <br/>
                            <div className="alert alert-danger" role="alert">
                                Hay datos requeridos o No Validos!
                            </div></p>
                        }
                    </div>
                    <div className="card-footer">
                        <input type="button" className="btn btn-primary" onClick={this.submitChanges} value="Agregar"></input>
                    </div>
                </div>
            </>    
        )
    }
}
