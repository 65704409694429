import React, { Component } from 'react'
import { RULE_FUNCTIONS, Validator } from '../../../utilities/form-validation';

export default class SectionVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            title:'',
            youtubeCode:'',
        }
        this.submitCallBack = this.props.onSubmit;

        let validationRules =
        [
            {
                fields: ['title',],
                rule: v => RULE_FUNCTIONS.required(v)  && RULE_FUNCTIONS.maxLen(v, 100)
            },
            {
                fields: ['youtubeCode'],
                rule: v => RULE_FUNCTIONS.required(v)  && RULE_FUNCTIONS.maxLen(v, 40)
            },
        ]
        this.validator = new Validator(validationRules);

    }
    
    handleChangeInput = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
    }

    generateHtml(){
        var videoUrl = (this.state.youtubeCode) ? "https://www.youtube.com/embed/" + this.state.youtubeCode : '';
        var ID = "Video-" + (new Date()).toTimeString().replace(/-/g,'').replace(/:/g,'').replace(/ /g,'').split('GMT')[0]
        var moduleHTML =`<section id=${ID} name="Section-Video" class="container pb-3">
            <div class="card">
                <div class="card-body">
                    <h2 class="card-title">${this.state.title}</h2>
                    <div class="center-block"> <iframe width="100%" height="400" class="center-block" src=${videoUrl}> </iframe></div>
                </div>
            </div>
        </section> `;
        return { id:`${ID}`, name:`Section-Video(nuevo)`, value:moduleHTML};
    }

    submitChanges = () => {
        //Muestra los errores... 
        let errors = this.validator.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            return false;
        }


        if (this.submitCallBack){
            this.submitCallBack(this.generateHtml());
            this.clearForm();    

        }
    }

    clearForm = () =>{
        this.setState({
            name:'',
            title:'',
            youtubeCode:'',
        })
    }
    
    render() {
        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <label htmlFor="secName">Titulo (requerido - max 100): </label>
                        <input type="text" name="title"  className="form-control" value={this.state.title} onChange={this.handleChangeInput} ></input>
                        <label htmlFor="secName">Codigo del video Youtube (requerido - max 40): </label>
                        <input type="text" name="youtubeCode"  className="form-control" value={this.state.youtubeCode} onChange={this.handleChangeInput} ></input>
                        {
                            this.state.errors && <p> <br/>
                            <div className="alert alert-danger" role="alert">
                                Hay datos requeridos o No Validos!
                            </div></p>
                        }
                    </div>
                    <div className="card-footer">
                        <input type="button" className="btn btn-primary" onClick={this.submitChanges} value="Agregar"></input>
                    </div>
                </div>
            </>    
        )
    }
}
