import React, { Component, Fragment } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <Fragment>
                <div className="air__layout__footer">
                    <div className="air__footer">
                    <div className="air__footer__inner">
                        <div className="row">
                        <div className="col-12">
                            <p className="text-right">
                            &copy; 2020 Bienes Raices Tibesa Realty
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
