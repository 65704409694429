export default class NewsTranlated {

    constructor(entity = {}){
        this.id = entity.id || null;
        this.newsId = entity.newsId || null; 
        this.title = entity.title || '';
        this.slug = entity.slug || '';
        this.keywords = entity.keywords || '';
        this.imageUrl = entity.imageUrl || '';
        this.description= entity.description || '';
        this.status = entity.status || '';
        this.body = entity.body || '';
        this.metas = entity.metas || '';
        this.language = entity.language || 'es';

    }
}