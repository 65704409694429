import React, { Component } from "react"
import { campaignsService } from '../../services/tibesa.api'
import LoadingOverlay from "react-loading-overlay";
import Swal from 'sweetalert2'
import Campaign from "../../models/campaign";
import CampaignForm from "./components/campaign-form";
import DesignEmailBuilder from "./emarketing/designBuilder";
import axios from 'axios';
import configs from '../../configs/config';

export default class CampaignDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign: null,
            editting:false,  // (is html editor oppened)
            imageLibrary : [],
            unsavedChanges:false,
        }

        this.submitChanges = this.submitChanges.bind(this);   //desde campaign-form
        this.submitDesign = this.submitDesign.bind(this);     //desde designBuilder.
        
        this.editDesign = this.editDesign.bind(this);    //comunicacion para indicar que se presiono editar diseño. 
        this.templateHasChanged = this.templateHasChanged.bind(this,);
        this.removeDesign = this.removeDesign.bind(this);   //sin diseño. 
this.submitUpdateTemplate = this.submitUpdateTemplate.bind(this);

    }
  
    decodeToString = (data) => {
        let buff = new Buffer.from(data, 'base64');
        return buff.toString('utf-8');
    }

    componentDidMount() {
        this.loadData();
    }
    
    loadData(){
        //let p_library = propertiesService.getImagesLibrary(),
          let  p_campaign  = campaignsService.get(this.props.match.params.id) 
        Promise.all([p_campaign])
                .then( ([c_campaign] ) => { 
                    //console.log(c_library);                       
                    let auxAmen = new Campaign(c_campaign);
                    auxAmen.campaignDesign =this.decodeToString(auxAmen.campaignDesign); 
                  /* c_library.map( item  =>  {
                        item.location =  config.URL_IMAGES +  item.location.replace('original','500x500')
                    })*/

                    this.setState({ 
                                campaign: auxAmen,
                            }
                        )
            });
    }

    sendEmails = (test) => {
        if (test){
              // 
            const formData =  new FormData();
            formData.append('email',test);
            axios.post(`${configs.URL_API_BASE}/campaigns/${this.props.match.params.id}/sendCampaignTest`, formData)
            .then(res => {
                if (res.status === 200) {
                    Swal.fire('Prueba Enviada!', 'En breve recibiras el correos', 'success');
                } else {
                    Swal.fire('Error!',res.data.results, 'info');
                }
            })
        }
        else
            axios.get(`${configs.URL_API_BASE}/campaigns/${this.props.match.params.id}/sendCampaign`, {})
            .then(res => {
                if (res.data.status === 200) {
                    Swal.fire('Tarea Enviada!', 'En breve se enviaran todos los correos', 'success');
                    //actualicemos el status
                    window.location.reload();  // ya no debes poder usar el boton. 
                } else {
                    if (res.data.status === 200) {
                        Swal.fire('Ya se envio con anterioridad!', 'En breve se enviaran todos los correos', 'info');
                    }
                    else
                        Swal.fire('Error!',res.data.results, 'info');
                }
            });
    }

    submitChanges(formData) {  //no incluye el diseño. viene el form desde el control.. 
        Swal.fire({
            title: '¿Seguro que deseas editar la Campaña?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return campaignsService.update(this.props.match.params.id, formData)
                .then(response => {
                    return response;
                })
                .catch(error => {
                    Swal.showValidationMessage(
                        `Error en la petición: ${error}`
                    )
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
               // console.log(response);
                if (response.value && response.value.data && response.value.data.status == 200) {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: '¡Campaña actualizada con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }
                    ).then( () => {
                        window.location.reload();
                    });
                } else if(!response.dismiss == 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }

    submitDesign(design,html){  //viene solo el json de diseño.. 
        if (design){
            const formData =  new FormData();
            let data = new Buffer.from(JSON.stringify(design),'utf-8').toString("base64");
            let dataHtml = new Buffer.from(html,'utf-8').toString("base64");

            formData.append('campaignTemplateId',this.state.campaign.campaignTemplateId);
            formData.append('campaignDesign',data);
            formData.append('campaignHtml',dataHtml);
            //intent guardar los cambios. solo el diseño. 
             campaignsService.update(this.props.match.params.id, formData)
                .then(response => {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: '¡Disñeo actualizado con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }).then(
                            () => {
                                this.setState({editting:false});
                            });
                })
                .catch(error => {
                    Swal.showValidationMessage(
                        `Error en la petición: ${error}`
                    )
                });
                this.setState ({
                    unsavedChanges:false,
                });
        }
        if (this.state.unsavedChanges){  // si viene en null el diseño entonces puede haber quedado esto sin gardar cancela o remueve el template. 
            // regresa a cero el combo de la plantilla. 
            this.CampaignForm.removeTemplate(null);
        }
        this.setState ({
            editting: false,
            unsavedChanges:false,
        });
    }
      
    submitUpdateTemplate(design,html){  //viene solo el json de diseño.. 
        if (design){
            Swal.fire({
                title: 'Esta seguro de Actualizar la Plantilla?',
                showCancelButton: true,
                confirmButtonText: 'Si',
              }).then(() => {
                    //intent guardar los cambios. solo el diseño. 
                    const formData =  new FormData();
                    let data = new Buffer.from(JSON.stringify(design),'utf-8').toString("base64");
                    //let dataHtml = new Buffer.from(html,'utf-8').toString("base64");
                    formData.append('campaignDesign',data);
                    // no por el momento formData.append('campaignHtml',dataHtml);
                    campaignsService.updateTemplate(this.state.campaign.campaignTemplateId,formData)
                    .then(response => {
                        Swal.fire(
                            {
                                title:  '¡Perfecto!',
                                text: 'Plantilla actualizado con éxito!',
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'OK'
                            }).then(
                                () => {
                                });
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
                }
              )
        }
    }
      
    submitAddTemplate(design,html){  //viene solo el json de diseño.. 
        if (design){
            Swal.fire({
                title: 'Proporciona un nombre para identificarlo',
                input: 'text',
                inputAttributes: {
                  autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Guardar',
              }).then((result) => {
                if (result.value) {
                    //intent guardar los cambios. solo el diseño. 
                    const formData =  new FormData();
                    let data = new Buffer.from(JSON.stringify(design),'utf-8').toString("base64");
                    let dataHtml = new Buffer.from(html,'utf-8').toString("base64");

                    formData.append('campaignDesign',data);
                    formData.append('campaignHtml',dataHtml);
                    formData.append('name',result.value);

                    campaignsService.addTemplate(formData)
                    .then(response => {
                       // console.log(JSON.stringify(response));
                        Swal.fire(
                            {
                                title:  '¡Perfecto!',
                                text: 'Plantilla Agregada con éxito!',
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'OK'
                            }).then(
                                () => {
                                    window.location.reload();
                                });
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
                }
              })
        }
    }

    removeDesign(){  //from campaignForm
        let newdata = new Campaign(this.state.campaign)
        newdata.campaignDesign="";
        newdata.campaignTemplateId=0;
        this.setState({
            campaign : newdata
        })
       //debeia activar seleccion.  combo. 
    }   

    //el formulario informa que van a editar el diseño. 
    editDesign(){
        this.setState(
            {  
                editting:true 
            }
        )
        //console.log("Editando la plantilla. " + this.state.campaign.campaignDesign);
        this.DesignEmailBuilder.loadDesign(this.state.campaign.campaignDesign);
    }

    //
    templateHasChanged(template){
        // la plantilla viene en base64ç
        let newdata = new Campaign(this.state.campaign)
        if (template){
            newdata.campaignDesign=template.campaignDesign;
            newdata.campaignTemplateId=template.templateId;
            console.log("Cambiado...");
            this.setState({
                    unsavedChanges : true,
                    campaign : newdata
                }, () => {
                    console.log("Ahora al diseño");
                this.editDesign();
            });
        }
    }

    render() {
        let form;
        let editorForm;

        if (this.state.campaign != null) {
            form = <CampaignForm   ref={ obj => this.CampaignForm = obj}  campaign={this.state.campaign}  onSendEmails ={this.sendEmails}
                                        onSubmit={this.submitChanges} onEditDesign={this.editDesign} onTemplateChanged={this.templateHasChanged}>
                    </CampaignForm>
            editorForm =<DesignEmailBuilder  ref={ obj => this.DesignEmailBuilder = obj} submitDesign={this.submitDesign} submitUpdateTemplate={this.submitUpdateTemplate} submitAddTemplate={this.submitAddTemplate}   >
                    </DesignEmailBuilder>
        }
        else {
            form = <LoadingOverlay></LoadingOverlay>
            editorForm = <LoadingOverlay></LoadingOverlay>
        }
        return (
            <>

                <div className="air__utils__heading">
                    <a href="/campaigns">Listado de Campañas de email</a><span>{" "}/{" "}Editar campaña #{this.props.match.params.id}</span>
                    </div>
                    <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    </div>
                    <div className="row my-4">
                    <div  className="col-12" style={this.state.editting ? {display:'none'} : {}}>
                        {form}
                    </div>                    
                    <div  className="col-12" style={!this.state.editting ? {display:'none'} : {}}  >
                         {editorForm}
                    </div>
                </div>
            </>
        )
    }
}
