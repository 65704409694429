import React, { Component } from 'react';
import auth from '../../session/auth'

import Navigation from "../_shared/navigation";

import Header from "../_shared/header";
import Footer from "../_shared/footer";

export default class AppLayout extends Component {

    constructor(props) {
        super(props);
        auth.validateView('dashboard');
    }

    render() {
        return (
            <div className="admin air__menu--flyout">
                <div className="air__layout air__layout--hasSider">
                    <Navigation></Navigation>
                    {/* //////////// CONTENT RIGHT */}
                    <div className="air__layout"> 
                        {/* /////// HEADER */}
                        <Header></Header>
                        {/* /////// END HEADER */}
                        {/* /////// MAIN CONTENT */}
                        <div className="air__layout__content p-5">
                            <div className="air__utils__content">
                                {this.props.children}
                            </div>
                        </div>
                        {/* /////// END MAIN CONTENT */}
                        {/* /////// FOOTER */}
                        <Footer></Footer>
                        {/* /////// END FOOTER */}
                    </div>
                    {/* //////////// END CONTENT RIGHT */}
                </div>
            </div>
        )
    }
}
