import React, { Component } from "react"
import PropertyForm from './components/property-form'
import { propertiesService,emailTemplatesService } from '../../services/tibesa.api'
import Modal from 'react-modal';

import LoadingOverlay from "react-loading-overlay";
import Property from "../../models/property";
import Swal from 'sweetalert2';
import configs from './../../configs/config'

export default class PropertyDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            property: null,
            show:null,
            emailTemplates : [],
            brochureUrl:null,
        }

        this.submitChanges = this.submitChanges.bind(this);

    }

    componentDidMount() {
        this.setState ({
            propertyId : this.props.match.params.id
        });
        Promise.all([
            propertiesService.get(this.props.match.params.id),
            propertiesService.getMediaOfProperty(this.props.match.params.id),
            emailTemplatesService.getAll(),
            
        ])
            .then(([property, images,emailTemplates]) => {
                this.setState({ 
                    property : new Property(property.info, property.amenities, images),
                    emailTemplates : emailTemplates,
                })
            })
    }

    callRequestBrochure=() => {
        this.setState({ isuploading : true, brochureUrl:null, })
        let formData = new FormData();
        formData.append("templateId", this.state.templateId);
        propertiesService.generatePropertyBrochure(this.state.propertyId,formData).then( (result) => {
            if (result.data.status =='200') {
                this.setState({
                    brochureUrl : `${configs.URL_API_BASE}` + "/" + result.data.results.replace('public/','')
                })
            }
        } ).finally(()=>{
            this.setState({ isuploading : false })
        })
    }

    submitChanges(formData) {
        Swal.fire({
            title: '¿Seguro que deseas editar la Propiedad?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return propertiesService.update(this.props.match.params.id, formData)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
                if (response.value && response.value.data && response.value.data.status == 200) {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: 'Propiedad actualizada con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }
                    ).then(() => {
                        window.location.reload();
                    });
                } else if(!response.dismiss == 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }
   
    handleClose = () => {
        this.setState({
            show: null,
            item: null,
        });
    }

    handleTemplateChange = (event) => {
        let { name, value } = event.target;
        this.setState({ 
            templateId : value
        });
        //busca la plantilla seleccionda
    }

    handleShow = (modalName) => {
    
        this.setState({
            show: modalName,
        });
    }

    render() {

        let form;
        if (this.state.property != null) {
            form = <PropertyForm property={this.state.property} onSubmit={this.submitChanges}></PropertyForm>
        }
        else
            form = <LoadingOverlay></LoadingOverlay>

        return (
            <>
                 <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <div>
                    <a href="/properties">Listado de propiedades</a><span>{" "}/{" "}Editar Propiedad #{this.props.match.params.id}</span>
                    </div>
                    <a
                        className="btn btn-primary d-flex justify-content-between align-self-center"
                        href="#" onClick={()=> {this.handleShow('generarBrochure')}} >
                        <span>Generar Brochure &nbsp;</span>
                        <span>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                        </span>
                    </a>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        {form}
                    </div>
                </div>

                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.show == 'generarBrochure'}
                    onRequestClose={this.handleClose}
                    className="Modal">
                    <form>
                        <div className="modal-dialog modal-dialog-centered">
                            <LoadingOverlay
                                active={this.state.isuploading}
                                spinner
                                text='Cargando...'>
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="row content-modal-mobile">
                                            <div className="col-12 col-md-12">
                                            { this.state.brochureUrl?  
                                                <div className="form-row ">
                                                <div className="col-12">
                                                    <a className="text-primary" href={this.state.brochureUrl} target="_blank">Abrir el Brochure</a>
                                                </div>
                                                </div>  
                                                : 
                                                 <div className="form-row ">
                                                 <div className="col-4 col-md-6 col-lg-4 col-xl-4">
                                                     <label className="text-primary" >Plantilla:</label>
                                                 </div>
                                                 <div className="col-8 col-md-6 col-lg-8 col-xl-8">
                                                     <select name="templateId" value={this.state.templateId} onChange={this.handleTemplateChange} className="form-control">
                                                         <option  value='0'> Seleccione la Plantilla para Generar Brochure     </option>
                                                         { this.state.emailTemplates.filter( x => x.type===2).map((item, pos) => (<option key={pos} value={item.templateId}> {item.name} </option>))}
                                                     </select>
                                                 </div>
                                             </div>                                                
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    
                                        <div className="modal-footer">
                                            <button className="btn btn-danger btn-lg" type="button" onClick={this.handleClose}>Cancelar</button>
                                            <button className="btn btn-success btn-lg" type="button" onClick ={this.callRequestBrochure}>Generar</button>
                                        </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </form>
                </Modal>
            </>
        )
    }
}
