import React,{ useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'

export default class Wizard extends React.Component {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  }
  static Page = ({  visible, children }) => {
    const [isVisible, setVisibility] = useState(null);
    const { page } = this

    useEffect(() => {
      setVisibility(visible); // update the state
    }, [visible]); // hook is only triggered when value changes

    return children
  }
  

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      values: props.initialValues || {}
    }
  }

  next = values => {
    this.setState(state => ({
        page: Math.min(state.page + 1, this.props.children.length - 1),
        values
      })
    );
    console.log(this.state.page+1);
  }
  previous = () => {
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    })
    );
    console.log(this.state.page-1);

  }
  
  ref= ()  => {
    return this;
  }
  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ]
   return activePage.props.validate ? activePage.props.validate(values) : {}
  }


  handleSubmit = values => {
    const { children, onSubmit } = this.props
    const { page } = this.state
    const isLastPage = page === React.Children.count(children) - 1
    if (isLastPage) {
      return onSubmit(values)
    } else {
      this.next(values)
    }
  }

  render() {
    const { children } = this.props
    const { page, values } = this.state
    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 1

    return (
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        className=""
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            {activePage}
            <div className="d-flex bd-highlight mb-3">
              {page > 0 && (
                <div className="p-2 bd-highlight">
                  <button type="button"  className="btn btn-secondary" onClick={this.previous}>
                    « Anterior
                  </button>
                </div>
              )}
                {!isLastPage && 
                  <div className="ml-auto p-2 bd-highlight">
                    <button type="submit" className="btn btn-primary">Siguiente »</button>
                  </div>
                }
                {isLastPage && 
                  <div  className="ml-auto p-2 bd-highlight">
                    <button type="submit"  className="btn btn-primary" disabled={submitting}>
                      Aceptar
                    </button>
                  </div>
                }
            </div>
          </form>
        )}
      </Form>
    )
  }
}
