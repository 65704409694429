import React, { Component } from 'react'
import { RULE_FUNCTIONS, Validator } from '../../../utilities/form-validation';

export default class SectionJumbotron extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUrl:'',
            targetUrl:'',
            title:'',
            shortDesc:''
        }
        this.submitCallBack = this.props.onSubmit;

        let validationRules =
        [
            {
                fields: ['title'],
                rule: v => RULE_FUNCTIONS.required(v)  && RULE_FUNCTIONS.maxLen(v, 100)
            },
          
        ]
        this.validator = new Validator(validationRules);

    }
    
    handleChangeInput = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
    }

    generateHtml(){
        var ID = "Jumbo-" + (new Date()).toTimeString().replace(/-/g,'').replace(/:/g,'').replace(/ /g,'').split('GMT')[0]
        var moduleHTML =`<section id=${ID} name="Section-Banner" class="container" >
                <div class="jumbotron jumbotron-fluid" style="background: url(${this.state.imageUrl}) no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;">
                    <div class="container">
                         <h1 class="display-4">${this.state.title}</h1>
                        <p class="lead">${this.state.shortDesc}</p>
                        <a class="btn btn-primary mr-2" href=${this.state.targetUrl} role="button">Leer más...</a>
                    </div>
                </div>
        </section> `;
        return { id:`${ID}`, name:`Section-Banner(nuevo)`, value:moduleHTML};
    }

    submitChanges = () => {
        //Muestra los errores... 
        let errors = this.validator.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            return false;
        }


        if (this.submitCallBack){
            this.submitCallBack(this.generateHtml());
            this.clearForm();    
        }
    }

    
    clearForm = () =>{
        this.setState({
            imageUrl:'',
            targetUrl:'',
            title:'',
            shortDesc:''
        })
    }
    

    render() {
        return (
            <>
                <div className="card">
                    <div className="card-body">
                        <label htmlFor="secName">Titulo (requerido - max 100): </label>
                        <input type="text" name="title"  className="form-control" value={this.state.title} onChange={this.handleChangeInput} ></input>
                        
                        <label htmlFor="secName">Url de la Imagen (requerido): </label>
                        <input type="text" name="imageUrl"  className="form-control" value={this.state.imageUrl} onChange={this.handleChangeInput} ></input>
                        
                        <label htmlFor="secName">Enlace a pagina (opcional): </label>
                        <input type="text" name="targetUrl"  className="form-control" value={this.state.targetUrl} onChange={this.handleChangeInput} ></input>
                        
                        <label htmlFor="secName">Texto Corto (opcional - max 200): </label>
                        <textarea rows="5" name="shortDesc"  className="form-control" value={this.state.shortDesc} onChange={this.handleChangeInput} ></textarea>
                        {
                            this.state.errors && <p> <br/>
                            <div className="alert alert-danger" role="alert">
                                Hay datos requeridos o No Validos!
                            </div></p>
                        }
                    </div>
                    <div className="card-footer">
                        <input type="button" className="btn btn-primary" onClick={this.submitChanges} value="Agregar"></input>
                    </div>
                </div>
            </>    
        )
    }
}
