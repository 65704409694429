import React, { Component } from 'react'

import Switch from "react-switch";
import Modal from 'react-modal';
import axios from 'axios';
import Swal from 'sweetalert2';
import configs from './../../configs/config'
import TableCategories from './components/table';
import LoadingOverlay from 'react-loading-overlay';

export default class Categories extends Component {

    constructor() {
        super();
        this.state = {
            isuploading: false,
            imagepreview: null,
            checked: false,
            modalIsOpen: false,
            name: '',
            description: '',
            image: '',
            showsuccess: false,
            config: {
                headers: { 'content-type': 'multipart/form-data' }
            },
            cats: [],
            isEdit: false,
            id: '',
        };
        this.getCats();
    }

    getCats = () => {
        axios.get(`${configs.URL_API_BASE}/categories/getCategories`, {})
            .then(res => {
                if (res.data.status === 200) {
                    let auxCats = res.data.results;
                    auxCats.forEach((item) => {
                        item.id = item.id_category;
                        item.status_val = (item.status === '1') ? 'Activo' : 'Inactivo';
                    })
                    this.setState({ cats: auxCats });
                }
            });
    }

    delete = (id) => {
        axios.post(`${configs.URL_API_BASE}/categories/${id}/delete`)
            .then(res => {
                console.log(res);
                if (parseInt(res.data.status) === 200) {
                    this.getCats();
                    Swal.fire('Eliminado!', 'Elemento eliminado correctamente.', 'success');
                } else {
                    Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
                }
            }).catch(err => {
                console.log(err);
                Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
            });
    }

    handleOpenEdit = record => {
        this.setState({
            isEdit: true,
            name: record.nombre,
            description: record.description,
            modalIsOpen: true,
            id: record.id_category,
            imagepreview: null,
        });

        record.status === 1 ? this.setState({ checked: true }) : this.setState({ checked: false });
    }

    handleAmenitiSubmit = (event) => {
        event.preventDefault();

        let url = `${configs.URL_API_BASE}/categories/createCategory`;
        this.state.isEdit ? url = `${configs.URL_API_BASE}/categories/${this.state.id}/edit` : url = `${configs.URL_API_BASE}/categories/createCategory`;
        const formData = new FormData();

        formData.append('image', this.state.image);
        formData.append('name', this.state.name);
        formData.append('description', this.state.description);
        formData.append('status', (this.state.checked) ? '1' : '0')

        this.setState({ isuploading: true });
        if (this.state.isEdit) {
            axios.put(url, formData, this.state.config).then((response) => {
                if (parseInt(response.data.status) === 200) {
                    this.setState({ isuploading: false });
                    this.closeModal();
                    this.getCats();
                    Swal.fire('Edición Correcta', 'Categoria editada correctamente.', 'success');
                } else {
                    this.setState({ isuploading: false });
                    this.closeModal();
                    Swal.fire('Error', 'Ocurrio un error inesperado.', 'waring');
                }
            });
        } else {
            axios.post(url, formData, this.state.config).then((response) => {
                if (parseInt(response.data.status) === 200) {
                    this.setState({ isuploading: false });
                    this.closeModal();
                    this.getCats();
                    Swal.fire('Ingreso Correcto', 'Categoria ingresada correctamente.', 'success');
                } else {
                    this.setState({ isuploading: false });
                    this.closeModal();
                    Swal.fire('Error', 'Ocurrio un error inesperado.', 'waring');
                }
            });
        }


    }

    handleChangeInput = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onChangeFile = e => {
        this.setState({
            image: e.target.files[0],
            imagepreview: URL.createObjectURL(e.target.files[0])
        })
    }

    activateCat = (checked) => {
        this.setState({ checked });
    }

    openModal = () => {
        this.setState({
            modalIsOpen: true,
            isEdit: false,
            name: '',
            description: '',
            checked: true,
            imagepreview: null,
        });

    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Categorías</h2>
                    <a href="#o" className="btn btn-primary d-flex justify-content-between align-self-center" onClick={this.openModal}>
                        <span>Nueva Categoría &nbsp;</span>
                        <span><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        <TableCategories handleOpenEdit={this.handleOpenEdit} delete={this.delete} cats={this.state.cats}></TableCategories>
                    </div>
                </div>
                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    className="Modal"
                    contentLabel="Crear Amenidad">
                    <form onSubmit={this.handleAmenitiSubmit}>
                        <div className="modal-dialog">

                            <LoadingOverlay
                                active={this.state.isuploading}
                                spinner
                                text='Subiendo el contenido...'
                            >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{this.state.isEdit ? "Editar Categoría" : "Crear Categoría"}
                                        </h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row content-modal-mobile">
                                            <div className="col-12">
                                                <div className="form-row ">
                                                    <div className="col-12 d-flex align-items-center bt-3">
                                                        <label className="form-check-label mr-3" htmlFor="priorizar">Activar esta Categoría?</label>
                                                        <Switch onChange={this.activateCat} checked={this.state.checked} />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 my-3">
                                                        <label>Nombre de la Categoría <span
                                                            className="text-red">*</span></label>
                                                        <input name="name" value={this.state.name} autoFocus={true} type="text" className="form-control" onChange={this.handleChangeInput} required />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 my-3">
                                                        <label>Descripción de la Categoría <span
                                                            className="text-red">*</span></label>
                                                        <input name="description" value={this.state.description} type="text" className="form-control" onChange={this.handleChangeInput} required />
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6 my-3">
                                                        <p>Imagen de la Categoria <span className="text-red">*</span>
                                                        </p>
                                                        <input name="image" type="file" onChange={this.onChangeFile} id="img" />
                                                        <img alt=""
                                                            width={'auto'} height={180} mode='fit'
                                                            src={this.state.imagepreview} />
                                                    </div>
                                                    <div className="d-flex justify-content-around col-12">
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-danger btn-lg" type="button" onClick={this.closeModal}>CANCELAR</button>
                                        <button className="btn btn-success btn-lg" type="submit">GUARDAR</button>
                                    </div>
                                </div>
                            </LoadingOverlay>

                        </div>
                    </form>
                </Modal>
            </>
        )
    }
}
