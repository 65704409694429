import React, { Component } from "react";

import "react-tabs/style/react-tabs.css";
import Switch from "react-switch";
import Modal from "react-modal";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import TableNews from "./components/table";

import axios from "axios";
import Swal from "sweetalert2";
import configs from "./../../configs/config";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LoadingOverlay from 'react-loading-overlay';
import { newsService } from './../../services/tibesa.api';

const newsStatus = ["inactivo","activo","reservado","eliminado"]

export default class News extends Component {
    constructor() {
        super();
        this.state = {
            isuploading: false,
            imagepreview: null,
            checked: false,
            modalIsOpen: false,
            isEdit: false,
            data: [],
            config: {
                headers: { "content-type": "multipart/form-data" }
            },
            new: {
                status: "",
                titulo: "",
                slug: "",
                keywords: "",
                descripcion: "",
                notice: "",
                tituloEN: "",
                slugEN: "",
                keywordsEN: "",
                descriptionEN: "",
                noticeEN: "",
                id: ""
            },
            image: ""
        };
        this.getNews();
    }

    getNews() {
        newsService.getAll()
        .then(res => {
                res.forEach(item => {
                    item.status_val = newsStatus[item.status];
                });
                this.setState({ data: res });
        });
    }

    delete = id => {
        axios.get(`${configs.URL_API_BASE}/news/${id}/delete`)
            .then(res => {
                if (parseInt(res.data.status) == 200) {
                    this.getNews();
                    Swal.fire(
                        "Eliminado!",
                        "Elemento eliminado correctamente.",
                        "success"
                    );
                } else {
                    Swal.fire(
                        "Error!",
                        "Contacte al desarrollador.",
                        "warning"
                    );
                }
            })
            .catch(err => {
                Swal.fire("Error!", "Contacte al desarrollador.", "warning");
            });
    };

    handleOpenEdit = record => {
        this.props.history.push(`/news/${record.id}`);
    };

    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Noticias</h2>
                    <a href="/news/add" className="btn btn-primary d-flex justify-content-between align-self-center">
                        <span>Nueva Noticia&nbsp;</span>
                        <span><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        <TableNews
                            handleOpenEdit={this.handleOpenEdit}
                            delete={this.delete}
                            data={this.state.data}
                        ></TableNews>
                    </div>
                </div>

            </>
        );
    }
}
