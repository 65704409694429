import React, { Component } from 'react';
import Swal from 'sweetalert2'
import { RULE_FUNCTIONS, Validator } from '../../../utilities/form-validation';
import classNames from 'classnames';
import emailList from './../../../models/emailList'
import TableLisItem from './tableListItem'
import { emailListsService,contactsService} from '../../../services/tibesa.api'
import * as ReactBootstrap from 'react-bootstrap';
import TableContactSearch from '../../contacts/components/tableContactSearch';

export default class EmailListForm extends Component {
    constructor(props) {
        super(props);
        this.state={
            ...(props.emailList || new emailList()),
            errors: [],  contactEmails:[],   data: props.data,
            c_sources:[],   c_interest:[],   filteredContacts:[],
            source : '0',   interest : '0',  messageFilter:"()"
        };

        this.state.editMode = this.state.id != null;  //HAY QUE  USARLO::: 
        this.submitCallback = this.props.onSubmit;
        let validationRules = [
                {
                    fields: ['name'],
                    rule: v => RULE_FUNCTIONS.required(v) && RULE_FUNCTIONS.maxLen(v, 100)
                }, ]
        this.validator = new Validator(validationRules);
       
    }
    componentDidMount(){
        Promise.all([
            contactsService.getToExport()])
        .then(([contacts]) => {
            contacts.map(item => {
                item.id=item.contactId;
                item.interests = item.interests ? item.interests.toLowerCase() : 'ND';
                item.source = item.source ? item.source.toLowerCase() : 'ND';
            })    
            this.setState(
                {  
                    contactEmails: contacts, 
                    c_sources : contacts.map(item => item.source
                    ).filter((value,index,self) => self.indexOf(value) === index ).sort(),
                    c_interest : contacts.map(item =>  item.interests
                    ).filter((value,index,self) => self.indexOf(value) === index).sort(),
                    filteredContacts : contacts,
                });

        });
    }

    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) === -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    handleChangeInput = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value });
        this.validateInput(name, value);
    }

    validateInput = (name, value) => {
        let validationResult = this.validator.validateField(name, value);
        if (validationResult) {
            let errors = this.state.errors.filter(x => x !== name);
            this.setState({ errors });
        }
        else {
            let errors = this.state.errors;
            if (errors.indexOf(name) === -1) {
                this.setState({ errors: [...errors, name] });
            }
        }
        return validationResult;
    }

    hasError = (name) => {
        return this.state.errors.indexOf(name) !== -1;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let errors = this.validator.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            Swal.fire('Error!', `cuentas con ${errors.length} error(es) en el formulario, por favor revisa los campos en rojo`, 'error');
            return false;
        }
        const formData = new FormData();
        // es manual.. 
        this.state.contacts = this.state.contacts + "\r\n";
        let newarray = this.state.contacts.split(/\r?\n/);
        
        let textData  = newarray.filter(item  => item !="").map((item) => {
            return item
        }).join('\n');

        let dtd = new Buffer.from(textData,'utf-8').toString("base64");

        formData.append('emails', dtd);

        this.submitCallback(formData,this.state.id);  //si el Id entonces es edicion.         
    }

    saveAllData = (event) => {
        event.preventDefault();

        let textData  = this.state.filteredContacts.map((item) => {
            return item.email.toString() + (item.name ? "," + item.name : "")
        }).join('\n');
        
        let errors = this.validator.validateState(this.state);
        if (errors.length > 0) {
            this.setState({ errors });
            Swal.fire('Error!', `cuentas con ${errors.length} error(es) en el formulario, por favor revisa los campos en rojo`, 'error');
            return false;
        }

        let dtd = new Buffer.from(textData,'utf-8').toString("base64");


        const formData = new FormData();
        formData.append('emails', dtd);
        
        this.submitCallback(formData,this.state.id);  //si el Id entonces es edicion. 

    }
    

    delete = (record) =>{
        window.event.preventDefault();        //removeit from data. 
        const formData = new FormData();
        formData.append('emailListItemId', record.id);
        emailListsService.deleteEmailItem(this.state.id,formData)
        .then(resp => {
           const arrayCopy = this.state.data.filter((x) => x.id != record.id);
           this.setState({ data:arrayCopy});
           // window.location.reload();
        })        
    }

    handleFilterChange = (event) => {
        if (event.target.name=='source'){
            this.setState( {source:event.target.value});
        }else{
            //asumiendo solo 2 filtros.. 
            this.setState( {interest:event.target.value});
        }

    }
 
    addRecord = (record) =>{
        window.event.preventDefault();        //removeit from data. 
        this.state.contacts = record.email + ',' + record.name + "\r\n";

        const formData = new FormData();
        // es manual.. 
        let newarray = this.state.contacts.split(/\r?\n/);        
        let textData  = newarray.filter(item  => item !="").map((item) => {
            return item
        }).join('\n');

        let dtd = new Buffer.from(textData,'utf-8').toString("base64");
        formData.append('emails', dtd);
        this.submitCallback(formData,this.state.id);  
        
        // message to said added. 
    }

    applyFilter = (event) => {
        event.preventDefault();
        //toma los dos valores, 
//        console.log(`valors de filtros ${this.state.source} y ${this.state.interest}`);
        let result = this.state.contactEmails;

        if (this.state.source!='0') 
            result = result.filter( item => item.source === this.state.source)
        if (this.state.interest !='0') 
            result = result.filter( item => item.interests === this.state.interest)
        this.setState({
            filteredContacts : result,
            messageFilter :  `( ${this.state.source!='0' ? this.state.source:"" } ${this.state.interest!='0' ? this.state.interest:"" } )`
        })
    }

    render() {
        return (
            <>   
            <form onSubmit={this.handleSubmit} autoComplete="off">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-row  my-3">
                                        <label>Nombre de la Lista </label>
                                        <input name="name" value={this.state.name ? this.state.name : ''} type="text" required
                                                    onChange={this.handleChangeInput}
                                                    className={classNames('form-control', { 'is-invalid': this.hasError('name') })} />
                                        <div className="invalid-feedback">Nombre requerido - máximo 100 caracteres.</div>   
                                </div>
                            </div>
                        </div>
                       
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="clearfix">&nbsp;</div>
                                <ReactBootstrap.Tabs defaultActiveKey="home" id="controlled-tab">
                                    <ReactBootstrap.Tab eventKey="home" title={'Lista Actual (' + this.state.data.length + ')'}  className="tab-bordered">
                                    {
                                        <TableLisItem handleOpenEdit={this.handleOpenEdit}  handleDelete={this.delete}  data={this.state.data}></TableLisItem>
                                    }
                                    </ReactBootstrap.Tab>
                                    <ReactBootstrap.Tab eventKey="history" title="Copiar de Contactos">
                                        <br/>
                                        <div className="row ">
                                            <div className="col-6 col-md-6 col-lg-3 col-xl-3">
                                                <label> Fuente de Captura {" "} </label>
                                                <select name="source" value={this.state.source} onChange={this.handleFilterChange} className="form-control">
                                                        <option value='0'> Seleccione </option>
                                                    {this.state.c_sources.map((item, pos) => (<option key={pos} value={item}> {item} </option>))}
                                                </select>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-3 col-xl-3">
                                                <label> Interest {" "}</label>
                                                <select name="interest" value={this.state.interest} onChange={this.handleFilterChange} className="form-control">
                                                    <option value='0'> Seleccione </option>
                                                    {this.state.c_interest.map((item, pos) => (<option key={pos} value={item}> {item} </option>))}
                                                </select>
                                            </div>
                                            <div className="row flex-column flex-fill">
                                                <div className="col-12 flex-fill d-flex align-items-end">
                                                    <button className="btn btn-info" type="button" onClick={this.applyFilter}>Aplicar filtro</button>
                                                </div>
                                            </div>
                                            <div className="row flex-column flex-fill">
                                                <div className="col-12 flex-fill d-flex align-items-end">
                                                    <button className="btn btn-primary" type="button" onClick={this.saveAllData}>Exportar todos</button>
                                                </div>
                                            </div>
                                        </div>                            
                                        <hr/> 
                                        <div>
                                            Registros {this.state.filteredContacts.length} {this.state.messageFilter}
                                        </div>    
                                        <div> 
                                            {
                                                <TableContactSearch 
                                                data={this.state.filteredContacts?this.state.filteredContacts:[]}
                                                dataselected={this.state.data?this.state.data:[]} 
                                                  handleAddRecord={this.addRecord} ></TableContactSearch> 
                                            }
                                           
                                        </div>                                       
                                    </ReactBootstrap.Tab>
                                    <ReactBootstrap.Tab eventKey="manual" title="Captura Manual">
                                            <div className="modal-dialog modal-lg">
                                                
                                                    <div className="modal-content">
                                                        
                                                        <div className="modal-body">
                                                            <div className="row content-modal-mobile">
                                                                <div className="col-12">
                                                                    <div className="form-row">
                                                                        <div className="col-12 d-flex align-items-center ">
                                                                            <label className="form-check-label mr-3" htmlFor="priorizar">Contactos</label>
                                                                            <textarea name="contacts" value={this.state.contacts} type="text" cols="40" rows="5"  className="form-control" onChange={this.handleChangeInput} />                                                   
                                                                        </div>
                                                                        <div className="col-12 my-3">
                                                                            <p className="text-muted">
                                                                                Solamente puedes añadir 1 correo electrónico por línea, en la misma linea la coma indica el nombre despues de correo (sin la coma no se agrega el nombre) 
                                                                                si tienes más de uno presionar “ENTER” después de cada dirección de email que añadas. 
                                                                                Puedes copiar y pegar hasta 500 direcciones de correo electrónico. 
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button className="btn btn-success btn-lg" type="submit">Enviar</button>
                                                        </div>
                                                    </div>
                                            </div>
                                    </ReactBootstrap.Tab>
                                </ReactBootstrap.Tabs>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </form>
         
            </>
        )
    }

}