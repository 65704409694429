import React, { Component } from 'react'

import Modal from 'react-modal';
import LoadingOverlay from 'react-loading-overlay';

import TableOportunities from './components/table';
import configs from './../../configs/config';
import axios from 'axios';
import Swal from 'sweetalert2';

export default class index extends Component {
    constructor() {
        super();
        this.state = {
            dataOportunities: [],
            show: null,
            item: null,
            /// values to store editingmode
            status: null,
            assignedToUserId: null,
            comment: null,
            filterStatus: -1,
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.updateComment = this.updateComment.bind(this);
        this.updateFiletr = this.updateFilter.bind(this);

    }

    componentDidMount() {
        this.getData();
    }

    groupBy = (data, key) => {
        return data.reduce(function (acc, item) {
            (acc[item[key]] = acc[item[key]] || []).push(item);
            return acc;
        }, {});
    }

    getData = () => {

      //  console.log(this.state.filterStatus);
        var url = `${configs.URL_API_BASE}/oportunities/${this.state.filterStatus}/getOportunitiesByStatus`;
        switch (this.state.filterStatus) {
            case -1:
                url = `${configs.URL_API_BASE}/oportunities/getOportunities`;
                break;
        };
        axios.get(url, {})
            .then(res => {
                if (res.data.status === 200) {
                    let auxData = res.data.results;
                    // hazlo arreglo ahuevo.. 
                    try {
                        auxData.forEach((item) => {
                            item.id = item.oportunityId;
                        })
                        this.setState({ dataOportunities: auxData });
                    }
                    catch{
                        this.setState({ dataOportunities: [] });
                    }

                }
            });
    }

    updateStatus(event) {
        event.preventDefault();
        let url = `${configs.URL_API_BASE}/oportunities/${this.state.item.oportunityId}/edit`;
        let dataOk = true;
        const formData = new FormData();
        switch (this.state.show) {
            case 'status':
                if (this.state.status < 0) dataOk = false;
                formData.append('status', this.state.status);
                break;

            default:
                this.handleClose();
                Swal.fire('Error', 'Ocurrio un error inesperado.', 'waring');
                dataOk = false;
        }
        if (dataOk) {
            //this.setState({ isuploading: true });
            axios.put(url, formData, this.state.config).then((response) => {
                this.setState({ isuploading: false });
                this.handleClose();
                if (parseInt(response.data.status) === 200) {
                    this.getData();
                    Swal.fire('Actualización Correcta', '', 'success');
                } else {
                    Swal.fire('Error', 'Ocurrio un error inesperado.', 'waring');
                }
            });
        }
    }

    updateComment(event) {
        event.preventDefault();
        let url = `${configs.URL_API_BASE}/timeLine/createTimeLine`;
        const formData = new FormData();
     //   console.log("ID: " + this.state.item.oportunityId)
        formData.append('foreignId', this.state.item.oportunityId);   //semanda este ppor que el url se computa en el api
        formData.append('comment', this.state.comment);
        formData.append('whoComment', '2');
        formData.append('operation', '4');
        formData.append('timeLineType', '2');
        this.setState({ isuploading: true });

     //   console.log("sending to ", url);

        axios.post(url, formData, this.state.config).then((response) => {
            this.setState({ isuploading: false });
            this.handleClose();
            if (parseInt(response.data.status) === 200) {
                this.getData();
                Swal.fire('Actualización Correcta', '', 'success');
            } else {
                Swal.fire('Error', 'Ocurrio un error inesperado.', 'waring');
            }
        });
    }

    updateFilter(status) {
        this.setState({
            filterStatus: status
        }, () => this.getData());
    }

    handleClose() {
        this.setState({
            show: null,
            item: null,
        });
    }

    handleShow(modalName, record) {

        this.setState({
            comment: '',
            show: modalName,
            item: record,
        }, () => {
            console.log("ID: " + this.state.item.oportunityId)

        });

    }
    /// Function to handle values. 

    onChangeInput = (event) => {
       // console.log([event.target.name] + ':' + event.target.value)
        this.setState({ [event.target.name]: event.target.value });
    }

    delete = id => {
        axios.post(`${configs.URL_API_BASE}/oportunities/${id}/delete`)
            .then(res => {
                console.log(res);
                if (parseInt(res.data.status) == 200) {
                    this.getData();
                    Swal.fire(
                        "Eliminado!",
                        "Elemento eliminado correctamente.",
                        "success"
                    );
                } else {
                    Swal.fire(
                        "Error!",
                        "Contacte al desarrollador.",
                        "warning"
                    );
                }
            })
            .catch(err => {
                Swal.fire("Error!", "Contacte al desarrollador.", "warning");
            });
    };

    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <div className="air__utils__heading d-flex justify-content-between align-self-center float-left">
                        <h2>Oportunidades</h2>
                    </div>
                    <div className="d-flex justify-content-between align-self-center">
                        <div className="p-2">
                            <button className="btn btn-info" title="Congelado" onClick={() => this.updateFilter(5)} ><i className="fa fa-minus-circle"></i> Congelado  </button>
                        </div>
                        <div className="p-2">
                            <button className="btn btn-info" title="Tomar Acción" onClick={() => this.updateFilter(4)} ><i className="fa fa-heartbeat"></i> Tomar Acción </button>
                        </div>
                        <div className="p-2">
                            <button className="btn btn-info" title="Evolucionando" onClick={() => this.updateFilter(3)} ><i className="fa fa-line-chart"></i> Evolucionando </button>
                        </div>
                        <div className="p-2">
                            <button className="btn btn-info" title="Esperando Respuesta" onClick={() => this.updateFilter(2)} ><i className="fa fa-history"></i> Esperando Respuesta  </button>
                        </div>
                        <div className="p-2">
                            <button className="btn btn-info" title="Pendiente" onClick={() => this.updateFilter(1)} ><i className="fa fa-clone"></i> Pendiente </button>
                        </div>
                        <div className="p-2">
                            <button className="btn btn-info" title="Mostrar Todos" onClick={() => this.updateFilter(-1)}><i className="fa fa-circle-o"></i> Mostrar Todos</button>
                        </div>
                    </div>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        {
                            <TableOportunities
                                handleShow={this.handleShow}
                                delete={this.delete}
                                dataOportunities={this.state.dataOportunities}></TableOportunities>
                        }
                    </div>
                </div>
                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.show == 'status'}
                    onRequestClose={this.handleClose}
                    className="Modal">
                    <form onSubmit={this.updateStatus}>
                        <div className="modal-dialog">
                            <LoadingOverlay
                                active={this.state.isuploading}
                                spinner
                                text='Actualizando...'>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Cambiar status de Seguimiento </h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row content-modal-mobile">
                                            <div className="col-12">
                                                <div className="form-row ">
                                                    <div className="col-12 d-flex align-items-center bt-3">
                                                        <label className="form-check-label mr-3" htmlFor="priorizar">Esta Cambiando el Estado de Seguimiento para {this.state.item ? this.state.item.contact : ''}</label>
                                                    </div>
                                                    <div className="col-12">
                                                        <label>Nuevo Estado:<span
                                                            className="text-red">*</span></label>
                                                        <select name="status" autoFocus={true} onChange={this.onChangeInput}>
                                                            <option value='-1'>Seleccione el nuevo estado</option>
                                                            <option value='1'>Pendiente de Contactar</option>
                                                            <option value='2'>Esperando Respuesta</option>
                                                            <option value='3'>Evolucionando</option>
                                                            <option value='4'>Tomar Accion</option>
                                                            <option value='5'>Congelado</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-danger btn-lg" type="button" onClick={this.handleClose}>Cancelar</button>
                                        <button className="btn btn-success btn-lg" type="submit">Guardar Estado</button>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </form>
                </Modal>
                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.show === 'comment'}
                    onRequestClose={this.handleClose}
                    className="Modal custom-modal">
                    <form onSubmit={this.updateComment}>
                        <div className="modal-dialog">
                            <LoadingOverlay
                                active={this.state.isuploading}
                                spinner
                                text='Subiendo el contenido...'>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Agregar un nuevo comentario de Seguimiento </h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body" >
                                        <div className="row content-modal-mobile">
                                            <div className="col-12">
                                                <div className="form-row ">

                                                    <div className="col-12">
                                                        <label>Su comentario de seguimiento [ {this.state.item ? this.state.item.contact : ''} ]<span className="text-red">*</span></label>
                                                    </div>
                                                    <div className="col-12">
                                                        <textarea name="comment" className="col-12" onChange={this.onChangeInput}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-danger btn-lg" type="button" onClick={this.handleClose}>Cancelar</button>
                                        <button className="btn btn-success btn-lg" type="submit">Guardar Comentario</button>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </form>
                </Modal>
            </>
        )
    }
}
