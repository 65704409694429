import React, { Component } from "react"
import Swal from "sweetalert2"
import "react-tabs/style/react-tabs.css"
import axios, { post, put } from "axios"
import configs from "./../../configs/config"
import TableProperties from "./components/table"
import { Lightbox } from "react-modal-image"


export default class Properties extends Component {
    constructor() {
        super()
        this.state = {
            isuploading: false,
            imagepreview: null,
            image: null,
            checkedPriority: false,
            checkedActive: true,
            modalIsOpen: false,
            amenities: [],
            amenitieschekeds: [],
            propertyTypes: [],
            currencies: [],
            categories: [],
            subcategories: [],
            stateslist: [],
            citieslist: [],
            selectedState: 0,
            selectedCity: 0,
            dataproperties: [],
            name_es: "",
            name_en: "",
            property_type: "1",
            price: "",
            currencyCode: "1",
            categoryId: "",
            idSubCategory: "",
            pdf: "",
            pdfi: "",
            fieldForm: "1",
            totalsize: "",
            front: "",
            background: "",
            slug: "",
            slugEN: "",
            meta: "",
            metaEn: "",
            keywords: "",
            keywordsEN: "",
            videoUrl: "",
            address: "",
            stateId: "",
            city: "",
            cp: "",
            latitud: "",
            longitud: "",
            descriptionEs: "",
            descriptionEN: "",
            rentTerms: "",
            rentTermsEN: "",
            alt: "",
            alte: "",
            isInvertionC: false,
            isInvertionT: "",
            isInvertionTEn: "",
            isEdit: false,
            id: "",
            open: false,
            urlimageshow: "",
            pictures: [],
            pictureslocalurl: [],
            picturesserverurl: [],
            logoproperty: '',
        }

        this.getPropertiesData()
    
    }

    closeLightbox = () => {
        // this.state.open = false;
        this.setState({ open: false })
    }
    openLigthBox = urlimage => {
        this.setState({ urlimageshow: urlimage })
        this.setState({ open: true })
    }
    getPropertiesData = () => {
        axios
            .get(`${configs.URL_API_BASE}/properties/getProperties`, {})
            .then(res => {
                if (res.data.status == 200) {
                    let auxAmen = res.data.results
                    auxAmen.forEach(item => {
                        item.id = item.id_property
                        item.status_val =
                            item.status == 1 ? "Activo" : "Inactivo"
                    })
                    this.setState({ dataproperties: auxAmen })
                }
            })
    }

    delete = id => {
        axios
            .post(`${configs.URL_API_BASE}/properties/${id}/delete`)
            .then(res => {
                console.log(res)
                if (parseInt(res.data.status) == 200) {
                    this.getPropertiesData()
                    Swal.fire(
                        "Eliminado!",
                        "Elemento eliminado correctamente.",
                        "success"
                    )
                } else {
                    Swal.fire("Error!", "Contacte al desarrollador.", "warning")
                }
            })
            .catch(err => {
                console.log(err)
                Swal.fire("Error!", "Contacte al desarrollador.", "warning")
            })
    }

    handleOpenEdit = record => {
        this.props.history.push(`/properties/${record.id_property}`);
    }

    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Propiedades</h2>
                    <a
                        className="btn btn-primary d-flex justify-content-between align-self-center"
                        href="/properties/add">
                        <span>Nueva Propiedad &nbsp;</span>
                        <span>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                        </span>
                    </a>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        <TableProperties
                            handleOpenEdit={this.handleOpenEdit}
                            delete={this.delete}
                            dataproperties={
                                this.state.dataproperties
                            }
                            openLigthBox={
                                this.openLigthBox
                            }></TableProperties>
                    </div>
                </div>
                {this.state.open && (
                    <Lightbox
                        large={this.state.urlimageshow}
                        alt=""
                        onClose={this.closeLightbox}
                    />
                )}
            </>
        )
    }
}
