import React, { Component } from "react"
import LoadingOverlay from "react-loading-overlay";
import { campaignsService,emailListsService } from "./../../services/tibesa.api";
import Swal from 'sweetalert2'
import * as ReactBootstrap from 'react-bootstrap';
import TableCampaignItem from "./components/tableCampaignItem";

export default class CampaignReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign:null,
            data:null,
            successAlert:false,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = function (){
        Promise.all([campaignsService.getCampaignItems(this.props.match.params.id),
            campaignsService.get(this.props.match.params.id)])
        .then(([campaignItems,campaign]) => {
            campaignItems.map(item => {
                item.id = item.campaignItemId
                item.name = item.name ? item.name : '';
            
            })
            this.setState({ campaign: campaign, data: campaignItems });
           // console.log(this.state.data);
        })
    }

    delete = (record) =>{
        window.event.preventDefault();        //removeit from data. 
        const formData = new FormData();
        formData.append('email', record.email);
        emailListsService.deleteEmailItemByEmail(this.state.campaign.campaignEmailListId,formData)
        .then(resp => {
           
            this.showMessage();
        })        
    }
    showMessage = () =>{
        this.setState({ successAlert:true});
        setTimeout(() => {
                 this.setState({ successAlert:false});
         }, 3000);
    }

    render() {
       
        return (
            <>
               <div className="air__utils__heading">
                    <a href="/campaigns">Listado de Campañas </a><span>/ Reporte /  {this.state.campaign?.campaignName} #{this.props.match.params.id}</span>
                </div>
                <div className="clearfix">&nbsp;</div>

                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h5>
                        <p>Campaña: {this.state.campaign?.campaignName}</p> 
                    </h5>                       
                </div>
                <div className="row my-4">
                    <div  className="col-12">
                        <div className="modal-content" >
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-1 col-md-2">
                                        <img src="//via.placeholder.com/60x100"></img>
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <p> Enviado el : {this.state.campaign?.sendingDate.split('T')[0]}</p>
                                        <p> Alcance : {this.state.campaign?.totalSent}</p>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <p>Linea de Asunto: {this.state.campaign?.subject}</p> 
                                        <p>Enviado por: {this.state.campaign?.sender}</p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    <div className="row">
                            <div className="col-sm-12">
                                {
                                    this.state.successAlert &&
                                    <div class="alert alert-warning sticky-top fade show" role="alert">
                                            email removido.
                                    </div>
                                }
                                <div className="clearfix">&nbsp;</div>
                                <ReactBootstrap.Tabs defaultActiveKey="home" id="controlled-tab">
                                   
                                    <ReactBootstrap.Tab eventKey="home" title={'Destinatrios (' + this.state.data?.length + ')'}  className="tab-bordered">
                                    {
                                         <TableCampaignItem data={this.state.data?this.state.data:[]} handleDelete={this.delete} ></TableCampaignItem>
                                    }
                                    </ReactBootstrap.Tab>
                                </ReactBootstrap.Tabs>
                            </div>
                        </div>
                    </div>                    
                </div>
            </>
        )
    }
}