import React, { Component } from "react"
import { pagesService } from '../../services/tibesa.api'
import LoadingOverlay from "react-loading-overlay";
import Swal from 'sweetalert2'
import PageTranslated from "../../models/pageTranslated";

import LandingPageForm from "./components/page-form";
import LandingPageHtml from "./components/page-html";
import LandingPageOrder from "./components/page-order";



export default class LandigPageDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: null
        }
        this.submitChanges = this.submitChanges.bind(this);
    }

    decodeToString = (data) => {
        try {
        let buff = new Buffer.from(data, 'base64');
        return buff.toString('utf-8');
        }
        catch (e) 
        {
            return '<body></body>';
        }
    }

    isBase64 = (encodedString) => {
        var regexBase64 = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
        return regexBase64.test(encodedString);   // return TRUE if its base64 string.
    }

    componentDidMount() {
        let curId=this.props.match.params.id;
        let curLan =this.props.match.params.language;

        Promise.all([pagesService.get(curId)])
        .then(([page]) => {

            if (this.props.match.params.language&&curId){

                
                //tray to get that language. 
                Promise.all([pagesService.getPageTranslation(curId,curLan)])
                .then(([pageT]) => {
                    this.setState({ page : new PageTranslated(pageT),language:  curLan.toLowerCase()});
                    //si no habia datos todo el page esta en null 
                 //   console.log(`from query paget ${pageT.translationId}`);     
                    if (pageT.pageName =='' || !pageT.pageName) pageT.pageName = page.pageName  // toma el de español 

                    pageT.bodyHtml = (this.isBase64(pageT.bodyHtml)) ?  this.decodeToString(pageT.bodyHtml) : pageT.bodyHtml;
                    if (!pageT.translationId)  {
                      //  console.log(`Nueva Traduccion de ${curId} en idioma EN`);

                        pageT.language = curLan.toLowerCase();
                        pageT.pageId = curId;
                        this.setState({
                            page : new PageTranslated(pageT),
                            language:curLan.toLowerCase(),
                        });
                    }
                });
            }
            else {
                page.bodyHtml =  (this.isBase64(page.bodyHtml)) ?   this.decodeToString(page.bodyHtml) : page.bodyHtml;

                this.setState({ page: new PageTranslated(page),language:'es' });
            }
        })
    }

    showTab(language){
        if (language===this.state.language){
            return "tab-paneactive show active fade p-3"
        }
        return "display:none"
    }
    showNav(language){
        if (language===this.state.language){
            return "nav-link show active "
        }
        return "nav-link"
    }

    selectTab(language){
        if (language===this.state.language){
            return "true"
        }
        return "false"
    }

    submitChanges(formData,id) {
        Swal.fire({
            title: '¿Seguro que deseas editar la Página?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return pagesService.update(id, formData)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
                if (response.value && response.value.data && response.value.data.status === 200) {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: '¡Página actualizada con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }
                    ).then(() => {
                        window.location.reload();
                    });
                } else if(!response.dismiss === 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }

    submitTransalation(formData,id,language) {
        Swal.fire({
            title: '¿Seguro que deseas editar la Página?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return pagesService.createOrUpdateLandingPageTranslation(id,language, formData)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
                if (response.value && response.value.data && response.value.data.status === 200) {
                    Swal.fire(
                        {
                            title:  '¡Perfecto!',
                            text: '¡Página actualizada con éxito!',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK'
                        }
                    ).then(() => {
                        window.location.reload();
                    });
                } else if(!response.dismiss === 'cancel'){
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }


    render() {
        let form;
        let formEn;
        if (this.state.page != null) {
            if (this.state.page.language==='en'){
                if (this.props.match.params.content=='seo')
                    formEn = <LandingPageForm page={this.state.page} onSubmitTranslate={this.submitTransalation}></LandingPageForm>
                if (this.props.match.params.content=='html')
                    formEn = <LandingPageHtml page={this.state.page} onSubmitTranslate={this.submitTransalation}></LandingPageHtml>
                if (this.props.match.params.content=='order')
                    formEn = <LandingPageOrder page={this.state.page} onSubmitTranslate={this.submitTransalation}></LandingPageOrder>
            }
            else {
                if (this.props.match.params.content=='seo')
                    form = <LandingPageForm page={this.state.page} onSubmit={this.submitChanges}></LandingPageForm>
                if (this.props.match.params.content=='html')
                    form = <LandingPageHtml page={this.state.page} onSubmit={this.submitChanges}></LandingPageHtml>
                if (this.props.match.params.content=='order')
                    form = <LandingPageOrder page={this.state.page} onSubmit={this.submitChanges}></LandingPageOrder>
            }
        }
        else {
            form = <LoadingOverlay></LoadingOverlay>
            formEn = <LoadingOverlay></LoadingOverlay>
        }
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-end"> 
                        <h1>Editando</h1></div>
                        <div className="d-flex justify-content-start"> 
                            <a className="btn - btn-secondary" href="/landingpages">Regresar</a>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <div> { this.state.page && <p> Url Pagina Aterrizaje : {this.state.page.pageUrl}</p> }
                    </div>
                    <div className="card-header tab-card-header">
                        <ul className="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className={this.showNav('es')} id="one-tab" data-toggle="tab" href={"/landingpages/"+this.props.match.params.id+"/"+this.props.match.params.content} role="tab" aria-controls="One" aria-selected={this.selectTab('es')} >Español</a>
                            </li>
                            <li className="nav-item">
                                <a className={this.showNav('en')}  id="two-tab" data-toggle="tab"href={"/landingpages/"+this.props.match.params.id+"/"+this.props.match.params.content+"/en"}role="tab" aria-controls="Two" aria-selected={this.selectTab('en')}>English</a>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" id="myTabContent">
                        <div className={this.showTab('es')} id="one" role="tabpanel" aria-labelledby="one-tab">
                            
                                {form}          
                        </div>
                        <div className={this.showTab('en')} id="one" role="tabpanel" aria-labelledby="one-tab">
                  
                                {formEn}          
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
