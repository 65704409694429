import React, { Component } from 'react';

import axios from 'axios';
import Swal from 'sweetalert2';
import configs from './../../configs/config';
import Switch from "react-switch";
import Modal from 'react-modal';

import TablePropertyTypes from './components/table';
import LoadingOverlay from 'react-loading-overlay';


export default class PropertyType extends Component {

    constructor() {
        super();
        this.state = {
            isuploading: false,
            checked: false,
            modalIsOpen: false,
            data: [],
            showsuccess: false,
            name: '',
            id: '',
            isEdit: false,
        };

        this.getData();
    }

    getData = () => {
        axios.get(`${configs.URL_API_BASE}/propertyTypes/getProperty_Type`, {})
            .then(res => {
                if (res.data.status == 200) {
                    let auxData = res.data.results;
                    auxData.forEach((item) => {
                        item.id = item.id_propertyType;
                        item.status_val = (item.status == '1') ? 'Activo' : 'Inactivo';
                    })
                    this.setState({ data: auxData });
                }
            });
    }
    delete = (id) => {
        axios.post(`${configs.URL_API_BASE}/propertyTypes/${id}/delete`)
            .then(res => {
                console.log(res);
                if (parseInt(res.data.status) == 200) {
                    this.getData();
                    Swal.fire('Eliminado!', 'Elemento eliminado correctamente.', 'success');
                } else {
                    Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
                }
            }).catch(err => {
                console.log(err);
                Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
            });
    }

    handleOpenEdit = record => {
        console.log(record);
        this.setState({
            modalIsOpen: true,
            isEdit: true,
            name: record.name,
            id: record.id,
        });
        record.status == 1 ? this.setState({ checked: true }) : this.setState({ checked: false });
    }

    onSubmit = (e) => {
        e.preventDefault();

        let newItem = {
            name: this.state.name,
            status: (this.state.checked) ? '1' : '0'
        }

        let url = ``;
        this.setState({ isuploading: true });
        if (this.state.isEdit) {
            url = `${configs.URL_API_BASE}/propertyTypes/${this.state.id}/edit`;
            axios.put(url, newItem, this.state.config).then((response) => {
                this.setState({ isuploading: false });
                if (parseInt(response.data.status) == 200) {
                    this.closeModal();
                    this.getData();
                    Swal.fire('Edición Correcta', 'Tipo de propiedad editada correctamente.', 'success');
                } else {
                    console.log("fail");
                }
            });
        } else {
            url = `${configs.URL_API_BASE}/propertyTypes/create`;
            axios.post(url, newItem, this.state.config).then((response) => {
                this.setState({ isuploading: false });
                if (parseInt(response.data.status) == 200) {
                    this.closeModal();
                    this.getData();
                    Swal.fire('Ingreso Correcto', 'Tipo de propiedad ingresada correctamente.', 'success');
                } else {
                    console.log("fail");
                }
            });
        }
        e.target.reset();
    }

    onInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    activateCheck = (checked) => {
        this.setState({ checked });
    }

    openModal = () => {
        this.setState({
            modalIsOpen: true,
            isEdit: false,
            name: '',
            id: '',
            checked: true,
        });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Tipos de Propiedad</h2>
                    <a className="btn btn-primary d-flex justify-content-between align-self-center" onClick={this.openModal}>
                        <span>Nuevo tipo de Propiedad &nbsp;</span>
                        <span><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        <TablePropertyTypes handleOpenEdit={this.handleOpenEdit} delete={this.delete} data={this.state.data}></TablePropertyTypes>
                    </div>
                </div>
                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    className="Modal"
                    contentLabel="Crear Amenidad">
                    <form onSubmit={this.onSubmit}>
                        <div className="modal-dialog">
                            <LoadingOverlay
                                active={this.state.isuploading}
                                spinner
                                text='Subiendo el contenido...'
                            >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{this.state.isEdit ? "Editar Tipo de Propiedad" : "Crear Tipo de Propiedad"}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-row my-3">
                                                    <div className="col-12 d-flex align-items-center">
                                                        <label className="form-check-label mr-3" htmlFor="priorizar">Activar este Tipo de Propiedad?</label>
                                                        <Switch onChange={this.activateCheck} checked={this.state.checked} />
                                                    </div>
                                                    <div className="col-12 my-3">
                                                        <label>Nombre<span
                                                            className="text-red">*</span></label>
                                                        <input name="name" autoFocus={true} value={this.state.name} type="text" className="form-control" onChange={this.onInputChange} required />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-danger btn-lg" type="button" onClick={this.closeModal}>CANCELAR</button>
                                        <button className="btn btn-success btn-lg" type="submit">GUARDAR</button>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </form>
                </Modal>
            </>
        )
    }
}
