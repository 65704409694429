import React, { Component } from 'react'

import 'react-tabs/style/react-tabs.css';
import Modal from 'react-modal';

import axios from 'axios';
import Swal from 'sweetalert2';
import configs from './../../configs/config';

import TableUsers from './components/table';
import Switch from "react-switch";

import LoadingOverlay from 'react-loading-overlay';

export default class News extends Component {

    constructor() {
        super();
        this.state = {
            modalIsOpen: false,
            isEdit: false,
            imagepreview: null,
            isuploading: false,
            id: null,
            data: [],
            userRoles: [
                { id: 1, text: 'Administrador' },
                { id: 2, text: 'Editor' },
                { id: 3, text: 'Capturista' },
                { id: 4, text: 'Temporal' }
            ],
            new: {
                name: '',
                lastname: '',
                email: '',
                password: '',
                userType: 1,
                status: ''
            },
            repassword: '',
            image: '',
            checked: true
        };

        this.getData();
    }

    getData = () => {
        axios.get(`${configs.URL_API_BASE}/users/getUsers`, {})
            .then(res => {
                console.log(res);
                if (res.data.status == 200) {
                    let auxData = res.data.results;
                    auxData.forEach((item) => {
                        item.id = item.user_id;
                        item.status_val = (item.status == '1') ? 'Activo' : 'Inactivo';
                        item.rol = this.getUserTypeString(item.userType);
                    })
                    this.setState({ data: auxData });
                }
            });
    }

    getUserTypeString = (level) => {
        let textRol;
        this.state.userRoles.map((rol) => {
            if (rol.id == level) {
                textRol = rol.text;
            }
        });

        return textRol;
    }

    delete = (id) => {
        axios.post(`${configs.URL_API_BASE}/users/${id}/delete`)
            .then(res => {
                //console.log(res);
                if (parseInt(res.data.status) === 200) {
                    this.getData();
                    Swal.fire('Eliminado!', 'Elemento eliminado correctamente.', 'success');
                } else {
                    Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
                }
            }).catch(err => {
                console.log(err);
                Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
            });
    }


    onSubmit = (e) => {
        e.preventDefault();

        let newItem = {
            ...this.state.new,
            image: this.state.image,
            status: (this.state.checked) ? '1' : '3'
        }

        console.log('item to send', newItem);



        if (this.state.new.password == this.state.repassword) {

            let form_data = new FormData();
            for (var key in newItem) {
                form_data.append(key, newItem[key]);
            }

            let url = `${configs.URL_API_BASE}/users/createUser`;
            if (this.state.isEdit) {
                url = `${configs.URL_API_BASE}/users/${this.state.id}/edit`;
            }

            this.setState({ isuploading: true });
            if (this.state.isEdit) {
                axios.put(url, form_data, { headers: { 'content-type': 'multipart/form-data' } }).then((response) => {
                    this.setState({ isuploading: false });
                    if (parseInt(response.data.status) == 200) {

                        Swal.fire('Edición Correcta', 'Usuario editado correctamente.', 'success');
                        this.closeModal();
                        this.getData();

                    } else {
                        console.log("fail");
                    }
                });
            } else {
                axios.post(url, form_data, { headers: { 'content-type': 'multipart/form-data' } }).then((response) => {
                    this.setState({ isuploading: false });
                    if (parseInt(response.data.status) === 200) {

                        Swal.fire('Ingreso Correcto', 'Usuario ingresada correctamente.', 'success');
                        this.closeModal();
                        this.getData();

                    } else {
                        console.log("fail");
                    }
                });
            }


            e.target.reset();
        } else {
            Swal.fire('Las contraseñas no corresponden', 'Favor de ingresar las contraseñas correspondientes.', 'warning');

        }

    }

    onInputChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;

        this.setState(prevState => {
            return {
                new: {
                    ...prevState.new, [name]: value
                }
            }
        })
    }

    onInputChangeMain = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    activateCheck = (checked) => {
        this.setState({ checked });
    }

    onChangeFile = e => {
        this.setState({
            image: e.target.files[0],
            imagepreview: URL.createObjectURL(e.target.files[0])
        })
    }

    openModal = () => {
        this.setState({ modalIsOpen: true, imagepreview: null, image: null, isEdit: false });
    }

    handleOpenEdit = record => {
        this.setState({ id: record.user_id, imagepreview: `${configs.URL_IMAGESNEW}${record.imgProfile}`, image: null });
        this.setState({ modalIsOpen: true, isEdit: true });
        this.updatestevideosstates('name', record.name);
        this.updatestevideosstates('lastname', record.lastname);
        this.updatestevideosstates('email', record.email);
        //this.updatestevideosstates('password', record.password);
        this.updatestevideosstates('userType', record.userType);
        this.updatestevideosstates('status', record.status);
    }
    updatestevideosstates = (name, value) => {
        this.setState(prevState => {
            return {
                new: {
                    ...prevState.new, [name]: value,
                }
            }
        });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Usuario</h2>
                    <a className="btn btn-primary d-flex justify-content-between align-self-center" href="#" onClick={this.openModal}>
                        <span>Nuevo Usuario &nbsp;</span>
                        <span><i className="fa fa-plus-circle" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        <TableUsers handleOpenEdit={this.handleOpenEdit} delete={this.delete} data={this.state.data}></TableUsers>
                    </div>
                </div>
                <Modal
                    appElement={document.getElementById("root")}
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    className="Modal"
                    contentLabel="Crear Usuario">
                    <form onSubmit={this.onSubmit}>
                        <div className="modal-dialog modal-lg">
                            <LoadingOverlay
                                active={this.state.isuploading}
                                spinner
                                text='Subiendo el contenido...'
                            >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{this.state.isEdit ? "Editar Usuario" : "Crear Usuario"}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row content-modal-mobile">
                                            <div className="col-12">
                                                <div className="form-row">
                                                    <div className="col-12 d-flex align-items-center ">
                                                        <label className="form-check-label mr-3" htmlFor="priorizar">Usuario Activo?</label>
                                                        <Switch onChange={this.activateCheck} checked={this.state.checked} />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-6 my-3">
                                                        <label>Nombre <span
                                                            className="text-red">*</span></label>
                                                        <input type="text" autoFocus={true} name={'name'} value={this.state.new.name} className="form-control" onChange={this.onInputChange} required />
                                                    </div>
                                                    <div className="col-6 my-3">
                                                        <label>Apellidos <span
                                                            className="text-red">*</span></label>
                                                        <input type="text" name={'lastname'} value={this.state.new.lastname} className="form-control" onChange={this.onInputChange} required />
                                                    </div>
                                                    <div className="col-6 my-3">
                                                        <label>Email <span
                                                            className="text-red">*</span></label>
                                                        <input type="text" name={'email'} value={this.state.new.email} className="form-control" onChange={this.onInputChange} required />
                                                    </div>
                                                    <div className="col-6 my-3">
                                                        <label>Contraseña <span
                                                            className="text-red">*</span></label>
                                                        {
                                                            this.state.isEdit ?
                                                                <input type="password" name={'password'} value={this.state.new.password} className="form-control" onChange={this.onInputChange} /> :
                                                                <input type="password" name={'password'} value={this.state.new.password} className="form-control" onChange={this.onInputChange} required />

                                                        }
                                                    </div>

                                                    <div className="col-6  my-3">
                                                        <label>Confirmar Contraseña <span className="text-red">*</span></label>
                                                        {
                                                            this.state.isEdit ?
                                                                <input type="password" name={'repassword'} value={this.state.password} className="form-control" onChange={this.onInputChangeMain} /> :
                                                                <input type="password" name={'repassword'} value={this.state.password} className="form-control" onChange={this.onInputChangeMain} required />

                                                        }
                                                    </div>

                                                    <div className="col-6 my-3">
                                                        <label>Rol de Usuario <span className="text-red">*</span></label>
                                                        <select className="form-control" id="userType" value={this.state.new.userType} name={'userType'} onChange={this.onInputChange}>
                                                            {
                                                                this.state.userRoles.map((rol, pos) => (
                                                                    <option key={pos} value={rol.id}>
                                                                        {rol.text}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-12">
                                                        <label>Imagen de perfil <span
                                                            className="text-red">*</span></label>
                                                        <input type="file" name="image" onChange={this.onChangeFile} id="img" />

                                                    </div>
                                                    <img alt=""
                                                        width={'auto'} height={180} mode='fit'
                                                        src={this.state.imagepreview} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-danger btn-lg" type="button" onClick={this.closeModal}>CANCELAR</button>
                                        <button className="btn btn-success btn-lg" type="submit">GUARDAR</button>
                                    </div>
                                </div>
                            </LoadingOverlay>
                        </div>
                    </form>
                </Modal>
            </>
        )
    }
}
