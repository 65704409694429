import React, { Component } from "react"
import TemplateForm from './components/template-form'
import { emailTemplatesService } from '../../services/tibesa.api'
import Swal from 'sweetalert2'

export default class ContactAdd extends Component {

    submitChanges(formData) {
        Swal.fire({
            title: '¿Seguro que deseas guardar la platilla?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return emailTemplatesService.create(formData)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        Swal.showValidationMessage(
                            `Error en la petición: ${error}`
                        )
                    });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .then((response) => {
                console.log(response);
                if (response.value && response.value.data && response.value.data.status == 200) {
                    Swal.fire(
                        '¡Perfecto!',
                        '¡Nueva plantilla creada!',
                        'success'
                    ).then(() => {
                        if (response.value.data.results) {
                            window.location.href = `/email-templates/${response.value.data.results}`;
                        }
                        else {
                            window.location.href = '/email-templates';
                        }
                    });
                } else if(!response.dismiss == 'cancel') {
                    Swal.fire(
                        'Algo salio mal :C',
                        'Hubo un error al realizar la petición',
                        'error'
                      );
                }
            });
    }


    render() {
        return (
            <>
                <div className="air__utils__heading">
                    <a href="/email-templates">Listado de plantillas de email</a><span>{" "}/{" "}Agregar plantilla</span>
                </div>
                <TemplateForm onSubmit={this.submitChanges}></TemplateForm>
            </>
        )
    }
}
