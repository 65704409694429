import React from 'react'

export const AmenityCheckBox = props => {
    return (
        <div className="col-6 col-sm-4 col-md-3">
            <div>
                <input key={props.id} onChange={props.handleCheckElement} type="checkbox" checked={props.isChecked} value={props.value} />
                <label style={{ paddingLeft: '6px' }}>  {props.value}</label>
            </div>
        </div>
    )
}

export default AmenityCheckBox