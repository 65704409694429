import React, { Component } from 'react'

import TableContactForm from './components/table';
import configs from '../../configs/config';
import axios from 'axios';
import Swal from 'sweetalert2'

export default class ContactForm extends Component {

    constructor() {
        super();
        this.state = {
            datacontacts: [],
        }
        this.getData();

    }
    getData = () => {
        axios.get(`${configs.URL_API_BASE}/contact/getContacts`, {})
            .then(res => {
                if (res.data.status == 200) {
                    let auxData = res.data.results;
                    auxData.forEach((item) => {
                        item.id = item.idContactoForm;
                    })
                    this.setState({ datacontacts: auxData });
                }
            });
    }

    delete = (id) => {
        axios.post(`${configs.URL_API_BASE}/contact/${id}/delete`)
            .then(res => {
                console.log(res);
                if (parseInt(res.data.status) == 200) {
                    this.getData();
                    Swal.fire('Eliminado!', 'Elemento eliminado correctamente.', 'success');
                } else {
                    Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
                }
            }).catch(err => {
                console.log(err);
                Swal.fire('Error!', 'Contacte al desarrollador.', 'warning');
            });
    }

    render() {
        return (
            <>
                <div className="air__utils__heading d-flex justify-content-between align-self-center">
                    <h2>Mensajes enviados desde el Portal</h2>
                </div>
                <div className="row my-4">
                    <div className="col-12">
                        {
                            <TableContactForm delete={this.delete} datacontacts={this.state.datacontacts}></TableContactForm>
                        }
                    </div>
                </div>
            </>
        )
    }
}
