export default class EmailTemplate {

    constructor(entity = {}){
        this.id = entity.templateId || null;
        this.type = entity.type || 1;
        this.name = entity.name || '';
        this.subject = entity.subject || '';
        this.body = entity.body || '';
        this.cc = entity.cc || '';
        this.orientation = entity.orientation || 1;
        this.language = entity.language || 'es-MX' 
    }
}